import {
    ActionSheetButton,
    IonActionSheet,
    IonAlert,
    IonAvatar,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { accessibilityOutline, 
    analytics, 
    bus, 
    calendar, 
    calendarOutline, 
    cashOutline, 
    chatbubbles, 
    chatbubblesOutline, 
    chevronForward, 
    closeOutline, 
    create, 
    documentsOutline,  
    idCardOutline, 
    medicalOutline, 
    notifications, 
    qrCodeOutline, 
    searchCircle, 
    sparkles, 
    time 
} from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { SendIntent } from "send-intent";
import Chart from "react-apexcharts";
import {useAuth} from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import CircularsSwiper from '../../components/ui/CircularsSwiper';
import useApiService from '../../components/ApiService';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Chat {
    _id: string,
    title: string,
    type: string,
    status: string,
    employee?: ChatEmployee,
    employees?: string[],
    adminEmployee?: string,
    taskInfo?: TaskInfo,
    createdAt: Date,
    updatedAt: Date
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface Appointment {
    _id: string,
    childInfo: Child,
    classInfo: ClassInfo,
    appointmentInfo: Appoint,
    amount: number,
    paidAt: Date
}

interface Inquiry {
    _id: string,
    inquiry: AdmnInquiryObj,
    nearestDate: UpdateObj
}

interface AdmnInquiryObj {
    _id: string,
    status: string,
    type: string,
    userInfo: User,
    updates: UpdateObj[],
    updatedAt: Date
}

interface UpdateObj {
    update: UpdateInfo,
    timestamp: Date
}
interface UpdateInfo {
    status: string,
    dated: Date
}

interface User {
    userID: string,
    name: string,
    phone: string
}

interface Child {
    childID: string,
    childName: string
}
interface ClassInfo{
    classID: string,
    className: string
}

interface Appoint {
    dated: Date,
    info: string
}

interface Student {
    _id: string,
    name: string,
    photo: string,
    dob: Date
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

interface StudentChat {
    _id: string,
    title: string,
    type: string,
    student?: Stud,
    students?: string[],
    adminStudent?: string,
    createdAt: Date
}

interface Stud {
    studentID: string,
    name: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

interface StudentAttendance {
    _id: string,
    studentID: string,
    studentName: string,
    status: string,
    createdAt: Date
}
interface StaffAttendance {
    _id: string,
    employee: Employee,
    status: string,
    createdAt: Date
}

interface Employee {
    employeeID: string,
    name: string
}

const EmployeeHome: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [admn, setAdmn] = useState < boolean > (false);
    const [comm, setComm] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (false);
    const [inventory, setInventory] = useState < boolean > (false);
    const [payment, setPayment] = useState < boolean > (false);
    const [transport, setTransport] = useState < boolean > (false);
    const [timetable, setTimetable] = useState < boolean > (false);
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [circulars, setCirculars]= useState < Circular[] > ([]);
    const [appointments, setAppointments] = useState < Appointment[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [chats, setChats] = useState < Chat[] > ([]);
    const [tasks, setTasks] = useState < Chat[] > ([]);
    const [categories, setCategories] = useState < string[] > (['First', 'Second', 'Third']);
    const [incomeSeries, setIncomeSeries] = useState < number[] > ([0, 0, 0]);
    const [expenseSeries, setExpenseSeries] = useState < number[] > ([0, 0, 0]);
    const [income, setIncome] = useState < number > (0);
    const [expense, setExpense] = useState < number > (0);
    const [series1, setSeries1] = useState < number[] > ([0, 0]);
    const [series2, setSeries2] = useState < number[] > ([0, 0]);
    const [students, setStudents] = useState < Student[] > ([]);
    const [events, setEvents] = useState < Event[] > ([]);
    const [studentChats, setStudentChats] = useState < StudentChat[] > ([]);
    const [routes, setRoutes] = useState < number > (0);
    const [trips, setTrips] = useState < number > (0);
    const [type, setType] = useState < string > ("");
    const [attendance, setAttendance]= useState < Attendance > ();
    const [studentAttend, setStudentAttend] = useState < StudentAttendance[] > ([]);
    const [driverAttend, setDriverAttend] = useState < StaffAttendance[] > ([]);
    const [teacherAttend, setTeacherAttend] = useState < StaffAttendance[] > ([]);
    const [salesRepAttend, setSalesRepAttend] = useState < StaffAttendance[] > ([]);
    const [unread, setUnread] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [actionButtons, setActionButtons] = useState < ActionSheetButton[] > ([]);
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [scannerActive, setScannerActive] = useState < boolean > (false);

    const series =  [{
        name: 'Income',
        data: incomeSeries
        }, {
        name: 'Expenses',
        data: expenseSeries
    }];

    const options =  {
        chart: {
            toolbar: {
              show: false
            }
        },
        colors : ['#2dd36f', '#eb445a'],
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: 'Rs. (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return "Rs. " + val + " thousands"
                }
            },
            theme: 'dark'
        }
    };

    const options1 = {
        colors : ['#FFC900', '#086E7D'],
        labels: ['Cash', 'Online'],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom' as 'bottom',
            labels: {
                colors: '#777'
            },
          }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const browserOptions: InAppBrowserOptions = {
        location: 'no',
        hidden: 'no',
        zoom: 'yes',
        toolbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarposition: 'bottom',
        closebuttoncaption: 'Close'
    };

    const openURL = (value: string) => {

        let browser : InAppBrowserObject;

        // _blank for iphone, _self for others
        if (isPlatform('capacitor') && isPlatform('ios'))
        {
            browser = InAppBrowser.create(String(value), '_blank', browserOptions);
        }
        else
        {
            browser = InAppBrowser.create(String(value), '_self', browserOptions);
        }
    };

    const openScanner = async () => {
        try {
            const status = await BarcodeScanner.checkPermissions();

            if (status.camera !== 'granted')
            {
                const res = await BarcodeScanner.requestPermissions();
                if (res.camera === 'denied')
                {
                    setMessage(t("camera_denied"));
                    setIserror(true);
                }
                else if (res.camera === 'granted') {

                    document.body.classList.add('scanner-active');
                    document.getElementById("ongoing-trip")?.classList.add('hide');
                    setScannerActive(true);

                    // Add the `barcodeScanned` listener
                    const listener = await BarcodeScanner.addListener(
                        'barcodeScanned',
                        async (result) => {
                            await listener.remove();
                            document.body.classList.remove('scanner-active');
                            document.getElementById("ongoing-trip")?.classList.remove('hide');
                            setScannerActive(false);
                            await BarcodeScanner.stopScan();
                            handleScan(result.barcode.displayValue);
                        },
                    );

                    await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
                    
                }
            }
            else
            {
                document.body.classList.add('scanner-active');
                document.getElementById("ongoing-trip")?.classList.add('hide');
                setScannerActive(true);

                // Add the `barcodeScanned` listener
                const listener = await BarcodeScanner.addListener(
                    'barcodeScanned',
                    async (result) => {
                        await listener.remove();
                        document.body.classList.remove('scanner-active');
                        document.getElementById("ongoing-trip")?.classList.remove('hide');
                        setScannerActive(false);
                        await BarcodeScanner.stopScan();
                        handleScan(result.barcode.displayValue);
                    },
                );

                await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
            }

        } catch (error: any) {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    };

    const stopScan = async () => {
        document.body.classList.remove('scanner-active');
        document.getElementById("ongoing-trip")?.classList.remove('hide');
        setScannerActive(false);
        await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
    };

    const handleScan = async (data : string) => {

        const studentID = data.split("/").pop();

        if (studentID && studentID.match(/^[0-9a-fA-F]{24}$/))
        {

            const result = await api.get('/students/' + studentID);
            setStudent(result.data);

            let cObjects: ActionSheetButton[] = [];

            cObjects.push({
                text: t("view_profile"),
                icon: !isPlatform('ios') ? accessibilityOutline : undefined,
                handler: () => history.push(`/app/student/${studentID}`)
            });

            if (((authInfo.institute._id === "623b78f1bbe7171e30738c7a") || (authInfo.institute._id === "623b7220bbe7171e30738a7c") || (authInfo.institute._id === "623b7808bbe7171e30738c74") || (authInfo.institute._id === "623b784fbbe7171e30738c77") ) && result.data.admnNo && (result.data.admnNo !== ""))
            {
                cObjects.push({
                    text: t("view_official_card"),
                    icon: !isPlatform('ios') ? idCardOutline : undefined,
                    handler: () => openURL(`https://softwareportal.gen.in/teacher/printidcard4app.php?id=${result.data.admnNo}`)
                });
            } 

            cObjects.push({
                text: t("view_ledger"),
                icon: !isPlatform('ios') ? cashOutline : undefined,
                handler: () => history.push(`/app/student/ledger/${studentID}`)
            });

            cObjects.push({
                text: t("view_communications"),
                icon: !isPlatform('ios') ? chatbubblesOutline : undefined,
                handler: () => history.push(`/app/inquiries/student/${studentID}`)
            });

            cObjects.push({
                text: t("view_attendance"),
                icon: !isPlatform('ios') ? calendarOutline : undefined,
                handler: () => history.push(`/app/student/attendance/${studentID}`)
            });

            if (!authInfo.institute.timetable)
            {
                cObjects.push({
                    text: t("view_submissions"),
                    icon: !isPlatform('ios') ? documentsOutline : undefined,
                    handler: () => history.push(`/app/submissions/student/${studentID}`)
                });
            } 

            if (authInfo.institute.medical)
            {
                cObjects.push({
                    text: t("view_records"),
                    icon: !isPlatform('ios') ? medicalOutline : undefined,
                    handler: () => history.push(`/app/student/incidents/${studentID}`)
                });
            }

            cObjects.push({
                text: t('cancel'),
                icon: !isPlatform('ios') ? closeOutline : undefined,
                role: 'cancel'
            });

            setActionButtons(cObjects);

            setShowActionSheet(true);
        }
        else 
        {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    };
   
    useEffect(() => {

        const upUser = async (push: Token) => {
            try {
                const response = await api.put(`/users/${authInfo.user._id}`, { push: push.value });
                return response.data;
            } catch (error) {
                throw error;
            }
        };

        const register = async () => {
            try {
                // Register with Apple / Google to receive push notifications
                await PushNotifications.register();
    
                // On success, we should be able to receive notifications
                await PushNotifications.addListener('registration', async (token: Token) => {
                    if (!authInfo.user.push || authInfo.user.push !== token.value) {
                        await upUser(token);
                    }
                });
    
                // Handle registration errors
                await PushNotifications.addListener('registrationError', () => {
                    setMessage(t("push_error"));
                    setIserror(true);
                });
            } catch (err) {
                console.error('Error in register function:', err);
            }
        };

        const fetchData = async () => {
            try {

                setType(authInfo.institute.type);
                setAdmn(authInfo.institute.whatsapp);
                setComm(authInfo.institute.communication);
                setPayment(authInfo.institute.payment);
                setAcad(authInfo.institute.academics);
                setTransport(authInfo.institute.transport);
                setTimetable(authInfo.institute.timetable);
                setInventory(authInfo.institute.inventory);

                if (authInfo.institute.admission && authInfo.user.roles.includes("AdmissionHead"))
                {
                    const appt = await api.get(`/applications/getSomeUpcoming/${authInfo.user.instituteID}`);
                    setAppointments(appt.data);

                }

                if (authInfo.institute.whatsapp && authInfo.user.roles.includes("AdmissionHead"))
                {
                    const inq = await api.get(`/admissionInquiries/getUpcomingInstitute/${authInfo.user.instituteID}`);
                    setInquiries(inq.data);

                    const sales = await api.get(`/staffAttends/getLogAdmission/${authInfo.user.instituteID}`);
                    setSalesRepAttend(sales.data);

                }

                if (authInfo.institute.whatsapp && authInfo.user.roles.includes("SalesRep"))
                {
                    const inq = await api.get(`/admissionInquiries/getUpcomingEmployee/${authInfo.user._id}`);
                    setInquiries(inq.data);

                }

                if (authInfo.institute.payment && authInfo.user.roles.includes("Accountant"))
                {
                    const resultA = await api.get('/transactions/reports/' + authInfo.user.instituteID);

                    setCategories(resultA.data.categories);
                    setIncomeSeries(resultA.data.incomeSeries);
                    setExpenseSeries(resultA.data.expenseSeries);
                    setIncome(resultA.data.income);
                    setSeries1(resultA.data.series1);
                    setExpense(resultA.data.expense);
                    setSeries2(resultA.data.series2);
    
                }

                if (authInfo.institute.academics && authInfo.user.roles.includes("Academic"))
                {
                    const birthday = await api.get(`/students/getBirthdaySchool/${authInfo.user.instituteID}`);
                    setStudents(birthday.data);

                    const schat = await api.get(`/inquiries/getSomePendingInstitute/${authInfo.user.instituteID}`);
                    setStudentChats(schat.data);

                    const attend = await api.get(`/attendances/getByInstitute/${authInfo.user.instituteID}`);
                    setStudentAttend(attend.data.attends);

                    const staff = await api.get(`/staffAttends/getLogAcademic/${authInfo.user.instituteID}`);
                    setTeacherAttend(staff.data);
                }

                if (authInfo.user.roles.includes("Teacher") && !authInfo.user.roles.includes("Academic"))
                {
                    const birthday = await api.get(`/students/getBirthdayTeacher/${authInfo.user._id}`);
                    setStudents(birthday.data);

                    const schat = await api.get(`/inquiries/getSomePendingTeacher/${authInfo.user._id}`);
                    setStudentChats(schat.data);
                }

                if (authInfo.institute.transport && authInfo.user.roles.includes("Transporter"))
                {
                    const resultT = await api.get(`/institutes/transport/${authInfo.user.instituteID}`);
                    setTrips(resultT.data.trips);
                    setRoutes(resultT.data.routes);

                    const driv = await api.get(`/staffAttends/getLogTransporter/${authInfo.user.instituteID}`);
                    setDriverAttend(driv.data);
                }

                // Fetch necessary data
                const [eve, circ, chat, task, entry, count] = await Promise.all([
                    api.get(`/feeds/getEventsByInstitute/${authInfo.user.instituteID}`),
                    api.get(`/circulars/getSomeByRole/${authInfo.user.instituteID}/${authInfo.user.role}`),
                    api.get(`/staffChats/getOneByEmployee/${authInfo.user._id}`),
                    api.get(`/staffChats/getHomeByEmployee/${authInfo.user._id}`),
                    api.get(`/staffAttends/getOneByEmployee/${authInfo.user._id}`),
                    api.get(`/notifications/getCountUser/${authInfo.user._id}`)
                ]);

                setEvents(eve.data);
                setCirculars(circ.data);
                setChats(chat.data);
                setTasks(task.data);
                setAttendance(entry.data);
                setUnread(count.data);

            } catch (error: any) {
                //
            }
        };

        const checkIntent = async () => {
            try {
                const result = await SendIntent.checkSendIntentReceived();
                if (result) {
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                }
            } catch (err) {
                console.error('Error checking intent:', err);
            }
        };

        const fetchVersion = async () => {
            setShowDownloading(true);
            try {
                const versionRes = await api.get(`/version-check`);
                const currentVersion = parseInt(process.env.REACT_APP_VERSION!);
    
                if (parseInt(versionRes.data) > currentVersion) {
                    setIsold(true);
                } else {
                    
                    if (isPlatform('android') && isPlatform('capacitor')) {
                        await checkIntent();
                    }

                    await fetchData();
                
                    if (isPlatform('capacitor')) {
                        const permissionRes = await PushNotifications.checkPermissions();
                        if (permissionRes.receive !== 'granted') {
                            const requestRes = await PushNotifications.requestPermissions();
                            if (requestRes.receive === 'granted') {
                                await register();
                            }
                        } else {
                            await register();
                        }
                    }
                }

                
            } catch (error: any) {  
                //
            } finally {
                setShowDownloading(false);
            }
        };

        fetchVersion();

        return () => {
            if (isPlatform('capacitor'))
            {
                stopScan();
            }
        }

    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                        {(isPlatform("capacitor")) && !scannerActive && authInfo.user.roles.includes("Academic") && (
                        <IonButton onClick={openScanner} >
                            <IonIcon 
                                icon={qrCodeOutline}
                                color="warning" />
                        </IonButton>
                        )}
                        {scannerActive && (
                        <IonButton onClick={stopScan} >
                            <IonIcon 
                                icon={closeOutline}
                                color="warning" />
                        </IonButton>
                        )}
                    </IonButtons>
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" id="ongoing-trip">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header={t("session_expired")}
                    message={t("login_again")}
                    buttons={[`${t("logout")}`]}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    subHeader={`${t("currently_selected")} - ${student?.name}`}
                    header={t("manage_student")}
                    buttons={actionButtons} 
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>

                {(isPlatform("capacitor")) && (
                    
                    <IonRow className='mt-30'>
                        {authInfo.user.roles.includes("Academic") && (
                        <>
                         <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/student`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_students")} src="assets/images/children.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_students")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {acad && (
                        <IonCol size="4">
                            <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/academics`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("academics")} src="assets/images/subjects.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("academics")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        {comm && (
                        <IonCol size="4">
                            <IonCard className="dash-card-1 ion-no-margin" routerLink={`/app/circulars`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={(type === "school") ? t("class_comm") : t("batch_comm")} src="assets/images/circulars.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {(type === "school") ? t("class_comm") : t("batch_comm")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}

                        </>
                        )}
                        {authInfo.user.roles.includes("Teacher") && !authInfo.user.roles.includes("Academic") && (
                        <>
                        <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/students`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_students")} src="assets/images/children.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_students")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {comm && (
                            <IonCol size="4">
                                <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/circulars`}>
                                    <IonCardContent className="ion-padding">
                                        <IonGrid className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol className='ion-no-padding'>
                                                    <img alt={(type === "school") ? t("class_comm") : t("batch_comm")} src="assets/images/circulars.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol className="dash-title-1 ion-no-padding">
                                                {(type === "school") ? t("class_comm") : t("batch_comm")}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            )}
                           {timetable && (
                            <IonCol size="4">
                                <IonCard className="dash-card-1 ion-no-margin" routerLink={`/app/subjects`}>
                                    <IonCardContent className="ion-padding">
                                        <IonGrid className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol className='ion-no-padding'>
                                                    <img alt={t("view_subjects")} src="assets/images/subjects.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol className="dash-title-1 ion-no-padding">
                                                {t("view_subjects")}
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            )}
                        </>
                        )}

                         {authInfo.user.roles.includes("Transporter") && transport && (
                        <>
                         <IonCol size="4">
                            <IonCard className="dash-card-6 ion-no-margin" routerLink={`/app/tracking`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("realtime_tracking")} src="assets/images/transport.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("realtime_tracking")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        </>
                        )}

                         {authInfo.user.roles.includes("Accountant") && payment && (
                        <IonCol size="4">
                            <IonCard className="dash-card-3 ion-no-margin" routerLink={`/app/accounting`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("accounting")} src="assets/images/payments.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {t("accounting")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}

                        {authInfo.user.roles.includes("Inventory") && inventory && (
                        <IonCol size="4">
                            <IonCard className="dash-card-7 ion-no-margin" routerLink={`/app/inventory`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("inventory")} src="assets/images/inventory.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("inventory")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                       
                        {authInfo.user.roles.includes("AdmissionHead") && admn && (
                        <IonCol size="4">
                            <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/crm`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("admn_inquiries")} src="assets/images/inquiries.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("admn_inquiries")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                    </IonRow>
                )}

                {(attendance) && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("attendance_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    
                            <IonCard className={
                                    (attendance.status === 'in') ? 'success-card' : 'danger-card'
                                }>
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    (attendance.status === 'in') ? 'success' : 'danger'
                                                    }
                                                />
                                                <IonLabel className="recent-label ion-text-wrap">
                                                        
                                                        {(attendance.status === 'out') && (
                                                            <>
                                                                <h3>{t("your_out_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'in') && (
                                                            <>
                                                                <h3>{t("your_in_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        
                                                </IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        
                        {(attendance.status === 'in') && (
                            <IonButton expand="block" color="dark" className='ion-margin-horizontal' onClick={() => history.push('/app/user/attendance')}>{t("mark_exit")}</IonButton>
                        )}
                        </IonCol>
                    </IonRow>
                </>
                )}

                {circulars && (circulars.length > 0) && 
                (<CircularsSwiper circulars={circulars} />)}

                    {authInfo.user.roles.includes("Accountant") && payment && (
                      <IonRow className="ion-padding-vertical max-lg">

                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_income")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-in">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{t("rs")} {income}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='6'>
                                                        <Chart options={options1} series={series1} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard className='action-card-d'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_expense")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-out">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{t("rs")} {expense}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='6'>
                                                        <Chart options={options1} series={series2} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("last_3_months")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <Chart options={options} series={series} type="bar" height={350} />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    )}


                    {authInfo.user.roles.includes("Transporter") && transport && (
                    <IonRow className="ion-padding-vertical max-lg">
                        <IonCol size="12" sizeMd='6' className='ion-padding-horizontal mt-30'>
                            <IonCard className="stat-card-7">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                        {routes}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top reports-label">
                                                    <IonCol>
                                                            {t("active")} {t("routes")}
                                                       </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                                <IonIcon 
                                                                    icon={analytics}
                                                                    className='reports-icon'
                                                                />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeMd='6' className='ion-padding-horizontal mt-30'>
                            <IonCard className="stat-card-6">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                        {trips}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-top reports-label'>
                                                    <IonCol>
                                                            {t("trips")} {t("completed")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                            <IonIcon 
                                                                icon={bus}
                                                                className='reports-icon'
                                                            />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                   
                    </IonRow>
                    )}


                    <IonRow className="ion-margin-vertical">
                        <IonCol className='ion-no-padding'>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{768: 1, 992: 2, 1200: 3}}
                            >
                                <Masonry>
                                {(tasks.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("my_tasks")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="danger" size="small" className='pr-16' onClick={() => history.push(`/app/user/tasks`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>

                                                        <IonList className='ion-no-padding'>
                                                            {(tasks.map((chat, i) => {
                                                                    return (
                                                                        <IonItem detail={true}
                                                                        detailIcon={chevronForward}
                                                                        lines='full'
                                                                        button={true}
                                                                        key={chat._id}
                                                                        onClick={
                                                                            () => history.push(`/app/user/task/${chat._id}`)
                                                                        }>
                                                                        <IonIcon slot="start"
                                                                            icon={create}
                                                                            color='danger'/>
                                                                        <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                                            <h3>{chat.title}
                                                                            <IonBadge className='ml-05' color={(chat.taskInfo!.priority === "high") ? "danger" : (chat.taskInfo!.priority === "medium") ? "warning" : "medium"}>
                                                                                {t(chat.taskInfo!.priority)}
                                                                            </IonBadge>
                                                                            <br/> 
                                                                            {t(chat.status)} <br/>
                                                                            {formatDate(chat.taskInfo!.startDate)} {t("to")} {formatDate(chat.taskInfo!.endDate)}
                                                                            </h3>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}

                                {(chats.length > 0) && (
                                    <IonCard className='ion-margin'>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("my_chats")}</IonCardSubtitle>
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-right'>
                                                    <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/user/chats`)}>{t("view_all")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>

                                                    <IonList className='ion-no-padding'>
                                                        {(chats.map((chat, i) => {
                                                                return (
                                                                    <IonItem detail={true}
                                                                    detailIcon={chevronForward}
                                                                    lines='full'
                                                                    button={true}
                                                                    key={chat._id}
                                                                    onClick={
                                                                        () => history.push(`/app/user/chat/${chat._id}`)
                                                                    }>
                                                                    <IonIcon slot="start"
                                                                        icon={chatbubbles}
                                                                        color='secondary'/>
                                                                    <IonLabel className="list-label text-capitalize">
                                                                        <h3>{chat.title}<br/> {chat.employee ? chat.employee.name : (chat.employees?.length === 1) ? chat.adminEmployee : `${t("employees")}: ${chat.employees!.length}`} ({chat.type})<br/>{formatDate(chat.createdAt)}</h3>
                                                                    </IonLabel>
                                                                </IonItem>
                                                                )
                                                            }))
                                                            } 
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>     
                                        
                                            
                                        </IonCardContent>
                                    </IonCard>
                                )}

                                {(inquiries.length > 0) && (
                                        <IonCard className="ion-margin">
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("recent_admn_inq")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/app/admission/inquiries/board`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>

                                                        <IonList className='ion-no-padding'>
                                                            {(inquiries.map((inquiry, i) => {
                                                                    return (
                                                                        <IonItem detail={true}
                                                                        detailIcon={chevronForward}
                                                                        lines='full'
                                                                        button={true}
                                                                        key={inquiry._id}
                                                                        onClick={
                                                                            () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                                                        }>
                                                                        <IonIcon slot="start"
                                                                            icon={searchCircle}
                                                                            color='primary'/>
                                                                        <IonLabel className="list-label text-capitalize">
                                                                        <IonBadge color={(inquiry.inquiry.status === "new") ? "medium" : ((inquiry.inquiry.status === "shared") || (inquiry.inquiry.status === "unreachable"))
                                                                             ? "secondary" : (inquiry.inquiry.status === "converted") ? "success" : ((inquiry.inquiry.status === "failed") || (inquiry.inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                                        {t(inquiry.inquiry.status)}
                                                                        </IonBadge>
                                                                        <h3>{inquiry.inquiry.userInfo.name} ({inquiry.inquiry.type})<br/>{formatDateTime(inquiry.nearestDate.update.dated)}</h3>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}

                                {(appointments.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("upcoming_appoint")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="tertiary" size="small" className='pr-16' onClick={() => history.push(`/app/admission/appointments`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>

                                            {appointments.map((event) => {
                                            return (
                                                <IonCard className="planner-card-3" key={event._id}>
                                                    <IonCardContent>
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonItem lines="none"
                                                                    button={false}
                                                                    detail={false}
                                                                    >
                                                                        <IonIcon icon={calendar}
                                                                            slot="start"
                                                                            color="tertiary" />
                                                                        <IonLabel className="action-title-one">
                                                                            <h3>{event.childInfo.childName} - ({event.classInfo.className})</h3>
                                                                            <p>{formatDate(event.appointmentInfo.dated)}</p>
                                                                            </IonLabel>
                                                                    
                                                                    </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonText>{event.appointmentInfo.info}</IonText>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                                    );
                                                })
                                            }
                                                    </IonCol>
                                                </IonRow>          
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}

                                {(studentChats.length > 0) && (
                                        <IonCard className="ion-margin">
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("manage_inquiries")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/inquiries/pending`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>

                                                        <IonList className='ion-no-padding'>
                                                            {(studentChats.map((inquiry, i) => {
                                                                    return (
                                                                        <IonItem detail={true}
                                                                        detailIcon={chevronForward}
                                                                        lines='full'
                                                                        button={true}
                                                                        key={inquiry._id}
                                                                        onClick={
                                                                            () => history.push(`/app/inquiry/${inquiry._id}`)
                                                                        }>
                                                                        <IonIcon slot="start"
                                                                            icon={chatbubbles}
                                                                            color='secondary'/>
                                                                        <IonLabel className="list-label">
                                                                            <h3>{inquiry.title}<br/>{inquiry.student ? inquiry.student.name : (inquiry.students?.length === 1) ? inquiry.adminStudent : `${t("students")}: ${inquiry.students!.length}`} ({inquiry.type})<br/>{formatDate(inquiry.createdAt)}</h3>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}

                                {(events.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("upcoming_events")}</IonCardSubtitle>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>

                                            {events.map((event) => {
                                            return (
                                                <IonCard className="planner-card-2" key={event._id}>
                                                    <IonCardContent>
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonItem lines="none"
                                                                    button={false}
                                                                    detail={false}
                                                                    >
                                                                        <IonIcon icon={sparkles}
                                                                            slot="start"
                                                                            color="primary" />
                                                                        <IonLabel className="action-title-one">
                                                                            <h3>{event.title}</h3>
                                                                            <p>{formatDate(event.calendarDate)}</p>
                                                                            </IonLabel>
                                                                    
                                                                    </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonText>{event.description}</IonText>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                                    );
                                                })
                                            }
                                                    </IonCol>
                                                </IonRow>          
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}
                                
                                {(students.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("birthdays_this_week")}</IonCardSubtitle>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>

                                                        <IonList className='ion-no-padding'>
                                                            {(students.map((student, i) => {
                                                                    return (
                                                                        <IonItem detail={false}
                                                                            lines='inset'
                                                                            button={false}
                                                                            key={student._id}
                                                                            className="leaderboard-item"
                                                                            >
                                                                        
                                                                            <IonAvatar className="leaderboard-avatar" onClick={() => history.push(`/app/student/${student._id}`)}>
                                                                            {student.photo && (student.photo !== "") ?
                                                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={student.name} />)
                                                                                : (<img src={`assets/images/avatar.png`} alt={student.name} />)
                                                                            }
                                                                            </IonAvatar>
                                                                            <IonLabel className='leaderboard-name'>
                                                                                <h3>{student.name}</h3>
                                                                                <p>{formatDate(student.dob)}</p>
                                                                            </IonLabel>
                                                                        
                                                                        </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}

                                {(studentAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attend/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(studentAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        ((attend.status === 'in') || (attend.status === 'entry') || (attend.status === 'reached')) ? 'success' : ((attend.status === 'picked') || (attend.status === 'left')) ? 'warning' : (attend.status === 'custody') ? 'tertiary' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/student/${attend.studentID}`} className='link-dark'>{attend.studentName}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'entry') && (
                                                                            <span> {(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'custody') && (
                                                                            <span> {t("custody_with_parent")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'picked') && (
                                                                            <span> {t("picked_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'reached') && (
                                                                            <span> {t("reached_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'left') && (
                                                                            <span> {t("left_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'dropped') && (
                                                                            <span> {t("dropped_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3> 
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}

                                    {(teacherAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("teacher_attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/teacher/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(teacherAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        (attend.status === 'in') ? 'success' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/employee/${attend.employee.employeeID}`} className='link-dark'>{attend.employee.name}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3>
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}

                                    {(driverAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("driver_attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/driver/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(driverAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        (attend.status === 'in') ? 'success' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/employee/${attend.employee.employeeID}`} className='link-dark'>{attend.employee.name}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3>
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}

                                {(salesRepAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("salesrep_attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/salesrep/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(driverAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        (attend.status === 'in') ? 'success' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/employee/${attend.employee.employeeID}`} className='link-dark'>{attend.employee.name}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3>
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}
                                </Masonry>
                            </ResponsiveMasonry>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default EmployeeHome;