import {
    IonBadge, IonCard, IonCardContent, IonCol, IonRow, IonTitle,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import '@ionic/react/css/ionic-swiper.css';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface SwiperPage {
    circulars: Circular[]
}

const CircularsSwiper: React.FC<SwiperPage> = (props) => {
    const { t } = useTranslation();

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    return (
    <IonRow className="ion-margin-top">
        <IonCol className='ion-no-padding'>
            <IonRow className="ion-padding-top ion-text-center">
                <IonCol>
                    <IonTitle className='list-title'>
                    {t("latest_news")}
                    </IonTitle>   
                </IonCol>
            </IonRow>
            <IonRow className="netflix-slider">
                <IonCol className="swiper-container">
                    <Swiper 
                    modules={[Navigation, Pagination]}
                    className="swiper-wrapper"
                    spaceBetween={10}
                    slidesPerView={4}
                    slidesPerGroup={1}
                    pagination={{ el: '.swiper-pagination', clickable: true }}
                    navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    loop={false}
                    centeredSlides={true}
                    >

                        {props.circulars.map((circular) => {
                            return (
                            <SwiperSlide key={circular._id} className='swiper-slide ion-text-left'>
                                <IonRow>
                                    <IonCol>
                                        <IonCard className={circular.filename ? 'circular-netflix full-width' : 'announce-netflix full-width'}
                                                    routerLink={`/app/news/${circular._id}`} > 
                                            <IonCardContent className='ion-no-padding'>

                                                    <IonRow className='ion-padding-horizontal'>
                                                        <IonCol className='netflix-col ion-padding-bottom'>
                                                            <h3>{formatDate(circular.createdAt)}</h3>
                                                            <IonBadge className={circular.filename ? 'circular-badge' : 'announce-badge'}>{circular.filename ? t("circular") : t("announcement") }</IonBadge>
                                                            <p>{circular.title}</p>

                                                        </IonCol>
                                                    
                                                    </IonRow>

                                                </IonCardContent>
                                            </IonCard>
                                    </IonCol>
                                    
                                </IonRow>
                            
                            </SwiperSlide>  
                            )})
                        }
                    </Swiper>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </IonCol>
            </IonRow>
            
        </IonCol>
    </IonRow>
    );
}

export default CircularsSwiper;
