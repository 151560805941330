import {
    IonButtons,
    IonContent,
    IonHeader,
    IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonCard,
    IonCardContent,
    IonItem,
    IonMenuButton,
    IonText,
    isPlatform,
    IonAvatar,
    IonLoading,
    IonAlert,
    IonPopover,
    IonButton,
    IonBadge,
    IonCardHeader,
    IonCardSubtitle
} from '@ionic/react';
import {
    accessibilityOutline,
    accessibilitySharp,
    camera,
    logOutOutline,
    logOutSharp,
    personOutline,
    personSharp,
    refreshOutline,
    refreshSharp,
    trash
} from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    color: string;
}

const Profile: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [employeeID, setEmployeeID] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    let appPages: AppPage[] = [];

    if (authInfo.user.role === "Parent") {
        appPages = [
            {
                title: t("personal_details"),
                url: '/app/user/profile/personal',
                iosIcon: personOutline,
                mdIcon: personSharp,
                color: 'tertiary'

            }, {
                title: t("students_details"),
                url: '/app/user/profile/student',
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                color: 'success'
            }, {
                title: t("change_password"),
                url: '/app/user/profile/password',
                iosIcon: refreshOutline,
                mdIcon: refreshSharp,
                color: 'primary'

            }
        ];
    } else {
        appPages = [
            {
                title: t("personal_details"),
                url: '/app/user/profile/personal',
                iosIcon: personOutline,
                mdIcon: personSharp,
                color: 'tertiary'

            }, {
                title: t("change_password"),
                url: '/app/user/profile/password',
                iosIcon: refreshOutline,
                mdIcon: refreshSharp,
                color: 'primary'

            }
        ];
    }

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions().then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUploadFile(rawFile);
                                    setImageURL(webPath);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUploadFile(rawFile);
                            setImageURL(webPath);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        setImageURL(webPath);
                    }
                    else
                    {
                        setImageURL("");
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        setImageURL(base64);
                    }
                    else
                    {
                        setImageURL("");
                    }
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/employees/updatePhoto', formData).then(res => {
                        return resolve(res.data.employee.userInfo.name);
                }).catch(err => reject(err));
            });
        }

      let formData = new FormData();
      formData.append('image', uploadFile, 'image');
      formData.append('employeeID', employeeID);
  
        setShowLoading(true);
        upStud(formData)
        .then(data => {
        
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleProfile = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/users/updatePhoto', formData).then(res => {
                    return resolve(res.data.user.name);
                }).catch(err => reject(err));
            });
        }

      let formData = new FormData();
      formData.append('image', uploadFile, 'image');
      formData.append('userID', authInfo.user._id);
  
        setShowLoading(true);
        upStud(formData)
        .then(data => {
        
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                if ((authInfo.user.role === "Driver") || (authInfo.user.role === "Transporter") || (authInfo.user.role === "Teacher") || (authInfo.user.role === "Accountant")
                || (authInfo.user.role === "Academic") || (authInfo.user.role === "AdmissionHead") || (authInfo.user.role === "Inventory") || (authInfo.user.role === "Employee"))
                {
                    const result = await api.get('/employees/getByUID/' + authInfo.user._id);
                    setFileName(result.data.photo);
                    setEmployeeID(result.data._id);
                }
                if ((authInfo.user.role === "Admin") || (authInfo.user.role === "Super"))
                {
                    setFileName(authInfo.institute.logo);
                }
                if ((authInfo.user.role === "Parent") || (authInfo.user.role === "Admission"))
                {
                    setFileName(authInfo.user.photo);
                }

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_profile")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_profile")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard className="profile-card">
                                        <IonCardContent>
                                            <IonRow >
                                                <IonCol size="3" className='ion-text-right' id="open-upload-input">
                                                    <IonAvatar className="leader-avatar">
                                                    {fileName && (fileName !== "") ?
                                                        (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={t("profile_pic")} />)
                                                        : (<img src={`assets/images/avatar1.png`} alt={t("profile_pic")} />)
                                                    }
                                                    </IonAvatar>
                                                </IonCol>
                                                <IonCol size="9" className='pl-08 pt-08 mt-05'>
                                                    <IonText className='profile-name'>{authInfo.user.name}</IonText><br/>
                                                    <IonText className='profile-email'>{authInfo.user.email}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {!((authInfo.user.role === "Super") || (authInfo.user.role === "Admin")) &&
                            (
                            <IonPopover trigger="open-upload-input" className='upload-popup'>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">

                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    color="primary"
                                                    onClick={isPlatform("capacitor") ? takePhoto : pickImage}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                     {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(uploadFile) && (
                                                <IonItem detail={true}
                                                        detailIcon={trash}
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => {
                                                            setUploadFile(null);
                                                            setImageURL("");
                                                        }}
                                                    >
                                                    <IonBadge color="danger" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {imageURL && (
                                <IonRow>
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                            
                                                <img src={imageURL} 
                                                    alt={t("image_preview")}
                                                    width="120" 
                                                />

                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )}
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonButton onClick={((authInfo.user.role === "Parent") || (authInfo.user.role === "Admission")) ? handleProfile : handlePhoto}
                                            size="small"
                                            className="first-button"
                                            fill="clear">{t("update_photo")}
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                            )}

                            {
                            appPages.map((appPage, index) => {
                            return (
                            <IonRow key={index} >
                                <IonCol>
                                    <IonCard className={`ion-margin-top ${appPage.color}-card`}>
                                        <IonCardContent className="ion-padding">                                   
                                            <IonItem 
                                                detail={true}
                                                lines='none'
                                                button={true}
                                                onClick={
                                                    () => {
                                                        history.push(appPage.url);
                                                    }
                                            }>
                                                <IonIcon slot="start"
                                                    ios={
                                                        appPage.iosIcon
                                                    }
                                                    md={
                                                        appPage.mdIcon
                                                    }
                                                    color={appPage.color} />
                                                <IonLabel className="list-label-2" color={appPage.color}> {
                                                    appPage.title
                                                } </IonLabel>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                            })
                            }
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-margin-top red-card">
                                        <IonCardContent className="ion-padding">
                                            <IonItem button={true}
                                                onClick={
                                                    async () => {
                                                        await logOut();
                                                        history.replace('/login');
                                                    }
                                                }
                                                lines="none"
                                                detail={true}
                                            >
                                                <IonIcon slot="start"
                                                    ios={logOutOutline}
                                                    md={logOutSharp}
                                                    color="danger" />
                                                <IonLabel className="list-label-2" color="danger">{t("logout")}</IonLabel>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Profile;
