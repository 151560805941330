import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, calendar, informationCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Toast } from '@capacitor/toast';
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    className: string,
    status: string,
    doneAt: Date
}

interface Class {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

const EntryExit: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [students, setStudents] = useState < Student[] > ([]);
    const [classes, setClasses] = useState < Class[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB') +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US');
    };

    const formatDateOnly = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return " "+valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) + " at " + valueDate.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
        }
    };
      
    const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionID);
        setClassi("");
        setStudents([]);
    }


    const handleClassi = (classID : string) => {
        setClassi(classID);
       
        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
        {
            if (!session || session === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/attendances/getByClass/${classID}`);
                setStudents(studs.data);
                setSkip(0);
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const studs = await api.get(`/attendances/getAllInstitute/${authInfo.user.instituteID}/${session}`);
                    setStudents(studs.data);
                }
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const studs = await api.get(`/attendances/getAllTeacher/${authInfo.user._id}`);
                    setStudents(studs.data);
                }
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        if (classID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

    }

    const loadItems = async () => {

        if (!session || session === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        let limit = skip + 30;

        try {
            const result = await api.get(`/attendances/getAllInstitute/${authInfo.user.instituteID}/${session}?skip=${limit}`);

            if(result.data && result.data.length)
            {
                setStudents([...students, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            //
        }
        
    }

    const handleEntry = (studentID : string) => {

        const entry = () => {
            return new Promise((resolve, reject) => {
              api.get('/attendances/entry/' + studentID).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
            });
        }

        const entryDate = () => {
            return new Promise((resolve, reject) => {
              api.get(`/attendances/entry/${studentID}/${selectedDate}`).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
            });
        }

        if (typeof selectedDate === "string")
        {
            entryDate()
            .then(async (data) => {
    
                let studs = [...students];
                const index = studs.findIndex((val) => val._id === studentID);
                if (index > -1)
                {
                    studs[index].status = 'in';
                    studs[index].doneAt = new Date(selectedDate);
                }
                setStudents(studs);
                await Toast.show({
                    text: `${data} ${t("marked_entered")} ${formatDate(new Date(selectedDate))}`,
                });

            })
            .catch((error) => {
                //
            });
        }
        else
        {
            entry()
            .then(async (data) => {
    
                let studs = [...students];
                const index = studs.findIndex((val) => val._id === studentID);
                if (index > -1)
                {
                    studs[index].status = 'in';
                    studs[index].doneAt = new Date(Date.now());
                }
                setStudents(studs);
                await Toast.show({
                    text: `${data} ${t("marked_entered")} ${formatDate(new Date(Date.now()))}`,
                });

            })
            .catch((error) => {
                //
            });
        }
    }

    const handleExit = (studentID : string) => {

        const exit = () => {
            return new Promise((resolve, reject) => {
              api.get('/attendances/exit/' + studentID).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
            });
        }
        const exitDate = () => {
            return new Promise((resolve, reject) => {
              api.get(`/attendances/exit/${studentID}/${selectedDate}`).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
            });
        }
    

        if (typeof selectedDate === "string")
        {
            exitDate()
            .then(async (data) => {
    
                let studs = [...students];
                const index = studs.findIndex((val) => val._id === studentID);
                if (index > -1)
                {
                    studs[index].status = 'out';
                    studs[index].doneAt = new Date(selectedDate);
                }
                setStudents(studs);
                await Toast.show({
                    text: `${data} ${t("marked_exit")} ${formatDate(new Date(selectedDate))}`,
                });

            })
            .catch((error) => {
                //
            });
        }
        else
        {
            exit()
            .then(async (data) => {
            
                let studs = [...students];
                const index = studs.findIndex((val) => val._id === studentID);
                if (index > -1)
                {
                    studs[index].status = 'out';
                    studs[index].doneAt = new Date(Date.now());
                }
                setStudents(studs);
                await Toast.show({
                    text: `${data} ${t("marked_exit")} ${formatDate(new Date(Date.now()))}`,
                });

            })
            .catch((error) => {
                //
            });
        }

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
               
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setClasses(classy.data);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                    setClasses([]);
                    setClassi("");
                    setStudents([]);
                    setSkip(0);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("mark_entry_exit")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("mark_entry_exit")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding max-xl">
                  
                    <IonRow>
                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                       <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent> 
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon mt-05" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text'>
                                            <p>
                                                {t("entry_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        id="open-date-input">
                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("attendance_date")}</IonLabel>
                                        <IonInput className="input-field" value={typeof selectedDate !== 'string' ? t("current_time") : formatDated(selectedDate)} readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("pick_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        cancelText={t("cancel")}
                                                        doneText={t("ok")}
                                                        value={selectedDate}
                                                        presentation="date-time"
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                        </IonModal>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonTitle className="list-title">
                                {t("students_last_update")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    {(students.length > 0) && (
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                            <IonList className="ion-no-padding">
                            {(students.map((student) => {
                                return (
                                <IonItemSliding key={student._id}>

                                    {isPlatform("mobile") && (
                                        <IonItemOptions side="start" onIonSwipe={() => handleEntry(student._id)}>
                                            <IonItemOption color="success" onClick={() => handleEntry(student._id)}>{(authInfo.institute.type === "school") ? t("entered_school") : t("entered_insti")}</IonItemOption>
                                        </IonItemOptions>
                                    )}

                                    <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                            <IonIcon slot="start"
                                            icon={calendar}
                                            color={
                                                ((student.status === 'in') || (student.status === 'entry') || (student.status === 'reached')) ? 'success' : ((student.status === 'picked') || (student.status === 'left')) ? 'primary' : (student.status === 'custody') ? 'tertiary' : (student.status === 'out') ? 'danger' : ''
                                            }/>
                                        <IonLabel>
                                            <h2 className="text-capitalize">{student.name} {(classi === "all") && ("("+student.className+")")} </h2>
                                            {(student.status === 'out') && (
                                                <p>{(authInfo.institute.type === "school") ? t("left_school_at") : t("left_insti_at")} {formatDate(student.doneAt)}</p>
                                            )}

                                            {(student.status === 'in') && (
                                                <p>{(authInfo.institute.type === "school") ? t("entered_school_at") : t("entered_insti_at")} {formatDate(student.doneAt)}</p>
                                            )}

                                            {(student.status === 'entry') && (
                                                <p>{(authInfo.institute.type === "school") ? t("entered_school_at") : t("entered_insti_at")} {formatDateOnly(student.doneAt)}</p>      
                                            )}

                                            {(student.status === 'custody') && (
                                                <p>{t("custody_with_parent")} {formatDate(student.doneAt)}</p>
                                            )}
                                            {(student.status === 'picked') && (
                                                <p>{t("picked_by_transport")} {formatDate(student.doneAt)}</p>
                                            )}
                                            {(student.status === 'reached') && (
                                                <p>{t("reached_by_transport")} {formatDateOnly(student.doneAt)}</p>
                                            )}
                                            {(student.status === 'left') && (
                                                <p>{t("left_by_transport")} {formatDate(student.doneAt)}</p>
                                            )}
                                            {(student.status === 'dropped') && (
                                                <p>{t("dropped_by_transport")} {formatDateOnly(student.doneAt)}</p>
                                            )}

                                        </IonLabel>
                                        {!isPlatform("mobile") && 
                                        (<IonButtons slot="end">
                                                <IonButton className="present-button" onClick={
                                                    () => handleEntry(student._id)
                                                }>
                                                    {(authInfo.institute.type === "school") ? t("entered_school") : t("entered_insti")}
                                                </IonButton>
                                                
                                                <IonButton className="absent-button" onClick={
                                                    () => handleExit(student._id)
                                                }>
                                                    {(authInfo.institute.type === "school") ? t("left_school") : t("left_insti")}
                                                </IonButton>
                                        </IonButtons>
                                        )}
                                    </IonItem>
                                
                                    {isPlatform("mobile") && (
                                        <IonItemOptions side="end" onIonSwipe={() => handleExit(student._id)}>
                                            <IonItemOption color="danger" onClick={() => handleExit(student._id)}> {(authInfo.institute.type === "school") ? t("left_school") : t("left_insti")}</IonItemOption>
                                        </IonItemOptions>
                                    )}
                                    
                                </IonItemSliding>
                                )
                            }))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                    )}
                            
                {(students.length === 0) && ( 
                    <IonRow>
                        <IonCol>
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )} 

            {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (classi === "all") && (students.length > 0) && (

                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    )}
                  
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow className="max-xl">
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("total")}: {students.length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("entered")}: {students.filter(val => val.status === "in").length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("left")}: {students.filter(val => val.status === "out").length}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default EntryExit;
