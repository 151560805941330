import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSkeletonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { ribbon } from 'ionicons/icons';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Question {
    _id: string,
    title: string,
    statement: string,
    statementImage?: string,
    options: string[],
    answer: number,
    solution: string,
    solutionImage?: string,
    isActive: boolean,
    order: number
}
interface Answer {
    questionID: string,
    selectedAnswer: number
}

interface ParamTypes {
    topicID: string
}

const TopicAssessment: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {topicID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [questions, setQuestions] = useState< Question[] > ([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [theAnswers, setTheAnswers] = useState< Answer[] > ([]);
    const [answers, setAnswers] = useState< Answer[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [score, setScore] = useState < number > ();
    const [topicName, setTopicName] = useState < string > ("");
    const [studentID, setStudentID] = useState < string > ("");

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const handleAnswerChange = (e: CustomEvent) => {
        const selectedAnswer = parseInt(e.detail.value, 10);
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestion] = {
            questionID: questions[currentQuestion]._id,
            selectedAnswer
        };
        setAnswers(updatedAnswers);
    };
    
    const handleNextQuestion = () => {
        setCurrentQuestion(currentQuestion + 1);
    };
    
    const handlePreviousQuestion = () => {
        setCurrentQuestion(currentQuestion - 1);
    };

    const handleSubmit = () => {

        if (!studentID || studentID === "") {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if ((answers.length > 0) && (answers.length !== questions.length)) {
            setMessage(t("answers_mandatory"));
            setIserror(true);
            return;
        }
        
        const addReport = () => {
            return new Promise((resolve, reject) => {
              api.post('/evaluations/addAssessment', {studentID, topicID, topicName, answers}).then(res => {
                return resolve(res.data.evaluation);
              }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        addReport()
        .then((data: any) => {
            setPass(t("submission_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudentID(student.data._id);

                const res = await api.post('/evaluations/getByStudentTopic', {'studentID': student.data._id, topicID});
                if (res.data)
                {
                    setTheAnswers(res.data.answers);
                    setScore(res.data.score);
                }

                const top = await api.get(`/topics/${topicID}`);
                setTopicName(top.data.name);

                if (top.data.questions) {
                    if (res.data) {
                        // Filter questions that have been answered
                        const ques = top.data.questions.filter((item: Question) => {
                            const answeredQuestion = res.data.answers.find((answer: Answer) => answer.questionID === item._id);
                            return answeredQuestion !== undefined;
                        });
                    
                        const sorted = ques.sort((a: Question, b: Question) => a.order - b.order);
                        setQuestions(sorted);
                    } else {
                        const ques = top.data.questions.filter((item: Question) => ((item.isActive) && (item.answer !== undefined)));
                        const sorted = ques.sort((a: Question, b: Question) => a.order - b.order);
                        setQuestions(sorted);
                    }
                    
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo, topicID, refreshed]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/planner`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(topicName !== "") ? (topicName) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(topicName !== "") ? (topicName) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                   
                    {(score !== undefined) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='ion-no-margin profile-photo-card'>
                                <IonCardContent className="ion-padding">
                                    <IonRow>
                                        <IonCol size="3" className='ion-text-center'>
                                            <IonIcon icon={ribbon} color={score > 50 ? 'success' : 'danger'} className='second-icon mt-10' />
                                        </IonCol>
                                        <IonCol size="9">
                                            <span className="result-heading">{t("result")}</span><br/>
                                            <span className="result-score">{score}%</span>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>
                    )}

                   {(theAnswers.length > 0) && (questions.length > 0) && (
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='ion-no-margin'>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("question")} {currentQuestion+1}/{questions.length}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            <p>{questions[currentQuestion].statement}</p>
                                            {(questions[currentQuestion].statementImage !== undefined) && (questions[currentQuestion].statementImage !== "none") && (
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("problem_image")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                    
                                                            <img
                                                            src={`${process.env.REACT_APP_STATIC_S3}/images/${questions[currentQuestion].statementImage}`} 
                                                            alt={t("problem_image")}
                                                            width="320" 
                                                            onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/images/${questions[currentQuestion].statementImage}`, questions[currentQuestion].title)}/>
                                                    
                                                </IonCardContent>        
                                            </IonCard>
                                            )}
                                        </IonCol>
                                    </IonRow>          
                                    <IonRow className="ion-margin-top">
                                        <IonCol>
                                            <IonRadioGroup value={theAnswers[currentQuestion] && theAnswers[currentQuestion].selectedAnswer} >
                                                {questions[currentQuestion].options.map((option, i) => {
                                                return (
                                                    <IonCard key={i} className={(questions[currentQuestion].answer === i) ? 'note-card-3' : (theAnswers[currentQuestion].selectedAnswer === i) ? 'note-card-2' : 'border-medium'}>
                                                        <IonCardContent className='ion-no-padding'>
                                                            <IonRow>
                                                                <IonCol>            
                                                                    <IonItem lines="none"
                                                                        button={false}
                                                                        detail={false}
                                                                        
                                                                    >
                                                                    <IonRadio slot="start" value={i} disabled={true}></IonRadio>
                                                                        <IonTextarea autoGrow={true} value={option} readonly={true}></IonTextarea>
                                                                        
                                                                    </IonItem>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCardContent>
                                                    </IonCard>
                                                        );
                                                    })}
                                            </IonRadioGroup>     
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="mt-30">
                                        <IonCol>
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("solution")}</IonCardSubtitle>
                                            <p>{questions[currentQuestion].solution}</p>
                                            {(questions[currentQuestion].solutionImage !== undefined) && (questions[currentQuestion].solutionImage !== "none") && (
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("solution_image")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                    
                                                            <img
                                                            src={`${process.env.REACT_APP_STATIC_S3}/images/${questions[currentQuestion].solutionImage}`} 
                                                            alt={t("solution_image")}
                                                            width="320" 
                                                            onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/images/${questions[currentQuestion].solutionImage}`, questions[currentQuestion].title)}/>
                                                    
                                                </IonCardContent>        
                                            </IonCard>
                                            )}
                                        </IonCol>
                                    </IonRow>   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                    {(theAnswers.length === 0) && (questions.length > 0) && (
                    
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='ion-no-margin'>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("question")} {currentQuestion+1}/{questions.length}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            <p>{questions[currentQuestion].statement}</p>
                                            {(questions[currentQuestion].statementImage !== undefined) && (questions[currentQuestion].statementImage !== "none") && (
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("problem_image")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                    
                                                    <img
                                                    src={`${process.env.REACT_APP_STATIC_S3}/images/${questions[currentQuestion].statementImage}`} 
                                                    alt={t("problem_image")}
                                                    width="320" 
                                                    onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/images/${questions[currentQuestion].statementImage}`, questions[currentQuestion].title)}/>
                                                    
                                                </IonCardContent>        
                                            </IonCard>
                                            )}
                                        </IonCol>
                                    </IonRow>          
                                    <IonRow className="ion-margin-top">
                                        <IonCol>
                                            <IonRadioGroup value={answers[currentQuestion] && answers[currentQuestion].selectedAnswer} onIonChange={handleAnswerChange} >
                                                {questions[currentQuestion].options.map((option, i) => {
                                                return (
                                                    <IonCard key={i} className="border-medium">
                                                        <IonCardContent className='ion-no-padding'>
                                                            <IonRow>
                                                                <IonCol>            
                                                                    <IonItem lines="none"
                                                                        button={false}
                                                                        detail={false}
                                                                    >
                                                                    <IonRadio slot="start" value={i}></IonRadio>
                                                                        <IonTextarea autoGrow={true} value={option} readonly={true}></IonTextarea>
                                                                        
                                                                    </IonItem>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCardContent>
                                                    </IonCard>
                                                        );
                                                    })}
                                            </IonRadioGroup>     
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="6">
                            <IonButton onClick={handlePreviousQuestion}
                                disabled={(currentQuestion === 0)}
                                className="first-button"
                                fill="clear"
                                >&lt; {t("previous")}</IonButton>

                        </IonCol>
                        {(theAnswers.length === 0) && (
                        <IonCol size="6" className="ion-text-right">
                        
                            {(currentQuestion !== questions.length - 1) && (
                            <IonButton onClick={handleNextQuestion}
                                className="first-button"
                                fill="clear"
                                >{t("next")} &gt;</IonButton>
                            )}
                            {(currentQuestion === questions.length - 1) && (
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("submit")} &gt;</IonButton>
                            )}
                        </IonCol>
                        )}
                        {(theAnswers.length > 0) && (
                        <IonCol size="6" className="ion-text-right">

                            <IonButton onClick={handleNextQuestion}
                                disabled={(currentQuestion === questions.length - 1)}
                                className="first-button"
                                fill="clear"
                                >{t("next")} &gt;</IonButton>
                        
                        </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default TopicAssessment;
