import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    tranID: string
}

const AppSuccess: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {tranID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [amount, setAmount] = useState < number > (0);

    const today = new Date();
    const date = today.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    const time = today.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/payments/${tranID}`);
                setAmount(result.data.netPayable);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, tranID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("payment_status")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("payment_status")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='ion-padding-horizontal'>
                    <IonRow className="ion-padding-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className="ion-text-center white-bg" >
                            <img alt={t("payment_success")} src="assets/images/success.gif" width="160" height="160" />
                        </IonCol>
                    </IonRow>
     
                    <IonRow className="ion-no-margin ion-no-padding">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className="ion-no-margin ion-no-padding">
                            <IonList className="ion-no-margin ion-no-padding">
                                <IonItem lines="none" color="secondary" className="ion-text-center">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                {t("payment_success")}
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonText className='first-meta'>
                                                {t("trans_id")}
                                                </IonText>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonText className='second-meta'> {tranID} </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonText className='first-meta'>
                                                {t("trans_amt")}
                                                    </IonText>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonText className='second-meta'> 
                                                &#8377; {amount}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonText className='first-meta'>
                                                {t("trans_date")}
                                                    </IonText>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonText className='second-meta'>  {date} at {time} </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className='ion-text-center'>
                            <IonButton onClick={() => history.push(`/app/home`)}
                                className="first-button"
                                fill="clear"
                            >
                                {t("back_to_home")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default AppSuccess;
