import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { addCircle, alertCircleOutline, closeCircle, helpCircleOutline, searchCircleOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    studentID?: string
}

interface Component {
    head: FeeHead,
    frequency: string,
    amount: number
}

interface FeeHead {
    headID: string,
    name: string
}

interface AllHead {
    _id: string,
    name: string,
    isActive: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface Student {
    _id: string,
    name: string,
    isActive: boolean
}

interface Fee {
    _id: string,
    title: string,
    components: Component[],
    isActive: boolean
}

interface SearchStudent {
    _id: string,
    classID: string,
    name: string,
    classInfo: ClassInfo,
    photo?: string
}
interface ClassInfo {
    className: string,
    session: string,
    sessionID: string
}

const StudentFee: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [components, setComponents] = useState < Component[] > ([]);
    const [heads, setHeads] = useState < AllHead[] > ([]);
    const [selectedStudent, setSelectedStudent] = useState <string> ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [fees, setFees] = useState < Fee[] > ([]);
    const [selectedFees, setSelectedFees] = useState < string > ("");
    const [ispass2, setIspass2] = useState < boolean > (false);
    const [pass2, setPass2] = useState < string > ("");
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState < string > ("");
    const [searchText, setSearchText] = useState<string>("");
    const [searchStudents, setSearchStudents] = useState < SearchStudent[] > ([]);
    const [showSearch, setShowSearch] = useState < boolean > (false);
    const [skip, setSkip] = useState<number>(0);
    const inputRef = useRef<HTMLIonSearchbarElement>(null); // To get back focus

    const handleStruct = (value: string) => {

        let comps = fees.find(val => val._id === value)!.components;
        setComponents(comps);
        setSelectedFees(value);
            
    }

    const handleAmount = (amt: string, index: number) => {

        let comps = [...components];
        comps[index].amount = parseInt(amt);
        setComponents(comps);
        
    }

    const handleHead = (headID: string, index: number) => {

        const head = heads.find(val => val._id === headID);
        if (head)
        {
            const name = head.name;
            const feehead: FeeHead = {
                headID,
                name
            };
            let comps = [...components];
            comps[index].head = feehead;
            setComponents(comps);
        }
    }
        
    const handleFreq = (freq: string, index: number) => {

        let comps = [...components];
        comps[index].frequency = freq;
        setComponents(comps);
    
    }

    const handleDelete = (index: number) => {
        let comps = [...components];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
          }
          setComponents(comps);
    }

    const handleAdd = () => {
        let comps = [...components];
        comps.push({head: {headID: "", name: ""}, frequency: '', amount: 0});
        setComponents(comps);
    }

    const handleSubmit = () => {

        if(!selectedStudent || selectedStudent === "")
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if (components.length < 1)
        {
            setMessage(t("fee_comp_mandatory"));
            setIserror(true);
            return;
        }

        for (let i = 0; i < components.length; i++)
        {
            if (!components[i].head || !components[i].frequency || !components[i].amount 
                || components[i].head.headID === '' || components[i].head.name === '' || components[i].frequency === '' || components[i].amount === 0)
            {
                setMessage(t("fee_comp_invalid"));
                setIserror(true);
                return;
            }
        }
  
        const studentFee = ( components: Component[]) => {
          return new Promise((resolve, reject) => {
            api.put(`/students/${selectedStudent}`, { components }).then(res => {
              return resolve(res.data.name);
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        studentFee(components)
        .then(data => {
          setPass(data+t("has_been_updated"));
          setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent("");
        setStudents([]);
        setComponents([]);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent("");
        setComponents([]);
    }

    const handleStudent = (studentID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/students/${studentID}`);
                if (stud.data.ledgerInfo) {
                    setText(stud.data.ledgerInfo);
                } else {
                    setText("");
                }
                if (stud.data.feeComponents)
                {
                    setComponents(stud.data.feeComponents);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedStudent(studentID);
        setSelectedFees("");
    }

    const handleRemarks = () => {

        if (!text || text === "") {
            setMessage(t("remarks_mandatory"));
            setIserror(true);
            return;
        }

        setShowLoading(true);
        api.put(`/students/${selectedStudent}`, { ledgerInfo: text })
        .then(res => {
            setPass2(t("ledger_remarks")+t("has_been_updated"));
            setIspass2(true);
        })
        .catch((error: any) => {
            //
        })
        .finally(() => setShowLoading(false));
    }

    const handleSearchText = (search : string) => {

        if (search.length > 0) {
            const fetchUp = async () => {
                try {
                    const studs = await api.post(`/students/searchStudent/${authInfo.user.instituteID}`, {searchText: search});
                    setSearchStudents(studs.data.students);
                    inputRef.current?.setFocus();
    
                } catch (error: any) {
                   //
                }
            };
    
            fetchUp();
        } else {
            setSearchStudents([]);
        }

        setSearchText(search);
    }

    const handleSearchStudent = (studentID : string) => {

        const fetchUp = async (sessionID: string, classID: string) => {
            setShowLoading(true);
            try {

                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);

                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        const theStudent = searchStudents.find((el) => el._id === studentID)!;
        const sessionID = theStudent.classInfo.sessionID;
        const classID = theStudent.classID;

        fetchUp(sessionID, classID);
        setSession(sessionID);
        setClassi(classID);
        handleStudent(studentID);
        setShowSearch(false);

    }

    const loadItems = async () => {

        const limit = skip + 30;

        try {
            const result = await api.post(`/students/searchStudent/${authInfo.user.instituteID}?skip=${limit}`, {searchText});

            if(result.data.students && result.data.students.length) {
                setSearchStudents([...searchStudents, ...result.data.students]);
                setSkip(limit);
            }

        } catch (error: any) {
            //
        }
    }

      useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                const result = await api.get(`/heads/getAllInstitute/${authInfo.user.instituteID}`);
                setHeads(result.data);

                const fees = await api.get(`/fees/getAllInstitute/${authInfo.user.instituteID}`);
                setFees(fees.data);

                if (studentID)
                {
                    const stud = await api.get(`/students/${studentID}`);
                    if (stud.data.ledgerInfo) {
                        setText(stud.data.ledgerInfo);
                    }
                    if (stud.data.feeComponents)
                    {
                        setComponents(stud.data.feeComponents);
                    }
                    const classInfo = await api.get('/classes/' + stud.data.classID);
                    setSession(classInfo.data.sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                    setClasses(query.data);
                    setClassi(stud.data.classID);
                    const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                    setStudents(studs.data);
                    setSelectedStudent(studentID);
                }
                else
                {
                    setComponents([]);
                    setSession("");
                    setClasses([]);
                    setClassi("");
                    setStudents([]);
                    setSelectedStudent("");
                    setText("");
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, studentID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_fee_struct")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_fee_struct")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false) 
                    }
                    header={t("success")}
                    message={pass}
                    buttons={[
                        {
                        text: `${t("close")}`,
                        handler: () => {
                                (studentID ? history.push(`/app/dues/add/${studentID}`) : history.goBack());
                            }
                        },
                        {
                        text: `${t("generate_invoice")}`,
                        handler: () => {
                                (selectedStudent !== "") && history.push(`/app/dues/add/${selectedStudent}`);
                            }
                        }
                    ]}
                />

                <IonAlert isOpen={ispass2}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass2(false)
                    }
                    header={t("success")}
                    message={pass2}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

            <IonModal isOpen={isOpen} showBackdrop={true} onDidDismiss={() => setIsOpen(false)}
                initialBreakpoint={0.6} breakpoints={[0, 0.6]} handle={true}>

                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                    <IonToolbar>
                        <IonTitle>{t("ledger_remarks")}</IonTitle>
                    </IonToolbar>
                    
                    
                    <IonRow>
                        <IonCol>
                            <IonCard className='ion-no-margin ion-margin-horizontal'>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="floating" className="input-label" color="secondary">{t("ledger_remarks")}</IonLabel>
                                            <IonTextarea rows={6} value={text} onIonInput={(e) => setText(e.detail.value!)}></IonTextarea>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleRemarks}
                                className="first-button"
                                fill="clear"
                                >{t("save")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonContent>
                
            </IonModal>

             {/* Modal for displaying search results */}
             <IonModal isOpen={showSearch} showBackdrop={true} 
                        initialBreakpoint={1} breakpoints={[1]} handle={false}
                        onDidDismiss={() => setShowSearch(false)}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{t("find_student")}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowSearch(false)}>{t("close")}</IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent onTouchMove={(e) => {e.stopPropagation()}}>
                        <IonSearchbar value={searchText} 
                            placeholder={t("search_student")}
                            ref={inputRef}
                            onIonInput={
                            (e) => handleSearchText(e.detail.value!)
                        }>
                        </IonSearchbar>
                        <IonList>
                        {(searchStudents.length > 0) ? (
                            searchStudents.map((stud) => 
                            (<IonItem key={stud._id}
                                button={true}
                                detail={false}
                                onClick={() => handleSearchStudent(stud._id)}
                                >
                                <IonAvatar slot="start">
                                    {stud.photo && (
                                        <IonImg src={`${process.env.REACT_APP_STATIC_S3}/images/${stud.photo}`} />
                                    )}
                                    {!stud.photo && (
                                        <IonImg src={`assets/images/avatar.png`} />
                                    )}
                                </IonAvatar>
                                <IonLabel>
                                <h2 className="text-capitalize">{stud.name} ({stud.classInfo.className})</h2>
                                </IonLabel>
                            </IonItem>)
                            )) : (
                            <IonItem lines="none">
                                <IonIcon icon={alertCircleOutline}
                                    slot="start"
                                    color="danger" />
                                <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                            </IonItem>
                            )}
                            
                        </IonList>
                            {/* Infinite Scroll for Loading More Results */}
                        {searchStudents.length > 0 && (
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        )}
                    </IonContent>
                </IonModal>

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle onClick={() => setShowSearch(true)} className='border-bottom-sec'>
                                {t("select_student")}
                                <IonIcon className="help-icon" color="primary" icon={searchCircleOutline} />
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                         <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}</IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("class_session")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                    <IonSelect value={classi}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                            onIonChange={
                                                                (e) => handleClassi(e.detail.value)
                                                        }>
                                                            
                                                            {classes.map((classy) => (
                                                                (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                            ))}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                            </IonRow>
                         </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                        <IonSelect value={selectedStudent}
                                                    className="input-field text-capitalize"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_student")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleStudent(e.detail.value)
                                                }>
                                                { students.map((student) => {
                                                        
                                                    return (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>);
                                                        
                                                    })
                                                }
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(selectedStudent !== "") && (
                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-text-center">
                            <IonButton color="primary" onClick={() => setIsOpen(true)}>{t("ledger_remarks")}</IonButton> 
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle id="open-fees-info" className='border-bottom-sec'>
                                {t("set_fee_struct")}
                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                            </IonTitle>
                            <IonPopover trigger="open-fees-info" className='info-popover'>
                                <IonCard className='note-card-3 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol className='note-text'>
                                                <p>
                                                {t("stud_fees_help")}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("fee_struct")}</IonLabel>
                                            <IonSelect value={selectedFees}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("fee_struct")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleStruct(e.detail.value)
                                                }>

                                                    {fees.map((fee) => (
                                                        (<IonSelectOption key={fee._id} value={fee._id}>{fee.title}</IonSelectOption>)
                                                    ))}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("fee_struct_comps")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        <div className="ion-table">

                            <IonRow className="table-title ion-padding">
                                <IonCol size="4" className='table-heading heading-fixed mt-05'>
                                        {t("fee_head")}
                                </IonCol>
                                <IonCol size="4" className='table-heading mt-05'>
                                        {t("fee_frequency")}
                                </IonCol>
                                <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                        {t("fee_amount")}
                                </IonCol>

                                <IonCol size="1">
                                    <IonIcon 
                                        icon={addCircle}
                                        color="light"
                                        className='table-icon ml-05'
                                        onClick={handleAdd}
                                        size="large"
                                        />
                                </IonCol>
                            </IonRow>
                       

                            {(components.length > 0) ? (components.map((component, i) => {
                                return (
                                    <IonRow key={i} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            <IonCol size="4" className='table-field' >
                                                <IonItem>
                                                    <IonSelect value={component.head.headID}
                                                            className='ion-no-padding' 
                                                            style={{'maxWidth': '100%'}} 
                                                            placeholder={t("fee_head")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleHead(e.detail.value!, i)
                                                        }>
                                                            <IonLabel>{t("fee_head")}</IonLabel>
                                                            {heads.length > 0 && heads.map((head) => (
                                                                (head.isActive) && 
                                                                (<IonSelectOption key={head._id} value={head._id}>{head.name}</IonSelectOption>)
                                                            ))}
  
                                                    </IonSelect>
                                                </IonItem>
                                            </IonCol>

                                            <IonCol size="4" className='table-field' >
                                                <IonItem>
                                                    <IonSelect placeholder={t("fee_frequency")}
                                                        className='ion-no-padding' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={component.frequency}
                                                        onIonChange={
                                                            (e) => handleFreq(e.detail.value!, i)
                                                        }
                                                        >
                                                        <IonLabel>{t("fee_frequency")}</IonLabel>
                                                        <IonSelectOption value="once">{t("once")}</IonSelectOption>
                                                        <IonSelectOption value="annually">{t("annually")}</IonSelectOption>      
                                                        <IonSelectOption value="quaterly">{t("quaterly")}</IonSelectOption>   
                                                        <IonSelectOption value="monthly">{t("monthly")}</IonSelectOption>   
                                                    </IonSelect>
                                                    
                                                </IonItem>
                                               
                                            </IonCol>

                                            <IonCol size="3" className='table-field ' >
                                                <IonItem className='fee-comp-height'>
                                                    <IonInput type="number"
                                                        className='ion-text-right'
                                                        placeholder='0'
                                                        value={component.amount}
                                                        onIonInput={
                                                            (e) => e.detail.value && handleAmount(e.detail.value, i)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    className='mt-15 ml-05'
                                                     icon={closeCircle}
                                                     color='dark'
                                                     onClick={() => handleDelete(i)}
                                                     size="large"
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_fee_comps")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 

                        </div>
                    </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_student")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StudentFee;