import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { addCircleOutline, alertCircleOutline, informationCircleOutline, sparkles, trash } from 'ionicons/icons';
import { Calendar, Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string
}

interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

const Planner: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [events, setEvents] = useState < Event[] > ([]);
    const [selectedDay, setSelectedDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [pop, setPop] = useState < boolean > (false);
    const [title, setTitle] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [description, setDescription] = useState < string > ("");
    const [selectedEvent, setSelectedEvent] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);

    const formatDated = (value: Day) => {
        return value.day+"/"+value.month+"/"+value.year;
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
                        t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
                        ];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const handleDelete = () => {
    
        const deleteApp = () => {
            return new Promise((resolve, reject) => {
                api.delete(`/feeds/${selectedEvent}`).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        };
    
        setShowLoading(true);
        deleteApp()
        .then(() => {
          setPass(t("event_deleted"));
          setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string")
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const minDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: 1
            }

            const maxDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
            }

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const events = await api.get(`/feeds/getByMonth/${authInfo.user.instituteID}/${theDate}`);

                    let result: Array < Attend > = [];

                    for (let i = 0; i < events.data.length ; i++) {
                        let dated: Date = new Date(events.data[i].calendarDate);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                        result.push(calObj);
                
                    }
                    setAttend(result);
                    setMinDay(minDate);
                    setMaxDay(maxDate);
                    setSelectedDay(minDate);
                
                } catch (error: any) {
                   //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    const handleDate = async (dated : Day) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.post('/feeds/getByDay', {'instituteID': authInfo.user.instituteID, 'day': dated});
                setEvents(result.data);
                setSelectedDay(dated);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();     
    }

    const handleAdd = () => {

        if (!title || title === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedDay) {
            setMessage(t("event_date"));
            setIserror(true);
            return;
        }

        let byear = selectedDay.year;
        let bmonth = selectedDay.month;
        let zeromonth = ('0'+bmonth).slice(-2);
        let bday = selectedDay.day;
        let zeroday = ('0'+bday).slice(-2);
        let calendarDate = new Date(`${byear}-${zeromonth}-${zeroday}T06:00:00.000`);

        const upInstitute = ( calendarDate: Date) => {
            return new Promise((resolve, reject) => {
                api.post('/feeds/admnAdd', {'instituteID': authInfo.user.instituteID, title, description, calendarDate}).then(res => {
                        return resolve(res.data.feed.title);
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        upInstitute(calendarDate)
        .then(data => {
            setPop(false);
            setPass(title+t("has_been_added"));
            setIspass(true);
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
    
                const minDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: 1
                }
    
                const maxDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
                }

                const events = await api.get(`/feeds/getByMonth/${authInfo.user.instituteID}/${onDate.toISOString()}`);


                let result: Array < Attend > = [];

                for (let i = 0; i < events.data.length ; i++) {
                    let dated: Date = new Date(events.data[i].calendarDate);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                    result.push(calObj);
            
                }
                setAttend(result);
                setMinDay(minDate);
                setMaxDay(maxDate);
                setSelectedDay(minDate);

                const res = await api.post('/feeds/getByDay', {'instituteID': authInfo.user.instituteID, 'day': minDate});
                setEvents(res.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo, refreshed]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("calendar")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("calendar")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_delete")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("event_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    max="2029-12-31"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-padding">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {(minDay && maxDay) && (
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    <Calendar 
                                        value={selectedDay}
                                        onChange={handleDate}
                                        minimumDate={minDay}
                                        maximumDate={maxDay}
                                        shouldHighlightWeekends={true}
                                        customDaysClassName={attend}
                                        calendarClassName="custom-calendar"
                                    />
                                </div>
                            </div>
                         )}
                        </IonCol>
                    </IonRow>
                    {(selectedDay && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("events_on")} {formatDated(selectedDay)}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    onClick={() => setPop(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("add_event")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            <IonPopover size='cover' isOpen={pop} onDidDismiss={e => setPop(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("event_title")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={title}
                                                        placeholder={t("event_title")}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("event_desc")}*</IonLabel>
                                                <IonTextarea rows={4} value={description} onIonInput={
                                                        (e) => setDescription(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                               
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handleAdd}
                                            expand="block"
                                            color="secondary">{t("add_event")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {
                            (events.length > 0) ? (events.map((event) => {
                                return (
                                        <IonCard className="planner-card-2" key={event._id}>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>            
                                                        <IonItem lines="none"
                                                            button={false}
                                                            detail={false}
                                                            >
                                                                <IonIcon icon={sparkles}
                                                                    slot="start"
                                                                    color="primary" />
                                                                <IonLabel className="action-title-one"><h3>{event.title}</h3></IonLabel>
                                                                <IonButtons slot="end">
                                                              
                                                                <IonButton onClick={() => {
                                                                    setSelectedEvent(event._id);
                                                                    setShowAlert(true);
                                                                }}>
                                                                    <IonIcon 
                                                                    icon={trash}
                                                                    color="danger"
                                                                    
                                                                    />
                                                                </IonButton>
                                                            </IonButtons> 
                                                            </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>            
                                                        <IonText>{event.description}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                        })) : (
                                            <IonCard className='red-card'>
                                                <IonCardContent className='ion-no-margin ion-no-padding ion-padding-vertical'>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_events")}</h3></IonLabel>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>

                </>
                ))}

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Planner;
