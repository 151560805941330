import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, pricetag } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface Store {
    _id: string,
    name: string,
    isActive: boolean
}

interface Item {
    _id: string,
    name: string,
    quantity: number,
    stock: boolean,
    isActive: boolean
}

const ItemReports: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [stores, setStores] = useState < Store[] > ([]);
    const [store, setStore] = useState < string > ("");
    const [items, setItems] = useState < Item[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [categories, setCategories] = useState < string[] > (['First', 'Second', 'Third']);
    const [incomeSeries, setIncomeSeries] = useState < number[] > ([0, 0, 0]);
    const [expenseSeries, setExpenseSeries] = useState < number[] > ([0, 0, 0]);
    const [income, setIncome] = useState < number > (0);
    const [expense, setExpense] = useState < number > (0);

    const series =  [{
        name: 'Income',
        data: incomeSeries
        }, {
        name: 'Expenses',
        data: expenseSeries
    }];

    const options =  {
        chart: {
            toolbar: {
              show: false
            }
        },
        colors : ['#2dd36f', '#eb445a'],
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: 'Rs. (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return "Rs. " + val + " thousands"
                }
            },
            theme: 'dark'
        }
    };
         
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const handleStore = (storeID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/items/getAllStore/${storeID}`);
                setItems(result.data);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setStore(storeID);
    }


    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/stores/getAllInstitute/${authInfo.user.instituteID}`);
                setStores(res.data);
                setItems([]);

                const result = await api.get('/itemTransactions/reports/' + authInfo.user.instituteID);

                setCategories(result.data.categories);
                setIncomeSeries(result.data.incomeSeries);
                setExpenseSeries(result.data.expenseSeries);
                setIncome(result.data.income);
                setExpense(result.data.expense);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/inventory`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("inventory_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("inventory_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60 max-xl">

                    <IonRow>
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                        <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_income")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-in">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{t("rs")} {income}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard className='action-card-d'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_expense")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-out">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{t("rs")} {expense}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                   
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("last_3_months")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <Chart options={options} series={series} type="bar" height={350} />
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                       
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("store_status")}
                                        </IonTitle>
                                </IonCol>
                           </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("item_store")}</IonLabel>
                                                    <IonSelect value={store}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("item_store")}
                                                            onIonChange={
                                                                (e) => handleStore(e.detail.value)
                                                        }>

                                                            {stores.map((store) => 
                                                                (<IonSelectOption key={store._id} value={store._id}>{store.name}</IonSelectOption>)
                                                            )}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                           <IonRow className="ion-margin-vertical">
                                <IonCol>
                                     <IonList className="ion-no-padding">
                                    {(items.length > 0) ? (items.map((item, i) => {
                                            return (
                                                <IonItem detail={true}
                                                    lines='full'
                                                    button={true}
                                                    key={item._id}
                                                    className={((i%2) === 0) ? '' : 'alt-item'}
                                                    onClick={() => history.push(`/app/item/ledger/${item._id}`)}
                                                    >
                                                    <IonIcon slot="start"
                                                        icon={pricetag}
                                                        color="secondary" />
                                                    <IonLabel className='list-label'>
                                                        <h3>{item.name} <br/>
                                                        {t("quantity_left")} {item.stock ? item.quantity : t("na")}
                                                        </h3>
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        })) : ( 
                                            <IonItem lines="none" className="red-card">
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_items")}</h3></IonLabel>

                                            </IonItem>
                                        )
                                        } 
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                   
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ItemReports;
