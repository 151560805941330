import {
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonMenuButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonCardHeader,
  IonCardSubtitle,
  IonButton,
  IonLoading,
  IonAlert,
  isPlatform,
  IonCheckbox,
  IonText
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

const DeleteAccount: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo, logOut} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [password, setPassword] = useState < string > ("");
    const [isChecked1, setIsChecked1] = useState < boolean > (false);
    const [isChecked2, setIsChecked2] = useState < boolean > (false);
    const [isChecked3, setIsChecked3] = useState < boolean > (false);
    const [isChecked4, setIsChecked4] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const handleSubmit = () => {

        const deleteAcc = () => {
            return new Promise((resolve, reject) => {
                api.delete(`users/${authInfo.user._id}`, { data: {password} }).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }

        if (!isChecked1 || !isChecked2 || !isChecked3 || !isChecked4) {
            setMessage(t("checking_mandatory"));
            setIserror(true);
            return;
        }

        if (!password || password === "") {
            setMessage(t("login_pass"));
            setIserror(true);
            return;
        }

        setShowLoading(true);
        deleteAcc()
        .then(() => {
        
            setPass(t("account_deleted"));
            setIspass(true);
            
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));

    }

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar className="white-shade">
                <IonButtons slot="start">
                    <IonMenuButton color="secondary"/>
                    <IonBackButton color="secondary" defaultHref="/app"/>
                </IonButtons>
                <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("delete_account")}</IonTitle>
                {!isPlatform('mobile') && (
                <IonButtons slot="end">
                    <ThemeToggle />
                    <LanguageToggle />
                </IonButtons>
                )}
            </IonToolbar>
        </IonHeader>


        <IonContent fullscreen={true}
            className="grey-shade">

            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large" color="secondary">{t("delete_account")}</IonTitle>
                </IonToolbar>
            </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={ispass}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/home");
                    }}
                    header={t("success")}
                    message={pass}
                    buttons={[`${t("goto_home")}`]}
                />


                <IonGrid className="mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol>
                                    <IonCard className='note-card-2'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonRow>
                                                <IonCol size="1">
                                                    <IonIcon icon={informationCircleOutline}
                                                        color="danger" 
                                                        className="note-icon" />
                                                </IonCol>
                                                <IonCol size="11" className='note-text pl-08'>
                                                    <p>
                                                        {t("delete_info")}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-no-margin">
                                        <IonCardHeader>
                                                <IonCardSubtitle className='info-subtitle'>{t("confirm_info")}</IonCardSubtitle>
                                            </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonList className="ion-no-padding">
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonCheckbox labelPlacement="end" checked={isChecked1} 
                                                                onClick={(e) => setIsChecked1(!isChecked1) } 
                                                            >
                                                                    <IonText className='ion-text-wrap'>{t("confirm_delete_1")}</IonText>
                                                            </IonCheckbox>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonCheckbox labelPlacement="end" checked={isChecked2} 
                                                                onClick={(e) => setIsChecked2(!isChecked2) } 
                                                            >
                                                                    <IonText className='ion-text-wrap'>{t("confirm_delete_2")}</IonText>
                                                            </IonCheckbox>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonCheckbox labelPlacement="end" checked={isChecked3} 
                                                                onClick={(e) => setIsChecked3(!isChecked3) } 
                                                            >
                                                                    <IonText className='ion-text-wrap'>{t("confirm_delete_3")}</IonText>
                                                            </IonCheckbox>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonCheckbox labelPlacement="end" checked={isChecked4} 
                                                                onClick={(e) => setIsChecked4(!isChecked4) } 
                                                            >
                                                                    <IonText className='ion-text-wrap'>{t("confirm_delete_4")}</IonText>
                                                            </IonCheckbox>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("type_your_password")}</IonLabel>
                                                            <IonInput type="password"
                                                                value={password}
                                                                placeholder={t("your_password")}
                                                                onIonInput={
                                                                    (e) => setPassword(e.detail.value!)
                                                            }></IonInput>
                                                        </IonItem>
                                                        
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>
                                            
                                            <IonRow className='mt-30 mb-30'>
                                                <IonCol className='pl-24 pr-24'>
                                                    <IonButton onClick={handleSubmit}
                                                        className="fifth-button"
                                                        fill="clear"
                                                        expand='block'
                                                        >{t("delete_my_account")}</IonButton>

                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>

        </IonPage>
    );
};

export default DeleteAccount;
