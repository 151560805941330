import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    classID: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const ClassDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [sessionID, setSessionID] = useState < string > ("");
    const [description, setDescription] = useState < string > ("");
  
    const handleSection = (section: string) => {

        const toggleClass = (isActive: string) => {
            return new Promise((resolve, reject) => {
                api.put(`/classes/${classID}`, { 'isActive': section }).then(res => {
                    return resolve(res.data.name);
                }).catch(err => reject(err));
            });
        }

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);
            toggleClass(section)
            .then(data => {
                let msg: string = "";
                if (section === 'enable')
                {
                    setActive("enable");
                    msg = data+t("now_active");
                }
                    
                if (section === 'disable')
                {
                    setActive("disable");
                    msg = data+t("now_inactive");
                }
                    
        
                setPass(msg);
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }

    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!sessionID || sessionID === "") {
            setMessage(t("session_mandatory"));
            setIserror(true);
            return;
        }
  
        const upClass = (year: string) => {
          return new Promise((resolve, reject) => {
            api.put('/classes/'+classID, { name, year, description, sessionID }).then(res => {
              return resolve(res.data.name);
            }).catch(err => reject(err));
          });
        }
    
        const sessionName = sessions.find(val => val._id === sessionID)!.name;

        setShowLoading(true);
        upClass(sessionName)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
      }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get('/classes/' + classID);
                setName(res.data.name);
                if (res.data.description)
                {
                    setDescription(res.data.description);
                }

                const result = await api.get(`/sessions/getAllInstitute/${res.data.instituteID}`);
                setSessions(result.data);
                setSessionID(res.data.sessionID);

                if (res.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref="/app/classes" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("manage_class") : t("manage_batch")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("manage_class") : t("manage_batch")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {(authInfo.institute.type === "school") ? t("class_details") : t("batch_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                               
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class_name") : t("batch_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={(authInfo.institute.type === "school") ? t("class_name") : t("batch_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}
                                        </IonLabel>
                                            <IonSelect value={sessionID}
                                                    className="input-field"
                                                    placeholder={t("class_session")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => setSessionID(e.detail.value)
                                                }>

                                                    {sessions.length > 0 && sessions.map((session) => 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            )}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="floating" color="secondary">{t("short_desc")}</IonLabel>
                                        <IonTextarea rows={4} value={description} onIonInput={
                                                (e) => setDescription(e.detail.value!)
                                        }></IonTextarea>
                                    
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">

                            <IonButton 
                                onClick={handleSubmit}
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{(authInfo.institute.type === "school") ? t("update_class") : t("update_batch")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ClassDetail;
