import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    isPlatform,
} from '@ionic/react';
import { attach, camera, ellipsisVertical, image, images, send, trash, trashOutline, videocam } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import { NativeAudio } from '@capacitor-community/native-audio';
import io, { Socket } from "socket.io-client";
import React, {useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Anchorme } from 'react-anchorme';
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import FileOpener from "../../../components/FileOpener";
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    inquiryID: string
}
interface Message {
    timestamp: Date,
    name: string,
    userInfo: UserInterface,
    message: Chat
}
interface UserInterface {
    userID: string,
    userName: string,
    userRole: string,
    userPhoto: string
}
interface Chat {
    text: string,
    files?: string[]
}

const InquiryDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {inquiryID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [userName, setUserName] = useState < string > ("");
    const [userRole, setUserRole] = useState < string > ("");
    const [userPhoto, setUserPhoto] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [status, setStatus] = useState < string > ("");
    const [reply, setReply] = useState < string > ("");
    const [createdAt, setCreatedAt] = useState < Date > ();
    const [fileName, setFileName] = useState < string[] > ([]);
    const [messages, setMessages] = useState < Message[] > ([]);
    const [upload, setUpload] = useState < File[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const scrollContent = useRef < HTMLIonContentElement | null > (null);
    const socketRef = useRef < Socket | null > (null);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [msg, setMsg] = useState < number > (0);

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return  valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        if (ext === 'mov')
            mime = 'video/quicktime';
        if (ext === 'docx')
            mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (ext === 'xlsx')
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (ext === 'pptx')
            mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (ext === 'doc')
            mime = 'application/msword';
        if (ext === 'xls')
            mime = 'application/vnd.ms-excel';
        if (ext === 'ppt')
            mime = 'application/vnd.ms-powerpoint';
        return mime;
    };

    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    };

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4' || extension === 'mov';
    };

    const openImage = (images: string[]) => {
        const theURLs: Image[] = [];
        for (let i=0; i < images.length; i++)
        {
            theURLs.push({url: `${process.env.REACT_APP_STATIC_S3}/conversations/${images[i]}`});
        }
        if (images.length > 1)
        {
            PhotoViewer.show({images: theURLs, mode: isPlatform('mobile') ? 'slider' : 'gallery'});
        }
        if (images.length === 1)
        {
            PhotoViewer.show({images: theURLs, mode: 'one'});
        }
    };

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: getMimeType(image.format),
                                    });
                                    setUpload([rawFile]);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: getMimeType(image.format),
                            });
                            setUpload([rawFile]);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads: File[] =  [];

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
            }
            setUpload(theUploads);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads: File[] =  [];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
           }
           setUpload(theUploads);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };
      
   const pickFiles = async () => {
    isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads: File[] = [];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
            }
            setUpload(theUploads);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };


    const downloadCircular = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/conversations/' + fileName;

        if (isPlatform('capacitor') && !showDownloading)
        {
            try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            setShowDownloading(true);
                            try {
                                const savedFile = await Filesystem.downloadFile({
                                    path: fileName,
                                    url,
                                    directory: Directory.External,
                                });
                                
                                if(savedFile.path) {
                                    await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                } else {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                                
                            } catch (error: any) {
                                setMessage(t("download_error"));  
                                setIserror(true);
                            } finally {
                                setShowDownloading(false);
                            }
                        }
                    });
                }
                else {
                    setShowDownloading(true);
                    try {
                        const savedFile = await Filesystem.downloadFile({
                            path: fileName,
                            url,
                            directory: Directory.External,
                        });
                        
                        if(savedFile.path) {
                            await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                        } else {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                        
                    } catch (error: any) {
                        setMessage(t("download_error"));  
                        setIserror(true);
                    } finally {
                        setShowDownloading(false);
                    }
                }
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            }
        }
        if (isPlatform('capacitor') && showDownloading)
        {
            setMessage(t("download_in_progress"));
            setIserror(true);
        }
        if (!isPlatform('capacitor'))
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }
    };

    const handleSubmit = () => {

        if (!reply || reply === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/inquiries/attach/update', formData).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        }
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/inquiries/'+inquiryID, { reply, 'adminID': authInfo.user._id }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }

        if (upload.length > 0)
        {
            let formData = new FormData();

            formData.append('adminID', authInfo.user._id);
            formData.append('reply', reply);
            formData.append('inquiryID', inquiryID);
            for (let i=0; i < upload.length; i++)
            {
                formData.append('attachment[]', upload[i], `${authInfo.user.instituteID}`);
            }

            setShowLoading(true);
            upAttach(formData)
            .then((data: any) => {
                setUpload([]);
                setReply("");
                if (data.messageObj)
                {
                    socketRef.current?.emit('sendMessage', inquiryID, data.messageObj);
                }
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            upApli()
            .then((data: any) => {
                setReply("");
                if (data.messageObj)
                {
                    socketRef.current?.emit('sendMessage', inquiryID, data.messageObj);
                }
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
    }

    const handleDelete = () => {
        setShowLoading(true);
        api.get(`/inquiries/deleteMessage/${inquiryID}/${msg}`)
        .then(res => {
            let newMsgs = [...messages];
            newMsgs[msg] = res.data.message;
            setMessages(newMsgs);
        })
        .catch((error: any) => {
            //
        })
        .finally(() => setShowLoading(false));
    }

    const deleteFirst = () => {
        setShowLoading(true);
        api.put('/inquiries/'+inquiryID, { 'delete': 'delete' })
        .then(res => {
            setText(res.data.inquiry.text);
            setFileName([]);
        })
        .catch((error: any) => {
           //
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        NativeAudio.preload({
            assetId: "attend",
            assetPath: "attend.mp3",
            audioChannelNum: 1,
            isUrl: false
        }).catch((error) => {
            console.log(error);
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/inquiries/' + inquiryID);

                if (result.data.student)
                {
                    setUserName(result.data.student.name);
                    setUserRole("Parent");
                    if (result.data.student.photo)
                    {
                        setUserPhoto(result.data.student.photo);
                    }
                    
                }

                if (result.data.admin)
                {
                    setUserName(result.data.admin.name);
                    setUserRole("Admin");
                    if (result.data.admin.photo)
                    {
                        setUserPhoto(result.data.admin.photo);
                    }
                }

                setTitle(result.data.title);
                setText(result.data.text);
                setType(result.data.type);
                setStatus(result.data.status);
                setCreatedAt(result.data.createdAt);
                
                if (result.data.files)
                {
                    setFileName(result.data.files);
                }
                if (result.data.messages)
                {
                    setMessages(result.data.messages);
                    setTimeout(() => scrollContent.current?.scrollToBottom(500), 500);
                }
                if (result.data.status === "pending")
                {
                    socketRef.current = io("https://erp.prathmic.brickedsolutions.com", {path: '/socket.io'});

                    socketRef.current.emit("joinChat", inquiryID);

                    socketRef.current.on('receiveMessage', (newMessage) => {
                        setMessages((msgs) => [...msgs, newMessage]);
                        setTimeout(() => scrollContent.current?.scrollToBottom(500), 500);
                        
                        if (newMessage.userInfo.userID !== authInfo.user._id)
                            {
                                NativeAudio.play({
                                    assetId: 'attend'
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }
                    });
                }

            } catch (error: any) {
                setMessage(t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

        return () => {

            NativeAudio.unload({
                assetId: 'attend'
            }).catch((error) => {
                console.log(error);
            });

            if (socketRef.current)
            {
                socketRef.current.off('receiveMessage');
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };

    }, [authInfo, inquiryID, refreshed]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{title}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{title}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("delete_message")}
                    buttons={[
                        {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: t("yes"),
                            handler: () => {
                                handleDelete();
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert1}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert1(false)}
                    header={t("confirm")}
                    message={t("delete_message")}
                    buttons={[
                        {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: t("yes"),
                            handler: () => {
                                deleteFirst();
                            }
                        }
                    ]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">

                    {userRole === "Admin" && (
                    <IonRow className="ion-margin-top chat-admin-row">
                        
                        <IonCol size="2" className="chat-left-img">
                        {(userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${userPhoto}`} alt={userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={userName} /></IonAvatar>)
                            }
                        </IonCol>
                        <IonCol offset="2" size="7">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-admin-card ion-no-margin">
                                        <IonCardHeader>
                                            <IonCardSubtitle className='text-capitalize'>
                                                {type}
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className='anchorme-text'>
                                            <Anchorme>{text}</Anchorme>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonText className="chat-timestamp">{createdAt && formatDate(createdAt)}</IonText>
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2" className='chat-right-col'>
                            <IonRow>
                                <IonCol>
                            {(fileName.length > 1) && (
                                <IonIcon 
                                    icon={images}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => openImage(fileName)}
                                />
                            )}
                            {(fileName.length === 1) && isFileImage(fileName[0]) && (
                                <IonIcon 
                                    icon={image}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => openImage([fileName[0]])}
                                />
                            )}
                            {(fileName.length === 1) && isFileVideo(fileName[0]) && (
                                <IonIcon 
                                    icon={videocam}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(fileName[0])}
                                />
                            )}
                             {(fileName.length === 1) && !isFileImage(fileName[0]) && !isFileVideo(fileName[0]) && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(fileName[0])}
                                />
                            )}
                                </IonCol>
                            </IonRow>
                            <IonRow className='chat-attach-caption'>
                                <IonCol>
                                    {(fileName.length > 1) && (<IonText onClick={() => openImage(fileName)}>{t("view_pictures")}</IonText>)}
                                    {(fileName.length === 1) && isFileImage(fileName[0]) && (<IonText onClick={() => openImage([fileName[0]])}>{t("view_picture")}</IonText>)}
                                    {(fileName.length === 1) && isFileVideo(fileName[0]) && (<IonText onClick={() => downloadCircular(fileName[0])}>{t("download_video")}</IonText>)}
                                    {(fileName.length === 1) && !isFileImage(fileName[0]) && !isFileVideo(fileName[0]) && (<IonText onClick={() => downloadCircular(fileName[0])}>{t("download_file")}</IonText>)}
                                 </IonCol>
                            </IonRow>
                        </IonCol>
                        
                    </IonRow>
                    )}

                    {userRole === "Parent" && (
                    <IonRow className="ion-margin-top chat-parent-row">

                        <IonCol offset="1" size="2" className='chat-left-col'>
                            <IonRow>
                                <IonCol>
                            {(fileName.length > 1) && (
                                <IonIcon 
                                    icon={images}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => openImage(fileName)}
                                />
                            )}
                             {(fileName.length === 1) && isFileImage(fileName[0]) && (
                                <IonIcon 
                                    icon={image}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => openImage([fileName[0]])}
                                />
                            )}
                             {(fileName.length === 1) && isFileVideo(fileName[0]) && (
                                <IonIcon 
                                    icon={videocam}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(fileName[0])}
                                />
                            )}
                             {(fileName.length === 1) && !isFileImage(fileName[0]) && !isFileVideo(fileName[0]) && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(fileName[0])}
                                />
                            )}
                                </IonCol>
                            </IonRow>
                            <IonRow className='chat-attach-caption'>
                                <IonCol>
                                    {(fileName.length > 1) && (<IonText onClick={() => openImage(fileName)}>{t("view_pictures")}</IonText>)}
                                    {(fileName.length === 1) && isFileImage(fileName[0]) && (<IonText onClick={() => openImage([fileName[0]])}>{t("view_picture")}</IonText>)}
                                    {(fileName.length === 1) && isFileVideo(fileName[0]) && (<IonText onClick={() => downloadCircular(fileName[0])}>{t("download_video")}</IonText>)}
                                    {(fileName.length === 1) && !isFileImage(fileName[0]) && !isFileVideo(fileName[0]) && (<IonText onClick={() => downloadCircular(fileName[0])}>{t("download_file")}</IonText>)}
                                 </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="7" className="ion-text-right">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-parent-card ion-no-margin">
                                        <IonCardHeader>
                                            <IonCardSubtitle className='text-capitalize'>
                                                {type}
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className='anchorme-text'>
                                            <Anchorme>{text}</Anchorme>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonIcon color="dark" icon={trashOutline} onClick={() => setShowAlert1(true)} />
                                    <IonText className="chat-timestamp ml-05">{createdAt && formatDate(createdAt)}</IonText>
                                    
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2" className="chat-right-img">
                        {(userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${userPhoto}`} alt={userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={userName} /></IonAvatar>)
                            }
                        </IonCol>
                        
                    </IonRow>
                    )}

                {(messages.length > 0) && (messages.map((message, i) => {
                    if (message.userInfo.userRole === "Parent")
                    {
                    return (
                    <IonRow key={i} className="ion-margin-top chat-parent-row">
                        <IonCol offset="1" size="2" className='chat-left-col'>
                            <IonRow>
                                <IonCol>
                            {message.message.files && (message.message.files.length > 1) && (
                                <IonIcon 
                                    icon={images}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => openImage(message.message.files!)}
                                />
                            )}
                            {message.message.files && (message.message.files.length === 1) && isFileImage(message.message.files[0]) && (
                                <IonIcon 
                                    icon={image}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => openImage([message.message.files![0]])}
                                />
                            )}
                             {message.message.files && (message.message.files.length === 1) && isFileVideo(message.message.files[0]) && (
                                <IonIcon 
                                    icon={videocam}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(message.message.files![0])}
                                />
                            )}
                             {message.message.files && (message.message.files.length === 1) && !isFileImage(message.message.files[0]) && !isFileVideo(message.message.files[0]) && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    className="chat-left-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(message.message.files![0])}
                                />
                            )}
                                </IonCol>
                            </IonRow>
                            <IonRow className='chat-attach-caption'>
                                <IonCol>
                                    {message.message.files && (message.message.files.length > 1) && (<IonText onClick={() => openImage(message.message.files!)}>{t("view_pictures")}</IonText>)}
                                    {message.message.files && (message.message.files.length === 1) && isFileImage(message.message.files[0]) && (<IonText onClick={() => openImage([message.message.files![0]])}>{t("view_picture")}</IonText>)}
                                    {message.message.files && (message.message.files.length === 1) && isFileVideo(message.message.files[0]) && (<IonText onClick={() => downloadCircular(message.message.files![0])}>{t("download_video")}</IonText>)}
                                    {message.message.files && (message.message.files.length === 1) && !isFileImage(message.message.files[0]) && !isFileVideo(message.message.files[0]) && (<IonText onClick={() => downloadCircular(message.message.files![0])}>{t("download_file")}</IonText>)}
                                  </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="7" className="ion-text-right">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {message.userInfo.userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-parent-card ion-no-margin">
                                       
                                        <IonCardContent className='anchorme-text'>
                                            <Anchorme>{message.message.text}</Anchorme>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {(message.userInfo.userID === authInfo.user._id) && (
                                        <IonIcon color="dark" icon={trashOutline} onClick={() => {
                                            setMsg(i);
                                            setShowAlert(true);
                                            }
                                        }/>
                                    )}
                                    <IonText className="chat-timestamp ml-05"> {message.timestamp && formatDate(message.timestamp)}</IonText>
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2" className="chat-right-img">
                        {(message.userInfo.userPhoto && message.userInfo.userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${message.userInfo.userPhoto}`} alt={message.userInfo.userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={message.userInfo.userName} /></IonAvatar>)
                            }
                        </IonCol>
                        
                    </IonRow>);
                    }
                    else
                    {
                    return (
                    <IonRow key={i} className="ion-margin-top chat-admin-row">
                        
                        <IonCol size="2" className="chat-left-img">
                        {(message.userInfo.userPhoto && message.userInfo.userPhoto !== "") ?
                                (<IonAvatar className="chat-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${message.userInfo.userPhoto}`} alt={message.userInfo.userName} /></IonAvatar>)
                                : (<IonAvatar className="chat-avatar"><img src={`assets/images/avatar1.png`} alt={message.userInfo.userName} /></IonAvatar>)
                            }
                        </IonCol>
                        <IonCol offset="2" size="7">
                            <IonRow>
                                <IonCol className="chat-user-name">
                                    {message.userInfo.userName}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="chat-admin-card ion-no-margin">
                                     
                                        <IonCardContent className='anchorme-text'>
                                            <Anchorme>{message.message.text}</Anchorme>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonText className="chat-timestamp">{message.timestamp && formatDate(message.timestamp)}</IonText>
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                        <IonCol size="2" className='chat-right-col'>
                            <IonRow>
                                <IonCol>
                            {message.message.files && (message.message.files.length > 1) && (
                                <IonIcon 
                                    icon={images}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => openImage(message.message.files!)}
                                />
                            )}
                            {message.message.files && (message.message.files.length === 1) && isFileImage(message.message.files[0]) && (
                                <IonIcon 
                                    icon={image}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => openImage([message.message.files![0]])}
                                />
                            )}
                            {message.message.files && (message.message.files.length === 1) && isFileVideo(message.message.files[0]) && (
                                <IonIcon 
                                    icon={videocam}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(message.message.files![0])}
                                />
                            )}
                             {message.message.files && (message.message.files.length === 1) && !isFileImage(message.message.files[0]) && !isFileVideo(message.message.files[0]) && (
                                <IonIcon 
                                    icon={attach}
                                    size="large"
                                    className="chat-right-attach"
                                    color="medium"
                                    onClick={() => downloadCircular(message.message.files![0])}
                                />
                            )}
                                </IonCol>
                            </IonRow>
                            <IonRow className='chat-attach-caption'>
                                <IonCol>
                                    {message.message.files && (message.message.files.length > 1) && (<IonText onClick={() => openImage(message.message.files!)}>{t("view_pictures")}</IonText>)}
                                    {message.message.files && (message.message.files.length === 1) && isFileImage(message.message.files[0]) && (<IonText onClick={() => openImage([message.message.files![0]])}>{t("view_picture")}</IonText>)}
                                    {message.message.files && (message.message.files.length === 1) && isFileVideo(message.message.files[0]) && (<IonText onClick={() => downloadCircular(message.message.files![0])}>{t("download_video")}</IonText>)}
                                    {message.message.files && (message.message.files.length === 1) && !isFileImage(message.message.files[0]) && !isFileVideo(message.message.files[0]) && (<IonText onClick={() => downloadCircular(message.message.files![0])}>{t("download_file")}</IonText>)}
                                  </IonCol>
                            </IonRow>
                        </IonCol>
                        
                    </IonRow>
                        );
                    }
                }))}

                    
                </IonGrid>
            </IonContent>
            {(status === "pending") && (
            <IonFooter className='ion-no-padding footer-shade-grey'>
                    <IonRow>
                        <IonCol>
                            <IonItem detail={false}
                                lines='none'
                                button={false}
                                >
                                <IonIcon
                                    slot="start"
                                    icon={ellipsisVertical}
                                    id="open-upload-input"
                                />
                                {(upload.length > 0) && (
                                    <IonBadge slot="start">{upload.length}</IonBadge>
                                )}
                                <IonTextarea rows={1} value={reply} placeholder={t("type_a_message")}
                                    onKeyDown={(e) => {
                                            if ((e.key === 'Enter') && !e.shiftKey) 
                                            {
                                                e.preventDefault();
                                                handleSubmit();
                                            }
                                        }
                                    }
                                    onIonInput={(e) => setReply(e.detail.value!)}
                                  
                                />
                                <IonIcon
                                    slot="end"
                                    icon={send}
                                    onClick={handleSubmit}
                                />
                            </IonItem>
                                
                        </IonCol>
                    </IonRow>
                    <IonPopover dismissOnSelect={true} trigger="open-upload-input" className='offset-y-fix'>
                            <IonRow>
                                <IonCol>
                                    <IonList>
                                        {isPlatform("capacitor") && (
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={takePhoto}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={camera}
                                            />
                                            <IonLabel>
                                                {t("take_photo")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        )}
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={pickImages}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={images}
                                            />
                                            <IonLabel>
                                                {t("upload_photos")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={pickVideo}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={videocam}
                                            />
                                            <IonLabel>
                                                {t("upload_video")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={pickFiles}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={attach}
                                            />
                                            <IonLabel>
                                                {t("attach_doc")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        {(upload.length > 0) && (
                                            <IonItem detail={true}
                                                color="danger"
                                                lines='none'
                                                button={true}
                                                onClick={() => setUpload([])}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={trash}
                                            />
                                            <IonLabel>
                                                {t("remove_files")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        )}
                                    </IonList>
                                </IonCol>
                            </IonRow>
                           
                    </IonPopover>
            </IonFooter>
            )}
        </IonPage>
    );
};

export default InquiryDetail;
