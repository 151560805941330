import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { analytics, bus } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

const TripsReports: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [routes, setRoutes] = useState < number > (0);
    const [trips, setTrips] = useState < number > (0);

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes/transport/${authInfo.user.instituteID}`);
                setTrips(result.data.trips);
                setRoutes(result.data.routes);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo]); 

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/transport`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("trips_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("trips_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mt-30 mb-60">

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-7">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                        {routes}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top reports-label">
                                                    <IonCol>
                                                            {t("active")} {t("routes")}
                                                       </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={analytics}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonCard className="stat-card-6">
                                <IonCardContent className="ion-padding">
                                        <IonRow> 
                                            <IonCol size="9" className='ion-padding'>
                                                <IonRow>
                                                    <IonCol className="reports-info">         
                                                        {trips}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-top reports-label'>
                                                    <IonCol>
                                                            {t("trips")} {t("completed")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3">
                                                <div className="vertical-container">
                                                     <div className="vertical-center"> 
                                                        
                                                        <IonIcon 
                                                            icon={bus}
                                                            className='reports-icon'
                                                        />
                                                      
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                   
                    </IonRow>
                  
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default TripsReports;
