import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../components/AuthContext';
import useApiService from '../../../components/ApiService';

interface Student {
    _id: string,
    name: string,
    photo: string,
    score: number
}

const students: Student[] = [{
    _id: '1',
    name: 'Akash',
    photo: 'image_1644680504268.jpeg',
    score: 96
},
{
    _id: '2',
    name: 'Komal Jain',
    photo: 'image_1675213892956.png',
    score: 93
},
{
    _id: '3',
    name: 'Rahul Verma',
    photo: 'image_1675213926025.png',
    score: 92
},
{
    _id: '4',
    name: 'Karan',
    photo: '',
    score: 88
},
{
    _id: '5',
    name: 'Sidharth',
    photo: 'image_1644680504268.jpeg',
    score: 84
},
{
    _id: '6',
    name: 'Surabhi',
    photo: 'image_1675213988912.png',
    score: 84
},
{
    _id: '7',
    name: 'Payal Gupta',
    photo: '',
    score: 80
},
{
    _id: '8',
    name: 'Kapil Singh',
    photo: 'image_1675213892956.png',
    score: 78
},
{
    _id: '9',
    name: 'Vishal',
    photo: 'image_1675213926025.png',
    score: 73
},
{
    _id: '10',
    name: 'Shrey Kapoor',
    photo: 'image_1644680504268.jpeg',
    score: 65
},
];

const Leaderboards: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;

    const [student, setStudent] = useState < Student > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [mode, setMode] = useState < string > ("month");

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/students/getByAccount/${authInfo.user._id}`);
                setStudent(stud.data);

            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle color="secondary">Leaderboard</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="white-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Leaderboard</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding max-md">

                    <IonRow className="leaderboard-row ion-padding-horizontal">
                    
      
                        <IonCol className='leaderboard-second'>
                            <div className="leader-container">
                            {(students[1].photo !== "") ?
                                (<IonAvatar className="leader-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${students[1].photo}`} alt={students[1].name} /></IonAvatar>)
                                : (<IonAvatar className="leader-avatar"><img src={`assets/images/avatar1.png`} alt={students[1].name} /></IonAvatar>)
                            }
                            <br/>
                            <div className="numberCircle">2</div>
                            <IonText className='leader-name'>{students[1].name.split(' ')[0]}</IonText>
                            </div>
                        </IonCol>
                        <IonCol className='leaderboard-first'>
                            <div className="leader-container">
                                <img src={`assets/images/crown.png`} alt="Crown" width="48" height="48" />
                                <br/>
                                {(students[0].photo !== "") ?
                                    (<IonAvatar className="leader-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${students[0].photo}`} alt={students[0].name} /></IonAvatar>)
                                    : (<IonAvatar className="leader-avatar"><img src={`assets/images/avatar1.png`} alt={students[0].name} /></IonAvatar>)
                                }
                                <br/>
                                <div className="numberOneCircle">1</div>
                                <IonText className='first-name'>{students[0].name.split(' ')[0]}</IonText>
                            </div>
                        </IonCol>
                        <IonCol className='leaderboard-third'>
                            <div className="leader-container">
                                {(students[2].photo !== "") ?
                                    ( <IonAvatar className="leader-avatar"><img src={`${process.env.REACT_APP_STATIC_S3}/images/${students[2].photo}`} alt={students[2].name}  /></IonAvatar>)
                                    : (<IonAvatar className="leader-avatar"><img src={`assets/images/avatar1.png`} alt={students[2].name} /></IonAvatar>)
                                }
                                <br/>
                                <div className="numberCircle">3</div>
                                <IonText className='leader-name'>{students[2].name.split(' ')[0]}</IonText>
                            </div>
                        </IonCol>

                                   
                    </IonRow>


                    <IonRow className="border-bottom-sec ion-margin-top">
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={mode} onIonChange={e => setMode( e.detail.value!.toString() )} className="leaderboard-segment">

                           
                                <IonSegmentButton value="week" className='leaderboard-button'>
                                    <IonLabel className='leaderboard-label'>Week</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="month" className='leaderboard-button'>
                                    <IonLabel className='leaderboard-label'>Month</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="all" className='leaderboard-button'>
                                    <IonLabel className='leaderboard-label'>All Time</IonLabel>
                                </IonSegmentButton>
                           
                               
                               
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          <IonList className='ion-no-padding'>
                             {(students.length > 0) ? (students.map((student, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='inset'
                                            button={false}
                                            key={student._id}
                                            className="leaderboard-item"
                                            >
                                            <IonText className="leaderboard-rank">{i+1}</IonText>
                                            <IonAvatar className="leaderboard-avatar">
                                            {student.photo && (student.photo !== "") ?
                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={student.name} />)
                                                : (<img src={`assets/images/avatar.png`} alt={student.name} />)
                                            }
                                            </IonAvatar>
                                            <IonLabel className='leaderboard-name'>
                                                <h3>{student.name}</h3>
                                                
                                            </IonLabel>
                                            <IonText className='leaderboard-score'>
                                                {student.score} %
                                            </IonText>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color='danger'/>
                                        <IonLabel className='list-label'><h3>No Students</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>

          

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Leaderboards;
