import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const PageUnloadHandler = () => {
    const history = useHistory();

    useEffect(() => {
        const handleBeforeUnload = () => {
            const navigationEntries = performance.getEntriesByType("navigation");
            if (navigationEntries.length > 0 && navigationEntries[0].type === "reload") {
                sessionStorage.removeItem(history.location.pathname);
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [history.location.pathname]);
};

export default PageUnloadHandler;