import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    incidentID: string
}

const IncidentDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {incidentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [type, setType] = useState <string> ("");
    const [description, setDescription] = useState <string> ("");
    const [treatment, setTreatment] = useState <string> ("");
    const [dated, setDated] = useState <Date> ();

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/incidents/${incidentID}`);

                setType(res.data.type);
                setDescription(res.data.description);
                setTreatment(res.data.treatment);
                setDated(res.data.createdAt);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, incidentID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("incident_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("incident_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                        
                    {dated && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem color="danger">
                                        <IonLabel position="stacked" >
                                            {t("recorded_on")}
                                        </IonLabel>
                                        <IonInput value={formatDated(dated)} readonly={true} />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("incident_type")}
                                        </IonLabel>
                                        <IonInput  value={type === "injury" ? t("injury") : t("illness")} readonly={true} />
                                            
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="floating" color="secondary">{t("incident_desc")}</IonLabel>
                                        <IonTextarea rows={8} value={description} readonly={true}></IonTextarea>
                                    
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="floating" color="secondary">{t("incident_treat")}</IonLabel>
                                        <IonTextarea rows={8} value={treatment} readonly={true}></IonTextarea>
                                    
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
           
        </IonPage>
    );
};

export default IncidentDetail;