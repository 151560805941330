import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, time, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface Attendance {
    _id: string,
    studentID: string,
    studentName: string,
    status: string,
    createdAt: Date
}

const InstituteLog: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [attendance, setAttendance] = useState < Attendance[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [attendanceID, setAttendanceID] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleDelete = () => {
  
        setShowLoading(true);
        api.delete(`/attendances/${attendanceID}`)
        .then(res => {

            let attends = [...attendance];
            let index = attends.findIndex((val) => val._id === attendanceID);
            if (index > -1) {
                attends.splice(index, 1); // 2nd parameter means remove one item only
            }
            setAttendance(attends);

            setPass(t("entry_deleted"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
    }

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/attendances/getByInstitute/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data.attends)
            {
                setAttendance([...attendance, ...result.data.attends]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            //
        }
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/attendances/getByInstitute/${authInfo.user.instituteID}`);

                setAttendance(result.data.attends);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("attend_logs")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("attend_logs")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(true)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("delete_entry")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />
                        
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding max-md">
                    {(attendance.length > 0) && (
                    <IonRow>
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(attendance.map((attend, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={attend._id}
                                            className={((i%2) === 0) ? 'alt-item' : ''}
                                           >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    ((attend.status === 'in') || (attend.status === 'entry') || (attend.status === 'reached')) ? 'success' : ((attend.status === 'picked') || (attend.status === 'left')) ? 'warning' : (attend.status === 'custody') ? 'tertiary' : 'danger'
                                                }/>
                                                <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                    <h3><Link to={`/app/student/${attend.studentID}`} className='link-dark'>{attend.studentName}</Link>
                                                    {(attend.status === 'out') && (
                                                        <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'in') && (
                                                        <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'entry') && (
                                                        <span> {(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} on {formatDate(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'custody') && (
                                                        <span> {t("custody_with_parent")} on {formatDateTime(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'picked') && (
                                                        <span> {t("picked_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'reached') && (
                                                        <span> {t("reached_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'left') && (
                                                        <span> {t("left_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                    )}
                                                    {(attend.status === 'dropped') && (
                                                        <span> {t("dropped_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                    )}
                                                    </h3>
                                                </IonLabel>
                                                
                                                <IonButton slot="end"
                                                    onClick={() => {
                                                        setAttendanceID(attend._id);
                                                        setShowAlert(true);
                                                    }}
                                                    >
                                                    <IonIcon
                                                     icon={trash}  />
                                                </IonButton>
                                        </IonItem>
                                    )
                                }))
                                } 
                            </IonList>
                        </IonCol>
                    </IonRow>
                    )}
                     {(attendance.length === 0) && ( 
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_records")}</h3></IonLabel>
    
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )} 

                        {(attendance.length > 0) &&
                        <IonRow>
                            <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                            }
                        
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${attendance.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-xl'>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/entryexit`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("mark_entry_exit")}
                                    </IonButton>

                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/entry/log`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_student_wise")}
                                    </IonButton>

                                    {(isPlatform("capacitor")) && (
                                    <>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/scan/entry`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("student_entry")}
                                    </IonButton>

                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/scan/exit`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("student_exit")}
                                    </IonButton>
                                    </>
                                    )}


                                </IonCol>
                            </IonRow>
                        </IonCol>
                        
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default InstituteLog;
