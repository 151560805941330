import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, construct } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface Course {
    _id: string,
    name: string,
    chapters: Chapter[],
    courseType: string,
    isActive: boolean
}
interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}

const Courses: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [courses, setCourses] = useState < Course[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const loadItems = async () => {

        let limit = skip+30;

        let instituteID: string = "";
        if (process.env.REACT_APP_APP_ID === 'com.bricked.stms')
        {
            instituteID = authInfo.user.instituteID;
        }
        else
        {
            instituteID = process.env.REACT_APP_APP_ID!;
        }

        try {
            const result = await api.get(`/courses/listInstitute/${instituteID}?skip=${limit}`);

            if(result.data.courses && result.data.courses.length)
            {
                setCourses([...courses, ...result.data.courses]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            // Api Error handling is already done in the interceptor
        }
        
    }

    useEffect(() => {

        let instituteID: string = "";
        if (process.env.REACT_APP_APP_ID === 'com.bricked.stms')
        {
            instituteID = authInfo.user.instituteID;
        }
        else
        {
            instituteID = process.env.REACT_APP_APP_ID!;
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/courses/listInstitute/${instituteID}`);
                setCourses(result.data.courses);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_schedules")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_schedules")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="7" className='table-heading' >
                                {t("course_name")}
                            </IonCol>

                            <IonCol size="4" className='table-heading'>
                            {t("total_terms")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>

                            {(courses.length > 0) ? (courses.map((course, i) => {
                                return (
                                    <IonRow key={course._id} className={`${(i % 2 === 0) ? 'row-table' : 'row-table-alt'} ${!course.isActive && 'font-color-3'} ion-padding-horizontal`}
                                    onClick={
                                        () => history.push(`/app/course/${course._id}`)
                                    }>
                                        
                                            <IonCol size="7" className='table-field' >
                                                <IonText className='text-capitalize'>{course.name}</IonText>
                                            </IonCol>
                                            
                                            <IonCol size="4" className='table-field'>
                                                {course.chapters.length}
                                            </IonCol>
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={construct}
                                                    color={course.isActive ? "dark" : "danger"}
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/course/${course._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_schedules")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                            {(courses.length > 0) &&
                             <IonRow>
                                <IonCol>
                                    <IonInfiniteScroll
                                        onIonInfinite={async (ev) => {
                                            await loadItems();
                                            ev.target.complete();
                                        }}
                                        >
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                 </IonCol>
                            </IonRow>
                            }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${courses.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>

                           
                            <IonButton 
                                onClick={
                                    () => history.push(`/app/courses/add`)
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("add_course")}</IonButton>
           
                            </IonCol>
                        </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Courses;
