import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { businessOutline, busOutline, call, locateSharp, manOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GoogleMap, Marker, DirectionsService, useJsApiLoader, InfoBox } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    tripID: string,
    studentID: string
}

interface TripStudent {
    studentID: string,
    name: string,
    lat: number,
    lon: number,
    status: string,
    doneAt: Date
}

interface Institute {
    name: string,
    phone: string,
    lat: number,
    lon: number
}

interface LatLon {
    lat: number,
    lon: number
}

const libraries = ["places" as "places"];

const OngoingTrip: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {tripID, studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);
    const [institute, setInstitute] = useState < Institute > ();
    const [selectedDate, setSelectedDate] = useState < Date > ();
    const [studentDoneAt, setStudentDoneAt] = useState < Date > ();
    const [studentStatus, setStudentStatus] = useState < string > ("");
    const [studentOrder, setStudentOrder] = useState < number > (0);
    const [driverName, setDriverName] = useState < string > ("");
    const [driverphone, setDriverPhone] = useState < string > ("");
    const [vehicleName, setVehicleName] = useState < string > ("");
    const [vehiclePlate, setVehiclePlate] = useState < string > ("");
    const [status, setStatus] = useState < boolean > (true);
    const [locate, setLocation] = useState < LatLon > ();
    const [lastUp, setLastUp] = useState < Date > ();
    const [myLocation, setMyLocation] = useState < LatLon > ();
    const [studentName, setStudentName] = useState < string > ("");
    const [response, setResponse] = useState < any > (null);
    const [myInterval, setMyInterval] = useState <number> ();
    const [secondInterval, setSecondInterval] = useState <NodeJS.Timer> ();
    const [seconds, setSeconds] = useState < number > (0);
    const [dropped, setDropped] = useState < boolean > (false);
    const [absent, setAbsent] = useState < boolean > (false);
    const [isDir, setIsDir] = useState < boolean > (false);
    const [changePick, setChangePick] = useState < boolean > (false);

    const mapRef = useRef < any > ();

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!,
        libraries
    });

    if (loadError)
    {
        setMessage(t("maps_error"));
        setIserror(true);
    }
      
    const mapStyles = {        
        height: "62vh",
        width: "100%"
    };

    const handleMapLoad = (ref: any) => {
        mapRef.current = ref;
        if (!isDir && locate)
        {
            mapRef.current.setCenter({lat: locate.lat, lng: locate.lon });
        }
    }

    const changePicked = (resp: any, inter: any) => {
        if(resp)
        {
            let len: number = resp.routes[0].legs.length;
            len = len - 1;

            let studs: number = students.length;
 
            let j: number = len - (studs - studentOrder);
            let sec: number = 0;

            for (let i = j; i < resp.routes[0].legs.length; i++)
            {
                sec = sec + resp.routes[0].legs[i].duration.value;
            }
            
            setSeconds(sec); // ETA to INSTI
            if(inter) {
                clearInterval(inter);
            }
            setSecondInterval(setInterval(tick, 60000));
            setChangePick(false);

        }
    }

    const changeAbsent = (inter: any) => {
       
        if(inter) {
            clearInterval(inter);
        }
        history.push(`/app/home`);

    }

    const changeDropped = (inter: any) => {
    
        if(inter) {
            clearInterval(inter);
        }
        history.push(`/app/home`);

    }

    const changeEnded = (inter: any) => {
    
        if(inter) {
            clearInterval(inter);
        }
        history.push(`/app/home`);

    }

    const tick = () => {
    setSeconds((s) => ((s-60) > 120) ? (s-60) : (s));
    }

    const secondsToHm = (d: number) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
    
        var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";

        return hDisplay + mDisplay; 
    }

    const createWay = () => {
        let way: any[] = [];
        for (let i = 0; i < students.length; i++) {
            if (students[i].status === 'upcoming')
            {
                way.push({location: new window.google.maps.LatLng(students[i].lat, students[i].lon), stopover: true});
            }
        }
        return way;
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: '2-digit' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const fitCenter = () => {
        if(response) {
            if(mapRef.current) {
                mapRef.current.fitBounds(response.routes[0].bounds);
            }
        }
        else {
            if(mapRef.current) {
                mapRef.current.setCenter({lat: locate!.lat, lng: locate!.lon });
            }
        }
    }

    const directionsCallback = (resp: any) => {
    
        if (resp !== null) {
          if (resp.status === 'OK') {
            setResponse(resp);
            if(mapRef.current)
            {
                mapRef.current.fitBounds(resp.routes[0].bounds);
            }

            if ((type === 'drop') && (studentStatus === 'upcoming'))
            {
                let len: number = resp.routes[0].legs.length;
                len = len - 1;

                let studs: number = students.length;

                let j: number = len - (studs - studentOrder);
                let sec: number = 0;

                for (let i = 0; i < j; i++)
                {
                    sec = sec + resp.routes[0].legs[i].duration.value;
                }
                
                setSeconds(sec); // ETA to DROP
                setSecondInterval(setInterval(tick, 60000));

            }
            if ((type === 'pickup') && (studentStatus === 'upcoming'))
            {
                let len: number = resp.routes[0].legs.length;
                len = len - 1;

                let studs: number = students.length;

                let j: number = len - (studs - studentOrder);
                let sec: number = 0;

                for (let i = 0; i < j; i++)
                {
                    sec = sec + resp.routes[0].legs[i].duration.value;
                }
                
                setSeconds(sec); // ETA to PICKUP
                setSecondInterval(setInterval(tick, 60000));
            }
            if ((type === 'pickup') && (studentStatus === 'picked'))
            {
                let sec: number = 0;
                for (let i = 0; i < resp.routes[0].legs.length; i++)
                {
                    sec = sec + resp.routes[0].legs[i].duration.value;
                }
                
                setSeconds(sec); // ETA to INSTITUTE (ie sum of all legs)
                setSecondInterval(setInterval(tick, 60000));
            }

          } else {
            if(mapRef.current)
            {
                mapRef.current.setCenter({lat: locate!.lat, lng: locate!.lon });
            }
            setPass(t("directions_error"));
            setIspass(true);
          }
        }
        else{
            if(mapRef.current)
            {
                mapRef.current.setCenter({lat: locate!.lat, lng: locate!.lon });
            }
            setPass(t("directions_error"));
            setIspass(true);
        }
      }

    useEffect(() => {

        let driverID: string = "";
        let initialStatus: string = "";
        let changedPick: boolean = false;
        let changedStat: boolean = false;
        let direct: boolean = true;

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/trips/track/' + tripID);

                setStudents(result.data.tripStudents);
                let check: boolean = true;

                for (let i = 0; i < result.data.tripStudents.length; i++) {
                  
                    if (isNaN(result.data.tripStudents[i].lat) || isNaN(result.data.tripStudents[i].lon))
                    {
                        direct = false;
                    }

                    if (result.data.tripStudents[i].studentID === studentID)
                    {
                        setMyLocation({lat: result.data.tripStudents[i].lat, lon: result.data.tripStudents[i].lon});
                        setStudentName(result.data.tripStudents[i].name);
                        setStudentStatus(result.data.tripStudents[i].status);
                        setStudentOrder(i+1);
                        if(result.data.tripStudents[i].status !== 'upcoming')
                        {
                            setStudentDoneAt(result.data.tripStudents[i].doneAt);
                        }
                        if(result.data.tripStudents[i].status === 'dropped')
                        {
                            check = false;
                            setDropped(true);
                        }
                        if(result.data.tripStudents[i].status === 'absent')
                        {
                            check = false;
                            setAbsent(true);
                        }
                        initialStatus = result.data.tripStudents[i].status;
                    }
                }

                if (result.data.tripStudents.length < 24)
                {
                    setIsDir(direct);
                }
                else
                {
                    direct = false;
                }

            
                setName(result.data.trip.name);
                setType(result.data.trip.type);
                setSelectedDate(result.data.trip.startedAt);
                setDriverName(result.data.driver.name);
                setDriverPhone(result.data.driver.phone);

                const insti = await api.get(`/institutes/${result.data.trip.instituteID}`);
                setInstitute(insti.data);

                const routei = await api.get('/routes/' + result.data.trip.routeID);
                if (routei.data.vehicle)   
                {
                    setVehicleName(routei.data.vehicle.name);
                    setVehiclePlate(routei.data.vehicle.plate);
                }

                if(check)
                {
                    driverID = result.data.trip.driver.driverID;
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        const fetchBus = async () => {
            try {
                if(driverID && driverID !== "")
                {
                    const result = await api.get('/drivers/' + driverID);
                    const trip = await api.get('/trips/' + tripID);

                    for (let i = 0; i < trip.data.students.length; i++) {
                        if (trip.data.students[i].studentID === studentID)
                        {

                            if(initialStatus !== trip.data.students[i].status)
                            {
                                setStudentStatus(trip.data.students[i].status);

                                if(trip.data.students[i].status !== 'upcoming')
                                {
                                    if(!changedStat)
                                    {
                                        setStudentDoneAt(trip.data.students[i].doneAt);
                                        changedStat = true;
                                    }
                                }
                                if(trip.data.students[i].status === 'picked')
                                {
                                    if(!changedPick)
                                    {
                                        setChangePick(true);
                                        changedPick = true;
                                    }

                                }
                            
                                if(trip.data.students[i].status === 'dropped')
                                {
                                    setDropped(true);
                                }
                                if(trip.data.students[i].status === 'absent')
                                {
                                    setAbsent(true);
                                }
                            }
                        }
                    }
                    setStatus(result.data.isActive);
                    setLocation({lat: result.data.lat, lon: result.data.lon});
                    setLastUp(result.data.updatedAt);

                    if (!direct)
                    {
                        if(mapRef.current) {
                            mapRef.current.setCenter({lat: result.data.lat, lng: result.data.lon });
                        }
                    }
                }

            } catch (error: any) {
               //
            } 
        };

        fetchData();
        if(!myInterval)
        {
            setMyInterval(window.setInterval(fetchBus,5000));
        }

        return () => {
            if(myInterval) {
                clearInterval(myInterval);
            }
         }

    }, [authInfo, tripID, studentID, myInterval]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("ongoing_trip")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("ongoing_trip")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/home`)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("update")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={!status}
                    onDidDismiss={ () => changeEnded(secondInterval)}
                    header={t("thank_you")}
                    message={t("trip_ended")}
                    buttons={[`${t("close")}`]}
                />

                <IonAlert
                    isOpen={dropped}
                    onDidDismiss={() => changeDropped(secondInterval)}
                    header={t("on_trip_ended")}
                    message={t("your_child_dropped") + formatDate(studentDoneAt!)}
                    buttons={[`${t("close")}`]}
                />

                <IonAlert
                    isOpen={absent}
                    onDidDismiss={() => changeAbsent(secondInterval)}
                    header={t("on_trip_ended")}
                    message={t("your_child_absent") + formatDate(studentDoneAt!)}
                    buttons={[`${t("close")}`]}
                />

                <IonAlert
                    isOpen={changePick}
                    onDidDismiss={ () => changePicked(response, secondInterval)}
                    header={t("child_picked")}
                    message={t("your_child_picked") + formatDate(studentDoneAt!)}
                    buttons={[`${t("close")}`]}
                />


                <IonGrid className="ion-no-padding">
  
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin">
                            <IonCard className="grey-shade">
                                <IonCardContent className="ion-no-padding map-container">
                                    {locate && myLocation && institute && isLoaded ? (
                                        <GoogleMap
                                        onLoad={handleMapLoad}
                                        mapContainerStyle={mapStyles}
                                        zoom={14}
                                        clickableIcons={false}
                                        options={{disableDefaultUI: true}}
                                        >
                                            {type === 'pickup' && (
                                                <>
                                                <Marker zIndex={45} position={{lat: institute.lat, lng: institute.lon}} 
                                                    icon={"assets/images/school-gps.png"}/>
                                                    <InfoBox
                                                    options={{closeBoxURL: ''}}
                                                    position={new window.google.maps.LatLng(institute.lat, institute.lon)}
                                                    >
                                                    <div style={{ backgroundColor: '#086e7d', opacity: 1, padding: 2, width: '80px' }}>
                                                        <div style={{ fontSize: 11, color: `#FFFFFF`, fontWeight: 600 }}>
                                                        {institute.name}
                                                        </div>
                                                    </div>
                                                </InfoBox>
                                                </>
                                              
                                            )}

                                            <Marker zIndex={999} position={{lat: locate.lat, lng: locate.lon}} icon={"assets/images/dot.gif"} />


                                            {!isNaN(myLocation.lat) && !isNaN(myLocation.lon) && (   
                                            <>
                                                <Marker zIndex={99} position={{lat: myLocation.lat, lng: myLocation.lon}} icon={"assets/images/self-gps.png"} />
                                                <InfoBox
                                                    options={{closeBoxURL: ''}}
                                                    position={new window.google.maps.LatLng(myLocation.lat, myLocation.lon)}
                                                    >
                                                    <div style={{ backgroundColor: '#ff0000', opacity: 0.75, padding: 2, width: '80px' }}>
                                                        <div style={{ fontSize: 11, color: `#FFFFFF` }} className='text-capitalize'>
                                                        {studentName}'s Bus Stop
                                                        </div>
                                                    </div>
                                                </InfoBox>
                                            </>
                                            )}

                                            {(response === null) && (isDir) && (window.google) && (
                                                <DirectionsService
                                                options={{
                                                    destination: {lat: institute.lat, lng: institute.lon},
                                                    origin: {lat: locate.lat, lng: locate.lon},
                                                    waypoints: createWay(),
                                                    travelMode: window.google.maps.TravelMode.DRIVING
                                                    
                                                }}
                                                callback={directionsCallback}
                                                />
                                            )}

                                            <IonIcon onClick={fitCenter} size="large" icon={locateSharp} className="bottom-right" />
                                        </GoogleMap>
                                    ) : ( 
                                        <div className="load-body">
                                            <div className="load-wrapper">
                                                <span className="circle circle-1"></span>
                                                <span className="circle circle-2"></span>
                                                <span className="circle circle-3"></span>
                                                <span className="circle circle-4"></span>
                                                <span className="circle circle-5"></span>
     
                                            </div>
                                            <div className="load-title">
                                                {t("loading_maps")}
                                            </div>
                                        </div>

                                        )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    {(seconds !== 0) && (
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin"  offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="secondary-shade">
                                <IonCardContent className='status-text'>
                                  
                                              {(type === 'drop') && (studentStatus === 'upcoming') && (
                                                  t("your_child_drop")+ secondsToHm(seconds)
                                              )}
                                              {(studentStatus === 'dropped') && (
                                                   t("your_child_dropped") + formatDate(studentDoneAt!)
                                              )}
                                              {(studentStatus === 'absent') && (
                                                  t("your_child_absent") + formatDate(studentDoneAt!)
                                              )}
                                               {(type === 'pickup') && (studentStatus === 'upcoming') && (
                                                  t("your_child_pick")+ secondsToHm(seconds)
                                              )}
                                              {(type === 'pickup') && (studentStatus === 'picked') && (
                                                  t("your_child_picked") + formatDate(studentDoneAt!) + t("school_eta") +institute?.name+" "+t("is")+" " + secondsToHm(seconds)
                                              )}
                                           
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                     <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin"  offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className={type === 'drop' ? "action-card-b" : "action-card-c"}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="2" className="ion-padding-top">
                                                <IonIcon className='second-icon'
                                                    icon={busOutline}
                                                    color={type === 'drop' ? 'secondary' : 'primary'} />
                                            </IonCol>
                                            <IonCol size="10">
                                                <IonText className='action-text'>{t("trip")}: {name} ({type === 'drop' ? t("drop") : t("pickup")})<br/>
                                                    {t("started")}: {selectedDate && formatDate(selectedDate)}<br/>
                                                    {t("last_tracked")}: {lastUp && formatDate(lastUp)}
                                                    {(vehicleName !== "") && (
                                                    <>
                                                        <br/>{t("vehicle")}: {vehicleName} ({vehiclePlate})
                                                    </>
                                                    )}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className="title-heading">
                                {t("trip_driver")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="secondary-card">
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="2" className="mt-05">
                                                        <IonIcon size="large"
                                                            icon={manOutline}
                                                            color='secondary'/>
                                                        </IonCol>
                                                        <IonCol size="10">
                                                          
                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                           
                                                           <IonText className='first-meta'>
                                                                {driverName}
                                                            </IonText>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => document.location.href = `tel:${driverphone}`}> <IonIcon 
                                                                    icon={call}
                                                                    color='secondary'/></IonButton>
                                                            </IonButtons>
                                                        </IonItem>
                                                           
                                                           
                                                        </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    
                                    </IonCard>    
                           
                        </IonCol>
                     </IonRow>
                     <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                {t("destination")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonCard className="tertiary-card">
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="2" className="mt-05">
                                                        <IonIcon size="large"
                                                            icon={businessOutline}
                                                            color='tertiary'/>
                                                        </IonCol>
                                                        <IonCol size="10">
                                                          
                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                           
                                                            <IonText className='first-meta'>
                                                            {institute?.name}
                                                            </IonText>
                                                            <IonButtons slot="end">
                                                             
                                                                <IonButton onClick={() => document.location.href = `tel:${institute?.phone}`}> <IonIcon 
                                                                    icon={call}
                                                                    color='tertiary'/></IonButton>
                                                            </IonButtons>
                                                        </IonItem>
                                                           
                                                           
                                                        </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    
                                    </IonCard>    
                                                
                                
                            </IonCol>
                        </IonRow>
                    
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default OngoingTrip;
