import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { busOutline, call, manOutline, location, businessOutline, locateSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, useJsApiLoader, InfoBox } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    tripID: string
}

interface TripStudent {
    studentID: string,
    name: string,
    fileName: string,
    lat: number,
    lon: number,
    phone: string,
    pname: string,
    address: string,
    status: string,
    doneAt: Date
}

interface Eta {
    studentID: string,
    eta: number
}

interface Institute {
    _id: string,
    name: string,
    phone: string,
    lat: number,
    lon: number,
    eta: number
}

interface LatLon {
    lat: number,
    lon: number
}

const libraries = ["places" as "places"];

const OngoingTrip: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {tripID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);
    const [seconds, setSeconds] = useState < Eta[] > ([]);
    const [institute, setInstitute] = useState < Institute > ();
    const [startedDate, setStartedDate] = useState < Date > ();
    const [lastUp, setLastUp] = useState < Date > ();
    const [driverUID, setDriverUID] = useState < string > ("");
    const [driverEID, setDriverEID] = useState < string > ("");
    const [driverName, setDriverName] = useState < string > ("");
    const [driverphone, setDriverPhone] = useState < string > ("");
    const [vehicleName, setVehicleName] = useState < string > ("");
    const [vehiclePlate, setVehiclePlate] = useState < string > ("");
    const [status, setStatus] = useState < boolean > (true);
    const [locate, setLocation] = useState < LatLon > ();
    const [response, setResponse] = useState < any > (null);
    const [myInterval, setMyInterval] = useState <number> ();
    const [respTime, setRespTime] = useState <number> ();
    const [isDir, setIsDir] = useState < boolean > (false);
    const mapRef = useRef < any > ();

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!,
        libraries
    });

    if (loadError)
    {
        setMessage(t("maps_error"));
        setIserror(true);
    }

    const mapStyles = {        
        height: "62vh",
        width: "100%"
    };

    const handleMapLoad = (ref: any) => {
        mapRef.current = ref;
        if (!isDir && locate)
        {
            mapRef.current.setCenter({lat: locate.lat, lng: locate.lon });
        }
    }

    const secondsToHm = (studID: string) => {
        
        let obj = seconds.find(o => o.studentID === studID);

        let eta: number;
        eta = respTime! + obj!.eta*1000;

        let etaDate: Date;
        etaDate = new Date(eta);
        return etaDate.toLocaleTimeString('en-US'); 
    }

    const secondsToEta = (d: number) => {

        let eta: number;
        eta = respTime! + d*1000;

        let etaDate: Date;
        etaDate = new Date(eta);
        return etaDate.toLocaleTimeString('en-US'); 
    }

    const createWay = () => {
        let way: any[] = [];
        for (let i = 0; i < students.length; i++) {
            if (students[i].status === 'upcoming')
            {
                way.push({location: new window.google.maps.LatLng(students[i].lat, students[i].lon), stopover: true});
            }
        }
        return way;
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const fitCenter = () => {
        if(response) {
            if(mapRef.current) {
                mapRef.current.fitBounds(response.routes[0].bounds);
            }
        }
        else {
            if(mapRef.current && locate) {
                mapRef.current.setCenter({lat: locate.lat, lng: locate.lon });
            }
        }
    }

    const directionsCallback = (resp: any) => {
    
        if (resp !== null) {
          if (resp.status === 'OK') {
            setResponse(resp);
            setRespTime(Date.now());

            if(mapRef.current)
            {
                mapRef.current.fitBounds(resp.routes[0].bounds);
            }
         

            let tripStud: Eta[] = [];
            let stud: Eta;
            let i: number = 0;
            let sec: number = 0;

            for (let j = 0; j < students.length; j++) {
                if(students[j].status === 'upcoming')
                {
                    sec = sec + resp.routes[0].legs[i].duration.value;
                    stud = {studentID: students[j].studentID, eta: sec}
                    tripStud.push(stud);
                    i = i+1;
                }
            }

            setSeconds(tripStud);

            let insti: Institute;
            insti = institute!;
            sec = sec + resp.routes[0].legs[i].duration.value;
            insti.eta = sec;
            setInstitute(insti);


          } else {
            if(mapRef.current)
            {
                mapRef.current.setCenter({lat: locate!.lat, lng: locate!.lon });
            }
            setPass(t("directions_error"));
            setIspass(true);
          }
        }
        else{
            if(mapRef.current)
            {
                mapRef.current.setCenter({lat: locate!.lat, lng: locate!.lon });
            }
            setPass(t("directions_error"));
            setIspass(true);
        }
    }

    const finishTrip = () => {
  
        const upTrip = () => {
            return new Promise((resolve, reject) => {
                api.put('/trips/'+tripID, { 'status': 'completed' }).then(res => {
                    api.put('/drivers/'+driverUID, { 'isActive': 'disable' }).then(resu => {
                        return resolve(res.data.name);
                    }).catch(err => reject(err));
                }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        upTrip()
        .then(data => {
            if(myInterval) {
                clearInterval(myInterval);
            }
            setStatus(false);
          
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        let driverID: string = "";
        let tripStuds: TripStudent[];
        let check: boolean = false;

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/trips/admin/track/' + tripID);

                setStudents(result.data.tripStudents);
                tripStuds = result.data.tripStudents;

                if (result.data.tripStudents.length < 24)
                {
                    check = true;
                    for (let i = 0; i < result.data.tripStudents.length; i++) {
                        if (isNaN(result.data.tripStudents[i].lat) || isNaN(result.data.tripStudents[i].lon))
                        {
                            check = false;
                            break;
                        }
                    }
                    setIsDir(check);
                }
                
                setName(result.data.trip.name);
                setType(result.data.trip.type);    
                setStartedDate(result.data.trip.startedAt);

                setDriverUID(result.data.trip.driver.driverID);
                setDriverName(result.data.driver.name);
                setDriverPhone(result.data.driver.phone);

                driverID = result.data.trip.driver.driverID;

                const insti = await api.get(`/institutes/${result.data.trip.instituteID}`);
                setInstitute(insti.data);

                const routei = await api.get('/routes/' + result.data.trip.routeID);
                if (routei.data.vehicle)   
                {
                    setVehicleName(routei.data.vehicle.name);
                    setVehiclePlate(routei.data.vehicle.plate);
                }

                const drive = await api.get(`/employees/getByUID/${result.data.trip.driver.driverID}`);
                setDriverEID(drive.data._id);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        const fetchBus = async () => {
            try {
                if(driverID && driverID !== "")
                {
                    const result = await api.get('/drivers/' + driverID);
                    const trip = await api.get('/trips/' + tripID);

                    let change: boolean = false;

                    for (let i = 0; i < trip.data.students.length; i++) {
                        for (let j = 0; j < tripStuds.length; j++) {
                            if (trip.data.students[i].studentID === tripStuds[j].studentID)
                            {     
                                if(tripStuds[j].status !== trip.data.students[i].status)
                                {
                                    tripStuds[j].status = trip.data.students[i].status;
                                    tripStuds[j].doneAt = trip.data.students[i].doneAt;
                                    change = true;
                                } 
                                break;
                            }
                        }
                    }

                    if(change)
                    {
                        setStudents(tripStuds);
                    }
                                
                    setStatus(result.data.isActive);
                    setLocation({lat: result.data.lat, lon: result.data.lon});
                    setLastUp(result.data.updatedAt);

                    if (!check)
                    {
                        if(mapRef.current) {
                            mapRef.current.setCenter({lat: result.data.lat, lng: result.data.lon });
                        }
                    }

                }

            } catch (error: any) {
                setMessage(t("something_went_wrong"));
                setIserror(true);
            }
        };

        fetchData();

        if(!myInterval)
        {
            setMyInterval(window.setInterval(fetchBus,5000));
        }

        return () => {
            if(myInterval) {
                clearInterval(myInterval);
            }
         }

    }, [authInfo, tripID, myInterval]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("trip_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("trip_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app`)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("update")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={!status}
                    onDidDismiss={ () => history.push(`/app`)}
                    header={t("thank_you")}
                    message={t("trip_ended")}
                    buttons={[`${t("close")}`]}
                />
                
                <IonGrid className="ion-no-padding">
                    
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin">
                            <IonCard className="grey-shade">
                                <IonCardContent className="ion-no-padding map-container">
                                    {locate && institute && isLoaded ? (
   
                                        <GoogleMap
                                        onLoad={handleMapLoad}
                                        mapContainerStyle={mapStyles}
                                        zoom={14}
                                        clickableIcons={false}
                                        options={{disableDefaultUI: true}}
                                        >
                                            <Marker position={{lat: institute.lat, lng: institute.lon}} icon={"assets/images/school-gps.png"} />
                                            <InfoBox
                                                options={{closeBoxURL: ''}}
                                                position={new window.google.maps.LatLng(institute.lat, institute.lon)}
                                                >
                                                <div style={{ backgroundColor: '#086e7d', opacity: 1, padding: 2, width: '80px' }}>
                                                    <div style={{ fontSize: 11, color: `#FFFFFF`, fontWeight: 600 }}>
                                                    {institute.name}
                                                    </div>
                                                </div>
                                            </InfoBox>

                                            <Marker zIndex={999} position={{lat: locate.lat, lng: locate.lon}} icon={"assets/images/dot.gif"} />

                                            {
                                                students && students.map(student => {
                                                    if (isNaN(student.lat) || isNaN(student.lon))
                                                    {
                                                        return null;
                                                    }
                                                    else
                                                    {
                                                        let myLocation = {lat: student.lat, lng: student.lon};
                                                        return (
                                                            <Marker 
                                                            key={student.studentID}
                                                            position={myLocation} 
                                                            icon={"assets/images/self-gps.png"} />
                                                            /*
                                                            <InfoBox
                                                                options={{closeBoxURL: ''}}
                                                                position={myLocation}
                                                                >
                                                                <div style={{ backgroundColor: '#ff0000', opacity: 0.75, padding: 2, width: '80px' }}>
                                                                    <div style={{ fontSize: 11, color: `#FFFFFF` }}>
                                                                    {student.name}
                                                                    </div>
                                                                </div>
                                                            </InfoBox>
                                                            */
                                                        )
                                                    }    
                                                })
                                            }

                                                {(response === null) && (isDir) && (window.google) && (
                                                    <DirectionsService
                                                    options={{
                                                        destination: {lat: institute.lat, lng: institute.lon},
                                                        origin: {lat: locate.lat, lng: locate.lon},
                                                        waypoints: createWay(),
                                                        travelMode: window.google.maps.TravelMode.DRIVING
                                                        
                                                    }}
                                                    callback={directionsCallback}
                                                    />
                                                )}

                                                {(response !== null) && (
                                                    <DirectionsRenderer
                                                    options={{ 
                                                        directions: response,
                                                        preserveViewport: true,
                                                        markerOptions: {
                                                            visible: false
                                                        }
                                                    }}
                                                   
                                                    />
                                                )}

                                                <IonIcon onClick={fitCenter} size="large" icon={locateSharp} className="bottom-right" />
                                        </GoogleMap>
                                    ) : ( 
                                        <div className="load-body">
                                            <div className="load-wrapper">
                                                <span className="circle circle-1"></span>
                                                <span className="circle circle-2"></span>
                                                <span className="circle circle-3"></span>
                                                <span className="circle circle-4"></span>
                                                <span className="circle circle-5"></span>
                                            </div>
                                            <div className="load-title">
                                                {t("loading_maps")}
                                            </div>
                                        </div>
                                        )}

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className="ion-no-padding ion-no-margin">
                            <IonCard className={type === 'drop' ? "action-card-b" : "action-card-c"}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="2" className="ion-padding-top">
                                                <IonIcon className='third-icon'
                                                    icon={busOutline}
                                                    color={type === 'drop' ? 'secondary' : 'primary'} />
                                            </IonCol>
                                            <IonCol size="10">
                                                <IonText className='action-text'>{t("trip")}: {name} ({type === 'drop' ? t("drop") : t("pickup")})<br/>
                                                    {t("started")}: {startedDate && formatDate(startedDate)}<br/>
                                                    {t("last_tracked")}: {lastUp && formatDate(lastUp)}
                                                    {(vehicleName !== "") && (
                                                    <>
                                                        <br/>{t("vehicle")}: {vehicleName} ({vehiclePlate})
                                                    </>
                                                    )}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("trip_driver")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="secondary-card">
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="2" className="mt-05" onClick={ () => history.push(`/app/employee/${driverEID}`)}>
                                                        <IonIcon size="large"
                                                            icon={manOutline}
                                                            color='secondary'
                                                            />
                                                        </IonCol>
                                                        <IonCol size="10">
                                                          
                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                        >
                                                           
                                                            <IonText>
                                                                {driverName}
                                                            </IonText>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => document.location.href = `tel:${driverphone}`}> <IonIcon 
                                                                    icon={call}
                                                                    color='secondary'/></IonButton>
                                                            </IonButtons>
                                                        </IonItem>
                                                           
                                                           
                                                        </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    
                                    </IonCard>    
                           
                        </IonCol>
                     </IonRow>
                     <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("trip_students")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            
                                         {students.map((student) => {
                                                return (
                                                 <IonCard key={student.studentID} >
                                                    <IonCardContent>
                                                        <IonGrid>
                                                            <IonRow>
                                                                <IonCol size="2" onClick={() => history.push(`/app/student/${student.studentID}`)}>
                                                                <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                {student.fileName ?
                                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.fileName}`} alt="Profile Pic" width="70" />)
                                                                    : (<img src={`assets/images/avatar.png`} alt="Profile Pic" width="70" />)
                                                                }

                                                                    </div>
                                                                </div>
                                                                    </IonCol>
                                                                    <IonCol size="10"className="ion-no-padding">
                                                                    
                                                                        <IonItem detail={false}
                                                                            lines='none'
                                                                            button={false}
                                                                            >
                                                                        
                                                                            <IonText className='first-meta'>
                                                                                {student.name}<br/>
                                                                                {student.pname}<br/>
                                                                                {student.address}
                                                                            </IonText>
                                                                            <IonButtons slot="end">
                                                                                <IonButton onClick={() => document.location.href = `tel:${student.phone}`}> <IonIcon 
                                                                                    icon={call}
                                                                                    color='secondary'/></IonButton>
                                                                                
                                                                                <IonButton onClick={() => history.push(`/app/student/location/${student.studentID}`)} > 
                                                                                <IonIcon 
                                                                                    icon={location}  
                                                                                    color='secondary'/></IonButton>
                                                                            </IonButtons>
                                                                                    
                                                                        </IonItem>
                                                                    </IonCol>
                                                                    
                                                            </IonRow>
                                                            <IonRow className="ion-padding-top">
                                                               <IonCol>
                                                                    <IonButton expand="full" disabled={true} className="first-button" fill="clear" >
                                                                                {(student.status !== 'upcoming') && (student.status +" "+ t("at") +" "+ formatDate(student.doneAt))} 
                                                                                {(student.status === 'upcoming') && (!isDir) && t("eta_unavailable")}
                                                                                {(student.status === 'upcoming') && (isDir) && (!seconds.length) && t("calc_eta")}
                                                                                {(student.status === 'upcoming') && (seconds.length > 0) && (t("eta")+': ' + secondsToHm(student.studentID))}
                                                                    </IonButton>
                                                                           
                                                                    </IonCol>
                                                                    
                                                            </IonRow>
                                                        </IonGrid>
                                                    </IonCardContent>
                                                </IonCard>
                                               

                                                )})
                                            }

                                
                            </IonCol>
                        </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("destination")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                
                {(institute) && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="tertiary-card">
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="2" className="mt-20">
                                                        <IonIcon size="large"
                                                            icon={businessOutline}
                                                            color='tertiary'/>
                                                        </IonCol>
                                                        <IonCol size="10">
                                                          
                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                           
                                                            <IonText className='first-meta'>
                                                            {institute.name}<br/>
                                                                
                                                                {institute.eta ? (t("eta")+': ' + secondsToEta(institute.eta)) : t("eta_unavailable")}
                                                            </IonText>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => history.push(`/app/config/institute/location`)}> <IonIcon 
                                                                    icon={location}
                                                                    color='warning'/></IonButton>
                                                                       <IonButton onClick={() => document.location.href = `tel:${institute.phone}`}> <IonIcon 
                                                                    icon={call}
                                                                    color='tertiary'/></IonButton>
                                                            </IonButtons>
                                                        </IonItem>
                                                           
                                                           
                                                        </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    
                                    </IonCard>    
                                                
                                
                            </IonCol>
                        </IonRow>
                        )}

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonButton className="first-button" 
                                fill="clear" 
                                onClick={finishTrip}>
                                    {t("finish_trip")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default OngoingTrip;
