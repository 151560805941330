import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import useApiService from '../../../components/ApiService';

const Transport: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [trips, setTrips] = useState < number > (0);
    const [drivers, setDrivers] = useState < number > (0);
    const [routes, setRoutes] = useState < number > (0);
            
    useEffect(() => {

        const fetchData = async () => {
            setShowDownloading(true);
            try {
                const stats = await api.get(`/institutes/transportStats/${authInfo.user.instituteID}`);
                const statsData = stats.data;

                setDrivers(statsData.drivers);
                setRoutes(statsData.routes);
                setTrips(statsData.trips);
            }
            catch (error: any) {
                //
            } finally {
                setShowDownloading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("transport")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("transport")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>

                <IonRow className="ion-padding-vertical max-lg">
                    <IonCol>
                        <IonRow className='dash-border-bottom'>
                            <IonCol sizeMd="6" size="12">
                                <IonCard className="dashboard-card dash-bg-4">
                                    <IonCardContent className="ion-no-padding">
                                        <IonRow className='ion-padding-horizontal'>
                                            <IonCol size="9" className="dash-title text-dark">         
                                                {t("trans_overview")}
                                            </IonCol>
                                            <IonCol size="3" className='dash-icon'>
                                                <div className="vertical-container">
                                                    <div className="vertical-center"> 
                                                        <img alt={t("trans_overview")} src="assets/images/transport-stats.png" />
                                                    </div>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-horizontal">
                                            <IonCol className="dash-box-info">
                                                {t("trans_overview_info")}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding">
                                            <IonCol size="3.5" className='dash-box'>
                                                <IonRow className="dash-box-header">
                                                    <IonCol className="dash-box-title">
                                                    {t("ongoing_trips")}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="dash-box-footer">
                                                    <IonCol className="dash-box-stat">
                                                    {trips}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                <IonRow className="dash-box-header">
                                                    <IonCol className="dash-box-title">
                                                    {t("total_drivers")}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="dash-box-footer">
                                                    <IonCol className="dash-box-stat">
                                                    {drivers}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                <IonRow className="dash-box-header">
                                                    <IonCol className="dash-box-title">
                                                    {t("total_routes")}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="dash-box-footer">
                                                    <IonCol className="dash-box-stat">
                                                    {routes}
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeMd="6" size="12">
                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                        <IonTitle id="open-trips-info" className='border-bottom-sec'>
                                            {t("manage_trips")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                        </IonTitle>
                                        <IonPopover trigger="open-trips-info" className='info-popover'>
                                            <IonCard className='note-card-3 ion-no-margin'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonRow>
                                                        <IonCol className='note-text'>
                                                            <p>
                                                            {t("trips_help")}
                                                            </p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonPopover>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/tracking`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("realtime_tracking")} src="assets/images/tracking.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("realtime_tracking")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/trips/completed`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("completed_trips")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        <IonCard className="action-card" routerLink={`/app/trips/reports`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("trips_reports")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/trips/alerts`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("trips_alerts")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeMd="6" size="12">

                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                        <IonTitle id="open-routes-info" className='border-bottom-sec'>
                                            {t("manage_routes")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                        </IonTitle>
                                        <IonPopover trigger="open-routes-info" className='info-popover'>
                                            <IonCard className='note-card-3 ion-no-margin'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonRow>
                                                        <IonCol className='note-text'>
                                                            <p>
                                                            {t("routes_help")}
                                                            </p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonPopover>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    <IonCol size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/routes`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_routes")} src="assets/images/route-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_routes")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/routes/add`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_new_route")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                        <IonTitle id="open-vehicles-info" className='border-bottom-sec'>
                                            {t("manage_vehicles")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                        </IonTitle>
                                        <IonPopover trigger="open-vehicles-info" className='info-popover'>
                                            <IonCard className='note-card-3 ion-no-margin'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonRow>
                                                        <IonCol className='note-text'>
                                                            <p>
                                                            {t("vehicles_help")}
                                                            </p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonPopover>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/vehicles`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_vehicles")} src="assets/images/transporters.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_vehicles")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="action-card" routerLink={`/app/vehicles/add`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_new_vehicle")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        <IonCard className="action-card" routerLink={`/app/transactions/transport`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("transport_expense")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>

                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
    );
}

export default Transport;