import React from 'react';
import axios from 'axios';
import { Preferences } from '@capacitor/preferences';

interface AuthProps {
  children?: React.ReactNode;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: parseInt(process.env.REACT_APP_API_TO!)
});

const getStorage = async (key: string) => {
  const { value } = await Preferences.get({ key });
  return value;
};

const setStorage = async (key: string, value: string) => {
    await Preferences.set({
        key,
        value,
    });
};

const removeStorage = async (key: string) => {
    await Preferences.remove({ key });
};

type UserDataInterface = { initialized: boolean; loggedIn: boolean; token: string, user: any, institute: any };
type MyContextInterface = {
  authInfo: UserDataInterface;
  switchInstitute: (userID: string, instituteID: string) => Promise<boolean>;
  logOut: () => Promise<boolean>;
  logIn: (email: string, password: string) => Promise<boolean>;
  register: (email: string, password: string, name: string, phone: string, role: string, instituteID: string, roles?: string[], institutes?: string[]) => Promise<string>;
  initialize: () => Promise<boolean>;
};

// create the context
export const AuthContext = React.createContext<MyContextInterface | undefined> (undefined);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...

export const AuthProvider: React.FC<AuthProps> = (props: any) => {
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();

  const switchInstitute = (userID: string, instituteID: string) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/switch', { userID, instituteID })
      .then(async res => {

        let v = {
          initialized: true,
          loggedIn: true,
          token: res.data.token,
          user: res.data.user,
          institute: res.data.institute
        };
        setAuthInfo(v);

        await setStorage('TOKEN', v.token);
        await setStorage('USER', JSON.stringify(v.user));
        await setStorage('INSTITUTE', JSON.stringify(v.institute));

        return resolve(true);
        
      })
      .catch(err => reject(err));
     
    });
  };

  const logOut = () => {
    return new Promise(async (resolve) => {
        await removeStorage('TOKEN');
        await removeStorage('USER');

        const storedInstitute = await getStorage('INSTITUTE');

        if (storedInstitute)
        {
            await removeStorage('INSTITUTE');
        }
          
        setAuthInfo({ initialized: true, loggedIn: false, token: "", user: null, institute: null });
        return resolve(true);
      });
  };

  const logIn = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/login', { email, password, 'appID': process.env.REACT_APP_APP_ID}).then(async res => {

        let v = {
          initialized: true,
          loggedIn: true,
          token: res.data.token,
          user: res.data.user,
          institute: res.data.institute
        };
        setAuthInfo(v);
        await setStorage('TOKEN', v.token);
        await setStorage('USER', JSON.stringify(v.user));

        if (v.institute !== null)
        {
          await setStorage('INSTITUTE', JSON.stringify(v.institute));
        }

        return resolve(true);
        
      }).catch(err => reject(err));
     
    });
  };

  const register = (email: string, password: string, name: string, phone: string, role: string, instituteID: string, roles: string[] = [], institutes: string[] = []) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/register', { email, password, name, phone, role, instituteID, roles, institutes}).then(res => {
        return resolve(res.data.user._id);
      }).catch(err => reject(err));
    });
  };

  const initialize = async () => {
    const value = await getStorage('TOKEN');
    const resu = await getStorage('USER');
    const resi = await getStorage('INSTITUTE');

    if ((value !== null) && (resu !== null))
    {
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        token: value,
        user: JSON.parse(resu),
        institute: (resi !== null) ? JSON.parse(resi) : null
      });
    }
    else
    {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        token: "",
        user: null,
        institute: null
      });
    }
  };

  let v = {
    authInfo,
    logOut,
    switchInstitute,
    logIn,
    register,
    initialize
  };

  return <AuthContext.Provider value={v} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);