import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { pricetagSharp, timeSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';


interface ParamTypes {
    schoolID: string,
    courseID: string
}

const CourseDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const history = useHistory();
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {schoolID, courseID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [duration, setDuration] = useState < string > ("");
    const [applicationFees, setApplicationFees] = useState < number > (0);
    const [annualCost, setAnnualCost] = useState < string > ("");
    const [active, setActive] = useState < boolean > (false);
    const [photo, setPhoto] = useState < string > ("");
    const [text, setText] = useState < string > ("");

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/admissionCourses/${courseID}`);

                setName(result.data.name);
                setPhoto(result.data.photo);
                setDuration(result.data.duration);
                setActive(result.data.isActive);
                setApplicationFees(result.data.applicationFees);
                setAnnualCost(result.data.annualCost);
                setText(result.data.text);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, courseID]);


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{
                        (name !== "") ? (name) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{
                        (name !== "") ? (name) : 
                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                        }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <Swiper 
                                spaceBetween={0}
                                initialSlide={0}
                                slidesPerView={1}
                            >
                                {(photo !== "") && (
                                <SwiperSlide >
                                        <img src={`${process.env.REACT_APP_STATIC_S3}/images/${photo}`} alt={name} style={{'width': '100%', 'height': 'auto'}} />
                                </SwiperSlide>  
                                )}

                                
                            </Swiper>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding ion-no-margin">

                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="ion-no-padding ion-no-margin">
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol className="insti-info">
                                            <IonRow className="ion-margin-horizontal ion-padding-vertical">
                                                <IonCol>
                                                    <p><span className="insti-name">{name}</span></p>
                                                    
                                                    <p><IonIcon icon={timeSharp} color="tertiary" className="mt-15" /><span className="insti-address">{duration}</span></p>
                                                    <p><IonIcon icon={pricetagSharp} color="success" className="mt-15" /><span className="insti-address">{t("rs")} {annualCost}</span></p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-margin-horizontal ion-padding-bottom">
                                                <IonCol>
                                                    <IonTextarea value={text} readonly={true} autoGrow={true} />
                                                </IonCol>
                                                    
                                            </IonRow>
                                        </IonCol>
                                        
                                    </IonRow>

                                </IonCardContent>
                            </IonCard>    
                        </IonCol>
                    </IonRow>
                 
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => history.push(`/app/application/${schoolID}/${courseID}`)}
                                className="first-button"
                                fill="clear"
                                disabled={!active}>{t("apply_now")} {t("for")} {t("rs")} {applicationFees}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CourseDetail;