import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import {useAuth} from "../../../components/AuthContext";
import { helpCircleOutline } from 'ionicons/icons';

const ManageFees: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("fee_head_struct")}</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton id="open-fees-info" fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon color="success" icon={helpCircleOutline} className="badge-icon" />
                            <IonPopover trigger="open-fees-info" className='info-popover'>
                                <IonCard className='note-card-3 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol className='note-text'>
                                                <p>
                                                {t("fees_help")}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("fee_head_struct")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>
                    <IonRow className="ion-padding-vertical max-xl">

                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/student`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    <IonRow className="ion-padding-top">
                                        <IonCol className="ion-no-padding">
                                            <img alt={t("student_fee_struct")} src="assets/images/students-1.png"/>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_fee_struct")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/add`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    <IonRow>
                                        <IonCol>
                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_fee_struct")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/manage`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    <IonRow>
                                        <IonCol>
                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_fee_structs")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/class`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    <IonRow className="ion-padding-top">
                                        <IonCol className="ion-no-padding">
                                            <img alt={(authInfo.institute.type === "school") ? t("class_fee_struct") : t("batch_fee_struct")} src="assets/images/class-1.png"/>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("class_fee_struct") : t("batch_fee_struct")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/feeheads/add`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    <IonRow>
                                        <IonCol>
                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_fee_head")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                            <IonCard className="action-card action-card-2" routerLink={`/app/feeheads`}>
                                <IonCardContent className="ion-no-padding ion-text-center">
                                    <IonRow>
                                        <IonCol>
                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_fee_heads")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        

                        
                        
                    </IonRow>
                          
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ManageFees;