import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline, attach, camera, checkboxOutline, construct, images, searchCircleOutline, trash, videocam } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    studentID?: string
}
interface ClassInterface {
    _id: string,
    name: string,
    year: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface Student {
    _id: string,
    parentID: string,
    name: string,
    dob: Date,
    isChecked?: boolean
}

interface SearchStudent {
    _id: string,
    classID: string,
    name: string,
    classInfo: ClassInfo,
    photo?: string
}
interface ClassInfo {
    className: string,
    session: string,
    sessionID: string
}

const AddInquiry: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [type, setType] = useState < string > ("general");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [fromSession, setFromSession] = useState < string > ("");
    const [fromClasses, setFromClasses] = useState < ClassInterface[] > ([]);
    const [fromClass, setFromClass] = useState < string > ("");
    const [fromStudents, setFromStudents] = useState < Student[] > ([]);
    const [buttonColor, setButtonColor] = useState < string > ("light");
    const [searchText, setSearchText] = useState<string>("");
    const [searchStudents, setSearchStudents] = useState < SearchStudent[] > ([]);
    const [showSearch, setShowSearch] = useState < boolean > (false);
    const [skip1, setSkip1] = useState<number>(0);
    const inputRef = useRef<HTMLIonSearchbarElement>(null); // To get back focus
    const [chatID, setChatID] = useState<string>("");

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleFromSession = (sessionId : string) => {
        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionId}`);
                setFromClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setFromSession(sessionId);
        setFromClass("");
        setFromStudents([]);
    };

    const handleFromClass = (classID : string) => {

        setFromClass(classID);

        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
        {
            if (!fromSession || fromSession === "") {
                setMessage(t("select_session_first"));
                setIserror(true);
                return;
            }
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/nameByClass/${classID}`);
                setFromStudents(studs.data);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const studs = await api.get(`/students/getAllInstitute/${authInfo.user.instituteID}/${fromSession}`);
                    setFromStudents(studs.data);
                }
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Teacher")))
                {
                    const studs = await api.get(`/students/getAllTeacher/${authInfo.user._id}`);
                    setFromStudents(studs.data);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        if (classID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }
        
    };

    const handleChecked = (id : number) => {

        let studs = [...fromStudents];
        studs[id].isChecked = !studs[id].isChecked;
        setFromStudents(studs);
        
    };

    const handleAll = () => {
        if (buttonColor === "light")
        {
            let studs = [...fromStudents];

            for (let i=0; i < studs.length; i++)
            {
                if (studs[i].parentID !== "none")
                    studs[i].isChecked = true;
            }
            setFromStudents(studs);
            setButtonColor("warning");
        }
        else
        {
            let studs = [...fromStudents];

            for (let i=0; i < studs.length; i++)
            {
                if (studs[i].parentID !== "none")
                    studs[i].isChecked = false;
            }
            setFromStudents(studs);
            setButtonColor("light");
        }
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUpload([rawFile]);
                                    setImageURL(webPath);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUpload([rawFile]);
                            setImageURL(webPath);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads: File[] =  [];
            let imgUrl = "";

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            imgUrl = webPath;
                        }
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: 'image/jpeg',
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            imgUrl = webPath;
                        }
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            const base64 = await fileToDataUri(rawFile);
                            imgUrl = base64;
                        }
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(imgUrl);
        } 
        catch (error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }

    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads: File[] =  [];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
           }
           setUpload(theUploads);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };
      
   const pickFiles = async () => {
    isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads: File[] = [];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
            }
            setUpload(theUploads);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("inq_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("select_inq_type"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("inq_text_mandatory"));
            setIserror(true);
            return;
        }

        let students: Array < string > = [];
        for (let i = 0; i < fromStudents.length ; i++) {
            if (fromStudents[i].isChecked)
            students.push(fromStudents[i]._id);
        }

        if(!students.length)
        {
            setMessage(t("pick_students"));
            setIserror(true);
            return;
        }

        const addInq = () => {
            return new Promise((resolve, reject) => {
                api.post('/inquiries/admin/add', { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, 'classID': fromClass, students, title, type, text }).then(res => {
                    return resolve(res.data.inquiry);
                }).catch(err => reject(err));
            });
        }
  
        const addAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/inquiries/admin/attach/add', formData).then(res => {
                    return resolve(res.data.inquiry);
                }).catch(err => reject(err));
            });
        }
  
        if (upload.length > 0)
        {
            let formData = new FormData();

            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('adminID', authInfo.user._id);
            formData.append('classID', fromClass);
            formData.append('students', JSON.stringify(students));
            formData.append('title', title);
            formData.append('type', type);
            formData.append('text', text);
            for (let i=0; i < upload.length; i++)
            {
                formData.append('attachment[]', upload[i], `${authInfo.user.instituteID}`);
            }
  
            setShowLoading(true);
            addAttach(formData)
            .then((data: any) => {
                setChatID(data._id);
                setPass(data.title + t("has_been_issued"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            addInq()
            .then((data: any) => {
                setChatID(data._id);
                setPass(data.title + t("has_been_issued"));
                setIspass(true);
            })
            .catch((error) => {
               //
            })
            .finally(() => setShowLoading(false));
        }
    }

    const handleSearchText = (search : string) => {

        if (search.length > 0) {
            const fetchAll = async () => {
                try {
                    const studs = await api.post(`/students/searchStudent/${authInfo.user.instituteID}`, {searchText: search});
                    setSearchStudents(studs.data.students);
                    inputRef.current?.setFocus();
    
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                }
            };

            const fetchTeacher = async () => {
                try {
                    const studs = await api.post(`/students/searchStudentTeacher/${authInfo.user._id}`, {searchText: search});
                    setSearchStudents(studs.data.students);
                    inputRef.current?.setFocus();
    
                } catch (error: any) {
                    //
                }
            };
    
            if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Teacher")))
            {
                fetchTeacher();
            } else {
                fetchAll();
            }

        } else {
            setSearchStudents([]);
        }

        setSearchText(search);
    }

    const handleSearchStudent = (studentID : string) => {

        const fetchUp = async (sessionID: string, classID: string) => {
            setShowLoading(true);
            try {

                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                    setFromClasses(result.data);
                }

                const studs = await api.get(`/students/getByClass/${classID}`);
                const id = studs.data.findIndex((el: { _id: string; }) => el._id === studentID);
                if (id > -1) {
                    studs.data[id].isChecked = true;
                    setFromStudents(studs.data);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        const theStudent = searchStudents.find((el) => el._id === studentID)!;
        const sessionID = theStudent.classInfo.sessionID;
        const classID = theStudent.classID;

        fetchUp(sessionID, classID);
        setFromSession(sessionID);
        setFromClass(classID);
        setShowSearch(false);

    }

    const loadItems1 = async () => {

        const limit1 = skip1 + 30;

        try {
            if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Teacher")))
            {
                const result = await api.post(`/students/searchStudentTeacher/${authInfo.user._id}?skip=${limit1}`, {searchText});

                if(result.data.students && result.data.students.length) {
                    setSearchStudents([...searchStudents, ...result.data.students]);
                    setSkip1(limit1);
                }
            } else {
                const result = await api.post(`/students/searchStudent/${authInfo.user.instituteID}?skip=${limit1}`, {searchText});

                if(result.data.students && result.data.students.length) {
                    setSearchStudents([...searchStudents, ...result.data.students]);
                    setSkip1(limit1);
                }
            }

        } catch (error: any) {
            //
        }
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setFromClasses(classy.data);

                    if (studentID)
                    {
                        const stud = await api.get(`/students/${studentID}`);
                        setFromClass(stud.data.classID);

                        const studs = await api.get(`/students/nameByClass/${stud.data.classID}`);

                        const id = studs.data.findIndex((el: { _id: string; }) => el._id === studentID);
                        if (id > -1) {
                            studs.data[id].isChecked = true;
                            setFromStudents(studs.data);
                        }
                        
                    }
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);

                    if (studentID)
                    {

                        const stud = await api.get(`/students/${studentID}`);

                        setFromSession(stud.data.classInfo.sessionID);

                        const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${stud.data.classInfo.sessionID}`);
                        setFromClasses(result.data);

                        setFromClass(stud.data.classID);

                        const studs = await api.get(`/students/nameByClass/${stud.data.classID}`);
                        const id = studs.data.findIndex((el: { _id: string; }) => el._id === studentID);
                        if (id > -1) {
                            studs.data[id].isChecked = true;
                            setFromStudents(studs.data);
                        }
                        
                    } else {
                        //Fix for switch branch super admin
                        setFromSession("");
                        setFromClasses([]);
                        setFromClass("");
                        setFromStudents([]);
                    }
                    
                }

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("make_inquiry")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("make_inquiry")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            history.push(`/app/inquiry/${chatID}`);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                  {/* Modal for displaying search results */}
                  <IonModal isOpen={showSearch} showBackdrop={true} 
                        initialBreakpoint={1} breakpoints={[1]} handle={false}
                        onDidDismiss={() => setShowSearch(false)}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{t("find_student")}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowSearch(false)}>{t("close")}</IonButton>
                        </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent onTouchMove={(e) => {e.stopPropagation()}}>
                        <IonSearchbar value={searchText} 
                            placeholder={t("search_student")}
                            ref={inputRef}
                            onIonInput={
                            (e) => handleSearchText(e.detail.value!)
                        }>
                        </IonSearchbar>
                        <IonList>
                        {(searchStudents.length > 0) ? (
                            searchStudents.map((stud) => 
                            (<IonItem key={stud._id}
                                button={true}
                                detail={false}
                                onClick={() => handleSearchStudent(stud._id)}
                                >
                                <IonAvatar slot="start">
                                    {stud.photo && (
                                        <IonImg src={`${process.env.REACT_APP_STATIC_S3}/images/${stud.photo}`} />
                                    )}
                                    {!stud.photo && (
                                        <IonImg src={`assets/images/avatar.png`} />
                                    )}
                                </IonAvatar>
                                <IonLabel>
                                <h2 className="text-capitalize">{stud.name} ({stud.classInfo.className})</h2>
                                </IonLabel>
                            </IonItem>)
                            )) : (
                            <IonItem lines="none">
                                <IonIcon icon={alertCircleOutline}
                                    slot="start"
                                    color="danger" />
                                <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                            </IonItem>
                            )}
                            
                        </IonList>
                            {/* Infinite Scroll for Loading More Results */}
                        {searchStudents.length > 0 && (
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems1();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        )}
                    </IonContent>
                </IonModal>

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle onClick={() => setShowSearch(true)} className='border-bottom-sec'>
                                        {t("select_students")}
                                        <IonIcon className="help-icon" color="primary" icon={searchCircleOutline} />
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonRow>
                                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                                       <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={fromSession}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleFromSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    )}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    )}
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={fromClass}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleFromClass(e.detail.value)
                                                                }>
                                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                                    {fromClasses.map((classy) => (
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol className='ion-padding-horizontal'>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("students")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <div className="ion-table">
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="7" className='table-heading'>
                                            <IonIcon 
                                                icon={checkboxOutline}
                                                color={buttonColor}
                                                className='table-icon'
                                                onClick={handleAll}
                                            />
                                            <IonText className='ion-margin-start'>{t("name")}</IonText>
                                        </IonCol>
                                        <IonCol size="4" className='table-heading'>
                                        {t("dob")}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="light"
                                                className='table-icon'
                                                />
                                        </IonCol>
                                    </IonRow>
                                {(fromStudents.length > 0) ? (fromStudents.map((stud, i) =>  
                                (stud.parentID !== "none") && (
                                    <IonRow key={stud._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                        <IonCol size="7" className='table-field text-capitalize' >
                                            <IonCheckbox labelPlacement="end" checked={stud.isChecked} 
                                                onClick={(e) => handleChecked(i) } 
                                            >
                                                    {stud.name}
                                            </IonCheckbox>
                                        
                                        </IonCol>
                                        <IonCol size="4" className='table-field'>
                                            {formatDated(stud.dob)}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${stud._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                
                                    </IonRow>
                                    ))) : (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonCard>
                                <IonCardHeader>
                                        <IonCardSubtitle className='info-subtitle'>{t("chat_info")}</IonCardSubtitle>
                                    </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonList className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked" color="secondary">{t("select_type")}</IonLabel>
                                                        <IonSelect value={type}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                            placeholder={t("select_type")}
                                                            onIonChange={
                                                                (e) => setType(e.detail.value)
                                                        }>
                                                                
                                                            <IonSelectOption value="general">{t("general")}</IonSelectOption>  
                                                            <IonSelectOption value="classwork">{t("classwork")}</IonSelectOption>
                                                            <IonSelectOption value="homework">{t("homework")}</IonSelectOption>
                                                            <IonSelectOption value="query">{t("query")}</IonSelectOption>  
                                                            <IonSelectOption value="feedback">{t("feedback")}</IonSelectOption>  
                                                        </IonSelect>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={title}
                                                            onIonInput={
                                                                (e) => setTitle(e.detail.value!)
                                                        }></IonInput>
                                                </IonItem>
                                                
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="floating" color="secondary">{t("write_to_us")}</IonLabel>
                                                    <IonTextarea rows={4} value={text} onIonInput={
                                                            (e) => setText(e.detail.value!)
                                                    }></IonTextarea>
                                                
                                                </IonItem>

                                                {isPlatform("capacitor") && (
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={takePhoto}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                        {t("take_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}

                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickImages}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={images}
                                                    />
                                                    <IonLabel>
                                                        {t("upload_photos")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickVideo}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={videocam}
                                                    />
                                                    <IonLabel>
                                                        {t("upload_video")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickFiles}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={attach}
                                                    />
                                                    <IonLabel>
                                                        {t("attach_doc")}
                                                    </IonLabel>
                                                    
                                                </IonItem>

                                                {(upload.length > 0) && (
                                                <IonItem detail={true}
                                                        detailIcon={trash}
                                                        color="danger"
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => {
                                                            setUpload([]);
                                                            setImageURL("");
                                                        }}
                                                    >
                                                    <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}

                                            </IonList>
                                        </IonCol>
                                    </IonRow>
                                    {imageURL && (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                
                                                    <img src={imageURL} 
                                                        alt={t("image_preview")}
                                                        width="320" 
                                                    />
                
                                                </IonCardContent>        
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("open_new_chat")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddInquiry;
