import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { attach, camera, images, trash } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

const AddLeave: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [dates, setDates] = useState < string | string[] > ([]);
    const [upload, setUpload] = useState < File[] > ([]);
    const [imageURL, setImageURL] = useState < string > ("");
    
    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUpload([rawFile]);
                                    setImageURL(webPath);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUpload([rawFile]);
                            setImageURL(webPath);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImages = async () => {
        try {
            const result = await FilePicker.pickImages();
            const theUploads: File[] =  [];
            let imgUrl = "";

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((i === 0) && ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png')))
                    {
                        imgUrl = webPath;
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    theUploads.push(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    theUploads.push(rawFile);
                    if ((i === 0) && ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png')))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        imgUrl = base64;
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(imgUrl);
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }

    };
      
    const pickFiles = async () => {
         try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads: File[] = [];
            let imgUrl = "";

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        imgUrl = webPath;
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    theUploads.push(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    theUploads.push(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        imgUrl = base64;
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(imgUrl);
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const formatDate = (value: string | string[]) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else if (value && value.length)
        {
            return (value.map((tag, i) => (<IonText className='tran-tag' key={i}>{tag}</IonText>)));
           
        }
        else
        {
            return t("leave_dates");
        }
    };

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("leave_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("cause_mandatory"));
            setIserror(true);
            return;
        }

        if (dates.length === 0)
        {
            setMessage(t("leave_dates_mandatory"));
            setIserror(true);
            return;
        }
  
        const addInq = () => {
          return new Promise((resolve, reject) => {
            api.post('/staffChats/add', { 'employeeID': authInfo.user._id, title, text, type: 'leave', dates }).then(res => {
                return resolve(res.data.staffChat.title);
            }).catch(err => reject(err));
          });
        }

        const addAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/staffChats/attach/add', formData).then(res => {
                return resolve(res.data.staffChat.title);
              }).catch(err => reject(err));
            });
        }

        if (upload.length > 0)
        {
            let formData = new FormData();

            formData.append('employeeID', authInfo.user._id);
            formData.append('title', title);
            formData.append('type', 'leave');
            formData.append('text', text);
            formData.append('dates', JSON.stringify(dates));
            for (let i=0; i < upload.length; i++)
            {
                formData.append('attachment[]', upload[i], `${authInfo.user.instituteID}`);
            }

            setShowLoading(true);
            addAttach(formData)
            .then(data => {
                setPass(data+t("has_been_submitted"));
                setIspass(true);
            })
            .catch((error) => {
               //
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            addInq()
            .then(data => {
            
                setPass(data + t("has_been_submitted"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }

    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/user/leaves"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("leave_request")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("leave_request")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/user/leaves`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("leave_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("leave_title")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("leave_title")}
                                                value={title}
                                                onIonInput={
                                                    (e) => setTitle(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            
                                            <IonLabel className="input-field ion-text-wrap" color="secondary">{formatDate(dates)}</IonLabel>
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("leave_dates")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={dates}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        multiple={true}
                                                        onIonChange={ev => setDates(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                             
                                    <IonItem>
                                        <IonLabel position="floating" className="input-label" color="secondary">{t("leave_text")}*</IonLabel>
                                        <IonTextarea rows={8} value={text} onIonInput={(e) => setText(e.detail.value!)}></IonTextarea>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                    <IonCol>
                                    <IonList className="ion-no-padding">

                                        {isPlatform("capacitor") && (
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={takePhoto}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={camera}
                                            />
                                            <IonLabel>
                                                {t("take_photo")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        )}

                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={pickImages}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={images}
                                            />
                                            <IonLabel>
                                                {t("upload_photos")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            onClick={pickFiles}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={attach}
                                            />
                                            <IonLabel>
                                                {t("attach_file")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        {(upload.length > 0) && (
                                            <IonItem detail={true}
                                                    detailIcon={trash}
                                                    color="danger"
                                                    lines='none'
                                                    button={true}
                                                    onClick={() => {
                                                            setUpload([]);
                                                            setImageURL("");
                                                        }
                                                    }
                                                >
                                                <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                <IonLabel>
                                                    {t("remove_files")}
                                                </IonLabel>
                                                
                                            </IonItem>
                                            )}

                                    </IonList>
                                    </IonCol>
                                    </IonRow>
                                    {imageURL && (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                
                                                    <img src={imageURL} 
                                                        alt={t("image_preview")}
                                                        width="320" 
                                                    />
                
                                                </IonCardContent>        
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("submit")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddLeave;
