import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { business, busOutline, person } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

import 'react-vertical-timeline-component/style.min.css';

interface ParamTypes {
    tripID: string
}

interface TripStudent {
    studentID: string,
    name: string,
    order: number,
    status: string,
    doneAt: Date
}

const CompletedTrip: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {tripID} = useParams < ParamTypes > ();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);
    const [startedDate, setStartedDate] = useState < Date > ();
    const [finishedDate, setFinishedDate] = useState < Date > ();
    const [institute, setInstitute] = useState < string > ("");
    const [driver, setDriver] = useState < string > ("");
    const [driverID, setDriverID] = useState < string > ("");

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB') +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/trips/' + tripID);
                setStudents(result.data.students);
                setName(result.data.name);
                setType(result.data.type);
                
                if(result.data.status === 'completed')
                {
                    setStartedDate(result.data.startedAt);
                    setFinishedDate(result.data.finishedAt);
                }

                setDriver(result.data.driver.name);

                const drive = await api.get(`/employees/getByUID/${result.data.driver.driverID}`);
                setDriverID(drive.data._id);

                const insti = await api.get(`/institutes/${result.data.instituteID}`);
                setInstitute(insti.data.name);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };
         
        fetchData();

    }, [authInfo, tripID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("trip_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("trip_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                
                    <IonRow className="ion-no-padding ion-margin-top">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className={(type === 'drop') ? "action-card-b" : "action-card-c"}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="2" className='mt-05'>
                                                <IonIcon className='second-icon'
                                                    icon={busOutline}
                                                    color={type === 'drop' ? 'secondary' : 'primary'} />
                                                </IonCol>
                                                <IonCol size="10" className="ion-text-right">
                                                    <IonText className='action-text'>{name} ({type === 'drop' ? t("drop") : t("pickup")} )<br/>
                                                    {t("driver")}: <Link className='card-link' to={`/app/employee/${driverID}`}>{driver}</Link>
                                                    </IonText>
                                                </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <VerticalTimeline layout='1-column' animate={false} lineColor="#086E7D">
                                <VerticalTimelineElement
                                    className="vertical-timeline-element"
                                    icon={ <IonIcon icon={business} className='timeline-icon' />}
                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                    <IonText className='timeline-para'>
                                        {t("started_from")} {institute} {t("on")}
                                        
                                    </IonText>
                                    {startedDate && (
                                    <IonText className='timeline-date'>
                                        <br/>
                                        {formatDate(startedDate)}
                                    </IonText>
                                    )}
                                    
                                </VerticalTimelineElement> 
                                {students.map(student => {
                                return (
                                
                                    <VerticalTimelineElement
                                        key={student.studentID}
                                        className="vertical-timeline-element"
                                        icon={ <IonIcon icon={person} className='timeline-icon' />}
                                        iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                        <IonText className='timeline-para'>
                                        
                                        <Link to={`/app/student/${student.studentID}`} className='text-capitalize'>{student.name}</Link>
                                        

                                            {student.status === 'picked' ? t("was_picked")+" "+t("on") : ""} 
                                            {student.status === 'dropped' ? t("was_dropped")+" "+t("on") : ""} 
                                            {student.status === 'absent' ? t("was_absent") : ""} 
                                            {(student.status === 'upcoming') && (type === 'pickup') ? t("was_picked") : ""} 
                                            {(student.status === 'upcoming') && (type === 'drop') ? t("was_dropped") : ""}
                                            
                                        </IonText>
                                        {student.doneAt && (
                                        <IonText className='timeline-date'>
                                            <br/>
                                            {formatDate(student.doneAt)}
                                        </IonText>
                                        )}
                                        
                                    </VerticalTimelineElement> 
                                )})
                                }

                                <VerticalTimelineElement
                                    className="vertical-timeline-element"
                                    icon={ <IonIcon icon={business} className='timeline-icon' />}
                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                    <IonText className='timeline-para'>
                                    {t("reached_at")} {institute} {t("on")}
                                        
                                    </IonText>
                                    {finishedDate && (
                                    <IonText className='timeline-date'>
                                        <br/>
                                        {formatDate(finishedDate)}
                                    </IonText>
                                    )}
                                    
                                </VerticalTimelineElement> 
                            
                            </VerticalTimeline>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default CompletedTrip;
