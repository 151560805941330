import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import translationEN from "../locales/en/translation.json";
import translationHI from "../locales/hi/translation.json";

// Importing translation files
const resources = {
    en: {
      translation: translationEN,
    },
    hi: {
      translation: translationHI,
    }
};


//i18N Initialization
i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    lng:"en", //default language
    resources,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: 'https://scapp-uploads.s3.ap-south-1.amazonaws.com/statics/locales/{{lng}}/{{ns}}.json',
      crossDomain: true,
    },
  });

  i18n.reloadResources().then(() => {
    i18n.emit('languageChanged', i18n.resolvedLanguage);
  });

export default i18n;