import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import React, { useEffect, useState } from 'react';
import { informationCircleOutline } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Application {
    _id: string,
    applicationType: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    instituteInfo: InstituteInfo,
    childInfo: ChildInfo,
    appointmentInfo?: AppointmentInfo
}

interface ClassInfo {
    classID: string,
    className: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface ParamTypes {
    applicationID: string
}

const Checkout: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {applicationID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [app, setApp] = useState < Application > ();
    const [logo, setLogo] = useState < string > ("");
    const [session, setSession] = useState < string > ("");

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const options: InAppBrowserOptions = {
        zoom: 'no',
        location: 'no',
        toolbar: 'no',
        hidden: 'no',
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes'
    };

    const handleSubmit = () => {

        if (!app || !app.amount) {
            setMessage(t("payment_initiate_error"));
            setIserror(true);
            return;
        }

        const userID = authInfo.user._id;
        const netPayable = app.amount+20;

        const doPay = () => {
            return new Promise((resolve, reject) => {
                api.post('/payments/new', { userID, applicationID, netPayable, 'type': 'application' }).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);

        doPay()
        .then((data: any) => {
            setShowLoading(false);

            let browser : InAppBrowserObject;

            // _blank for iphone, _self for others
            if (isPlatform('capacitor') && isPlatform('ios')) {
                browser = InAppBrowser.create(String(data.URLpay), '_blank', options);
            } else {
                browser = InAppBrowser.create(String(data.URLpay), '_self', options);
            }

            browser.on('loadstart').subscribe(event => {
                const res = event.url.split("/"); 
                if(res[5] === 'success' || res[5] === 'failed') {
                    history.replace(`/app/checkout/${res[5]}/${res[6]}`);
                    browser.close();
                }
            });

            browser.on('loaderror').subscribe(() => {
                history.replace(`/app/checkout/failed/${data.paymentID}`);
                browser.close();
            });

            browser.on('exit').subscribe(() => {
                if(window.location.pathname === `/app/application/${applicationID}`) {
                    history.replace(`/app/checkout/failed/${data.paymentID}`);
                }
            });

        })
        .catch(error => {
            //
            setShowLoading(false);
        });
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/applications/${applicationID}`);
                setApp(result.data);

                if (result.data.applicationType === 'admission')
                {
                    const school = await api.get(`/institutes/${result.data.instituteInfo.instituteID}`);
                    if(school.data.logo)
                    {
                        setLogo(school.data.logo);
                    }
                    if(school.data.admnSession)
                    {
                        setSession(school.data.admnSession.sessionName);
                    }
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, applicationID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("checkout")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("checkout")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>
                    
                    {app && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="ion-margin-vertical class-card">

                                <IonCardContent className="ion-no-padding">
                                        <IonRow>
                                            <IonCol size="9">
                                                <IonRow className="pb-08">
                                                    <IonCol>
                                                        <p><span className="class-name" >{app.instituteInfo.instituteName}</span></p>
                                                        
                                                        <p><span className="class-info">{app.classInfo.className} {(session !== "") && `(${session})`}</span></p>
                                                        {(app.applicationType === 'workshop' && app.appointmentInfo) && (
                                                        <p><span className="class-info">{t("scheduled_on")}: {formatDated(app.appointmentInfo.dated)}</span><br/>
                                                        <span className="class-info">{t("duration")}: {app.appointmentInfo.info}</span>
                                                        </p>)}
                                                        <br/>
                                                        <p><span className="class-info-2">{t("applying_for")} {app.childInfo.childName}</span></p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size="3" className="ion-text-center">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                {(app.applicationType === 'admission') && (logo !== "") ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${logo}`} alt={app.instituteInfo.instituteName} width="60" />)
                                                    : (<img src={`assets/images/logo-trans.png`} alt="Logo" width="60" />)
                                                }
                                               
                                                </div>
                                            </div>        
                                        </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>
                    )}

                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("amount_breakup")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow className='border-top-sec ion-padding-horizontal pt-08'>
                                        <IonCol size="8">
                                        {t("form_fee")}
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                        &#8377; {app && app.amount}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding-horizontal pb-08'>
                                        <IonCol size="8">
                                        {t("platform_fee")}
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                        &#8377; 20
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding-horizontal mb-10 mt-10'>
                                        <IonCol size="8">
                                        {t("total_amount")}
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                        &#8377; {app && app.amount+20}
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            {app && app.applicationType === 'admission' && (
                                                <p>
                                                    {t("checkout_info_1")}
                                                    {t("checkout_info_2")}
                                                    {t("checkout_info_3")}
                                                </p>
                                            )}
                                            {app && app.applicationType === 'workshop' && (
                                                <p>
                                                    {t("checkout_info_4")}
                                                </p>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="4" className='ion-text-center'>
                                {app && (
                                <>
                                    <p className='checkout-label'>
                                    {t("total_amount")}
                                    </p>
                                    <p className='checkout-amt'>
                                    &#8377; {app.amount+20}
                                    </p>
                                    
                                </>
                                )}

                        </IonCol>
                        <IonCol size="8" className="ion-text-right">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("make_payment")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default Checkout;
