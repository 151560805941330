import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline, arrowUndoCircle, attach, checkmarkCircle, receipt } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    student?: Stud,
    vendor?: Vendor,
    vehicle?: Vehicle,
    filename?: string,
    receiptID?: string,
    paymentID?: string,
    reversed?: boolean,
    validated?: boolean,
    dated: Date
}

interface Stud  {
    studentID: string,
    name: string
}

interface Vendor  {
    vendorID: string,
    vendorName: string
}

interface Vehicle  {
    vehicleID: string,
    name: string
}

const Transactions: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [debits, setDebits] = useState < Transaction[] > ([]);
    const [credits, setCredits] = useState < Transaction[] > ([]);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("one");
    const [balance, setBalance] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [tranID, setTranID] = useState < string > ("");

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/transactions/getByInstitute/${authInfo.user.instituteID}`, {duration, toDOB, fromDOB});
                setDebits(result.data.debits);
                setCredits(result.data.credits);
                setBalance(result.data.total);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }

    const handleReverse = () => {
        setShowLoading(true);
        api.get(`/transactions/reverse/${tranID}/${authInfo.user._id}`)
        .then(res => {
            if(res.data)
            {
                if (res.data.amount < 0)
                {
                    let subs = [...debits];
                    const index = subs.findIndex((val) => val._id === tranID);
                    if (index > -1) subs[index] = res.data;
                    setDebits(subs);
                }
                else
                {
                    let subs = [...credits];
                    const index = subs.findIndex((val) => val._id === tranID);
                    if (index > -1) subs[index] = res.data;
                    setCredits(subs);
                }
            }
        
        })
        .catch((error: any) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleValidate = () => {
        setShowLoading(true);
        api.put('/transactions/'+tranID, { validated: true })
        .then(res => {
            if(res.data)
            {
                if (res.data.amount < 0)
                {
                    let subs = [...debits];
                    const index = subs.findIndex((val) => val._id === tranID);
                    if (index > -1) subs[index] = res.data;
                    setDebits(subs);
                }
                else
                {
                    let subs = [...credits];
                    const index = subs.findIndex((val) => val._id === tranID);
                    if (index > -1) subs[index] = res.data;
                    setCredits(subs);
                }
            }
        
        })
        .catch((error: any) => {
           //
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {
       
        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.post(`/transactions/getByInstitute/${authInfo.user.instituteID}`, {duration: "one", toDOB: "", fromDOB: ""});
                setDebits(result.data.debits);
                setCredits(result.data.credits);
                setBalance(result.data.total);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();


    }, [authInfo.user.instituteID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_transactions")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_transactions")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_reverse")}
                    buttons={[
                        {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: t("yes"),
                            handler: () => {
                                handleReverse();
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert1}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert1(false)}
                    header={t("confirm")}
                    message={t("confirm_validate")}
                    buttons={[
                        {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: t("yes"),
                            handler: () => {
                                handleValidate();
                            }
                        }
                    ]}
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                        
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_duration")}
                                            </IonLabel>
                                            <IonSelect value={duration}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("tran_duration")}
                                                onIonChange={
                                                    (e) => setDuration(e.detail.value)
                                                }>
                                                <IonSelectOption value="one">{t("last_month")}</IonSelectOption>
                                                <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                                <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                                <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                                <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                                <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_transactions")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>

                    <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("credit")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                    {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                    {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                    {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                    {t("amount")}
                                    </IonCol>
                                   
                                </IonRow>

                                {(credits.length > 0) ? (credits.map((credit, i) => {
                                    return (
                                        <IonRow key={credit._id} className={(credit.validated) ? 'row-table-success ion-padding-horizontal' : (credit.reversed) ? 'row-table-danger ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            
                                                <IonCol size="1" className='table-field action-button' >
                                                    {i+1}.
                                                </IonCol>
                                                <IonCol size="3" className='table-field'>
                                                    <IonRow>
                                                        <IonCol>
                                                            {formatDated(credit.dated)}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='mt-10'>
                                                        <IonCol>
                                                            {credit.receiptID && (
                                                                <IonIcon 
                                                                    icon={receipt}
                                                                    color="dark"
                                                                    className='table-icon'
                                                                    onClick={
                                                                        () => history.push(`/app/receipt/${credit._id}`)
                                                                    }
                                                                />
                                                            )}
                                                           
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size="5" className='table-field'>
                                                    <IonRow>
                                                        <IonCol>
                                                            {credit.student && (<IonText className='text-capitalize'><Link to={`/app/student/ledger/${credit.student.studentID}`} className='link-dark'>{credit.student.name}</Link> - </IonText>)} 
                                                            {credit.remarks}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size="3" className='table-field'>
                                                    <IonRow>
                                                        <IonCol className='ion-text-right'>
                                                        {credit.amount}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='mt-10'>
                                                        <IonCol size="6">
                                                        {!credit.validated && !credit.reversed && (authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                                                <IonIcon 
                                                                    icon={checkmarkCircle}
                                                                    color="success"
                                                                    className='table-icon'
                                                                    onClick={
                                                                        () => {
                                                                            setTranID(credit._id);
                                                                            setShowAlert1(true);
                                                                        }
                                                                    }
                                                                />
                                                            )}
                                                        </IonCol>
                                                        <IonCol size="6" className='ion-text-right'>
                                                        
                                                        {!credit.validated && !credit.reversed && (
                                                                <IonIcon 
                                                                    icon={arrowUndoCircle}
                                                                    color="danger"
                                                                    className='table-icon'
                                                                    onClick={
                                                                        () => {
                                                                            setTranID(credit._id);
                                                                            setShowAlert(true);
                                                                        }
                                                                    }
                                                                />
                                                            )}
                                                           {credit.validated && (<IonText className='h6'>{t("validated")}</IonText>)}
                                                           {credit.reversed && (<IonText className='h6'>{t("reversed")}</IonText>)}
                                                           
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                    
                                                </IonCol>
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_credit")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 
                            </div>
                        </IonCol>

                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("debit")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                    {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                    {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                    {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                    {t("amount")}
                                    </IonCol>
                                   
                                </IonRow>
                               

                                {(debits.length > 0) ? (debits.map((debit, i) => {
                                    return (
                                        <IonRow key={debit._id} className={(debit.validated) ? 'row-table-success ion-padding-horizontal' : (debit.reversed) ? 'row-table-danger ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            
                                                <IonCol size="1" className='table-field action-button' >
                                                    {i+1}.
                                                    
                                                </IonCol>
                                                <IonCol size="3" className='table-field'>
                                                    <IonRow>
                                                        <IonCol>
                                                            {formatDated(debit.dated)}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='mt-10'>
                                                        <IonCol>
                                                            {debit.filename && (
                                                                <IonIcon 
                                                                    icon={attach}
                                                                    color="dark"
                                                                    className='table-icon'
                                                                    onClick={
                                                                        () => history.push(`/app/bill/${debit._id}`)
                                                                    }
                                                                />
                                                            )}
                                                           
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size="5" className='table-field'>
                                                    {debit.vendor && debit.vendor.vendorID !== "none" && (<IonText className='text-capitalize'><Link to={`/app/vendor/ledger/${debit.vendor.vendorID}`} className='link-dark'>{debit.vendor.vendorName}</Link> - </IonText>)} 
                                                    {debit.vehicle && (<IonText className='text-capitalize'><Link to={`/app/vehicle/expenses/${debit.vehicle.vehicleID}`} className='link-dark'>{debit.vehicle.name}</Link> - </IonText>)} 
                                                    {debit.remarks}
                                                </IonCol>
                                                <IonCol size="3" className='table-field ion-text-right'>
                                                    <IonRow>
                                                        <IonCol>
                                                        {Math.abs(debit.amount)}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='mt-10'>
                                                        <IonCol size="6">
                                                            {!debit.validated && !debit.reversed && (authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                                                    <IonIcon 
                                                                        icon={checkmarkCircle}
                                                                        color="success"
                                                                        className='table-icon'
                                                                        onClick={
                                                                            () => {
                                                                                setTranID(debit._id);
                                                                                setShowAlert1(true);
                                                                            }
                                                                        }
                                                                    />
                                                                )}
                                                            </IonCol>
                                                            <IonCol size="6" className='ion-text-right'>
                                                            {!debit.validated && !debit.reversed && (
                                                                <IonIcon 
                                                                    icon={arrowUndoCircle}
                                                                    color="danger"
                                                                    className='table-icon'
                                                                    onClick={
                                                                        () => {
                                                                            setTranID(debit._id);
                                                                            setShowAlert(true);
                                                                        }
                                                                    }
                                                                />
                                                            )}
                                                            {debit.validated && (<IonText className='h6'>{t("validated")}</IonText>)}
                                                            {debit.reversed && (<IonText className='h6'>{t("reversed")}</IonText>)}
                                                        </IonCol>
                                                    </IonRow>
                                                   

                                                   
                                                           
                                                </IonCol>
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_debit")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 
                            </div>
                        </IonCol>
                        
                        
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    <span>{t("page_balance")} {balance}</span>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-md'>
                        
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/dues/view`)
                                            
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_dues")}
                                    </IonButton>

                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/collection`)
                                            
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("collect_fees")}
                                    </IonButton>

                                    {/*<IonButton 
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("export_excel")}</IonButton>*/}

                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Transactions;