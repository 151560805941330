import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { addCircleOutline, alertCircleOutline, calendarNumber, construct } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    classID: string
}

interface TimeTable {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    isActive: boolean
}
interface ClassInfo {
    classID: string,
    className: string
}


const ClassTimeTables: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [tables, setTables] = useState < TimeTable[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [year, setYear] = useState < string > ("");
    const [current, setCurrent] = useState < string > ("");
    const [instituteID, setInstituteID] = useState < string > ("");

    const handleSubmit = () => {
  
        const upTable = (tableID: string) => {
          return new Promise((resolve, reject) => {
            api.put('/timeTables/'+tableID, { 'setCurrent': 'yes' }).then(res => {
              return resolve(res.data.name);
            }).catch(err => reject(err));
          });
        }
    
        if (current !== "")
        {
            setShowLoading(true);
            upTable(current)
            .then(data => {
    
                setPass(data+t("timetable_active"));
                setIspass(true);
            
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        
      }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/classes/' + classID);
                setInstituteID(result.data.instituteID);
                setName(result.data.name);
                setYear(result.data.year);

                const ctt = await api.get(`/timeTables/getByClass/${classID}`);
                setTables(ctt.data);

                for(let i=0; i < ctt.data.length; i++)
                {
                    if(ctt.data[i].isCurrent)
                    {
                        setCurrent(ctt.data[i]._id);
                        break;
                    }
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/class/${classID}`} />
                    </IonButtons>
                   
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("class_timetables")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("class_timetables")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem color="secondary">
                                        
                                        <IonLabel position="stacked" className="input-label">
                                            {t("class_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                value={`${name} (${year})`}
                                                readonly={true}
                                                ></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow> 
                        
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    detail={true}
                                    onClick={() => history.push(`/app/${instituteID}/timetables/add/class/${classID}`) }
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("add_new_timetable")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>

                            <IonTitle className='list-title'>
                                {t("class_timetables")}
                            </IonTitle>
                               
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                          <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>
                                {(tables.length > 0) ? (tables.map((table, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={table._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            >
                                            <IonRadio slot="start" value={table._id} disabled={!table.isActive}></IonRadio>
                                            <IonIcon slot="start"
                                                icon={calendarNumber}
                                                color={
                                                    table.isActive ? 'success' : 'danger'
                                                }/>
                                            <IonLabel className='list-label'>
                                                <h3>{table.name} </h3>
                                            </IonLabel>
                                            <IonButtons slot="end">
                                                <IonButton onClick={
                                                        () => history.push(`/app/timetable/${table._id}`)
                                                    }>
                                                    <IonIcon 
                                                    icon={construct}
                                                    color="dark"
                                                    
                                                    />
                                                 </IonButton>
                                            </IonButtons> 
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_timetables")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } 
                                </IonRadioGroup>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("set_active")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ClassTimeTables;
