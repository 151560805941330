import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

const AddStudent: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [gender, setGender] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > ('2000-01-01T06:30:00.000+00:00');
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
   
    const formatDate = (value: string | string[]) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const handleSubmit = () => {

        const addChild = ( userID: string, name: string, gender: string, dob: string | string[]) => {
            return new Promise((resolve, reject) => {
                api.post('/admissionUsers/addChild', { userID, name, gender, dob }).then(res => {
        
                return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

    
        setShowLoading(true);
        addChild(authInfo.user._id, name, gender, selectedDate)
        .then((data: any) => {
        
            setPass(name+t("has_been_added"));
            setIspass(true);
            
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
    }
    

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/students"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_child")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_child")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/students`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("admn_child_info")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
 
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='list-title'>
                                {t("childs_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='none'
                                    button={false}>
                                    <IonLabel position="stacked" color="secondary">{t("student_name")}</IonLabel>
                                    <IonInput type="text"
                                        value={name}
                                        placeholder={t("student_name")}
                                        onIonInput={
                                            (e) => setName(e.detail.value!)
                                    }></IonInput>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='none'
                                    button={false}>
                                    <IonLabel position="stacked" color="secondary">{t("student_gender")}</IonLabel>
                                </IonItem>

                                <IonRadioGroup value={gender} onIonChange={
                                            (e) => setGender(e.detail.value!)
                                    }>
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonRadio value="boy"></IonRadio>
                                        <IonAvatar className="leaderboard-avatar">
                                            <img src={`assets/images/boy.png`} alt="Boy" width="60" />
                                        </IonAvatar>
                                        <IonLabel className='leaderboard-name'>
                                            <h3>{t("boy")}</h3>
                                            
                                        </IonLabel>
                                    </IonItem>
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonRadio value="girl"></IonRadio>
                                        <IonAvatar className="leaderboard-avatar">
                                            <img src={`assets/images/girl.png`} alt="Boy" width="60" />
                                        </IonAvatar>
                                        <IonLabel className='leaderboard-name'>
                                            <h3>{t("girl")}</h3>
                                            
                                        </IonLabel>
                                    </IonItem>

                                </IonRadioGroup>

                                <IonItem detail={false}
                                    lines='none'
                                    button={true}
                                    id="open-date-input"
                                    className='mt-15'>
                                        <IonLabel position="stacked" color="secondary">{t("student_dob")}</IonLabel>
                                    <IonInput value={formatDate(selectedDate)} readonly={true} />
                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                            <IonToolbar>
                                                <IonTitle>{t("student_dob")}</IonTitle>
                                                
                                            </IonToolbar>
                                            <IonDatetime
                                                cancelText={t("cancel")}
                                                doneText={t("ok")}
                                                value={selectedDate}
                                                presentation="date"
                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                showDefaultButtons={true}
                                                size="cover"
                                            />
                                        </IonContent>
                                        
                                    </IonModal>
                                </IonItem>

                              
                                
                            </IonList>
                            </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">{t("add_child")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default AddStudent;
