import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

interface Application {
    _id: string,
    applicationType: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    appointmentInfo?: AppointmentInfo,
    response?: string,
    instituteInfo: InstituteInfo,
    childInfo: ChildInfo,
    createdAt: Date
}

interface ClassInfo {
    classID: string,
    className: string
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

const Dashboard: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [appoints, setAppoints] = useState < Application[] > ([]);
    const [pendingApps, setPendingApps] = useState < Application[] > ([]);
    const [paidApps, setPaidApps] = useState < Application[] > ([]);
   
    const [refreshed, setRefreshed] = useState < number > (0);

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const appoint = await api.get(`/applications/getAppointmentHome/${authInfo.user._id}`);
                setAppoints(appoint.data);

                const unpaid = await api.get(`/applications/getUnpaidHome/${authInfo.user._id}`);
                setPendingApps(unpaid.data);

                const paid = await api.get(`/applications/getWorkshopsHome/${authInfo.user._id}`);
                setPaidApps(paid.data);

            } catch (error: any) {  
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();
        
    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("dashboard")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("dashboard")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                   
                   <IonRow className='mt-30 mb-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="4">
                                    <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/students`}>
                                        <IonCardContent className="ion-text-center ion-padding">
                                            <IonRow>
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("manage_children")} src="assets/images/children.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_children")}
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="4">
                                    <IonCard className="dash-card-1 ion-no-margin" routerLink={`/app/shortlisted`}>
                                        <IonCardContent className="ion-padding">
                                            <IonGrid className="ion-text-center ion-no-padding">
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("shortlisted_schools")} src="assets/images/school.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="dash-title-1 ion-no-padding">
                                                    {t("shortlisted_schools")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="4">
                                    <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/payments`}>
                                        <IonCardContent className="ion-padding">
                                            <IonGrid className="ion-text-center ion-no-padding">
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("payments_history")} src="assets/images/payments.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="dash-title-1 ion-no-padding">
                                                    {t("payments_history")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="4">
                                    <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/admission/inquiries`}>
                                        <IonCardContent className="ion-padding">
                                            <IonGrid className="ion-text-center ion-no-padding">
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("my_admn_inquiries")} src="assets/images/inquiries.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="dash-title-1 ion-no-padding">
                                                    {t("my_admn_inquiries")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="4">
                                    <IonCard className="dash-card-3 ion-no-margin" routerLink={`/app/workshops/attended`}>
                                        <IonCardContent className="ion-padding">
                                            <IonGrid className="ion-text-center ion-no-padding">
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("attended_workshops")} src="assets/images/events.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="dash-title-1 ion-no-padding">
                                                    {t("attended_workshops")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="4">
                                    <IonCard className="dash-card-6 ion-no-margin" routerLink={`/app/applications/submitted`}>
                                        <IonCardContent className="ion-padding">
                                            <IonGrid className="ion-text-center ion-no-padding">
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("submitted_apps")} src="assets/images/subjects.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className="dash-title-1 ion-no-padding">
                                                    {t("submitted_apps")}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    {(pendingApps.length > 0) && (
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_cart")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push('/app/applications/pending')}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>

                                {pendingApps.map((app) => {
                                return (
                                        <IonCard key={app._id} className={(app.applicationType === 'workshop') ? 'ion-margin-vertical planner-card-3 ion-padding' : 'ion-margin-vertical class-card'} >

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol size="11">
                                                                    <p><span className="class-name" >{app.instituteInfo.instituteName}</span></p>
                                                                    
                                                                    <p><span className="class-info">{app.classInfo.className} &#8226; {app.childInfo.childName}</span></p>
                                                                    <p><span className="class-info-2"> {formatDate(app.createdAt)}</span></p>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <p><IonIcon icon={informationCircleOutline} size="small" color="dark"
                                                                    onClick={() => {
                                                                        history.push(`/app/form/${app._id}`);
                                                                    }}/>
                                                                    </p>
                                                                    
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow className="pr-16">
                                                                <IonCol size="8" className='mt-05'>
                                                                    <p><span className="class-info-3">{t("app_fees")} {app.amount}/-</span></p>
                                                                </IonCol>
                                                                <IonCol size="4" className="ion-text-right">
                                                                    <IonButton color="primary" size="small" onClick={() => history.push(`/app/application/${app._id}`)}>{t("checkout")}</IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                        </IonCol>
                                    </IonRow>              
                                    
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                    )}


                    {(appoints.length > 0) && (
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("all_appointments")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push('/app/appointments')}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>

                                {appoints.map((app) => {
                                return (
                                        <IonCard key={app._id} className="ion-margin-vertical class-card">

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol size="11">
                                                                    <p><span className="class-name" >{app.instituteInfo.instituteName}</span></p>
                                                                    
                                                                    <p><span className="class-info">{app.classInfo.className} &#8226; {app.childInfo.childName}</span></p>
                                                                    <p><span className="class-info-2"> {formatDate(app.createdAt)}</span></p>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <p><IonIcon icon={informationCircleOutline} size="small" color="dark"
                                                                    onClick={() => {
                                                                        history.push(`/app/form/${app._id}`);
                                                                    }}/>
                                                                    </p>
                                                                    
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-3">{t("appointment_date")} {formatDated(app.appointmentInfo!.dated)}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-4">{app.appointmentInfo!.info}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                        </IonCol>
                                    </IonRow>              
                                    
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                    )}

                    
                    {(paidApps.length > 0) && (
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_workshops")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push('/app/workshops/upcoming')}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                {paidApps.map((app) => {
                                return (
                                        <IonCard key={app._id} className="ion-margin-vertical planner-card-3 ion-padding">

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol size="11">
                                                                <p><span className="class-name" >{app.instituteInfo.instituteName}</span></p>
                                                                    
                                                                    <p><span className="class-info">{app.classInfo.className} &#8226; {app.childInfo.childName}</span></p>
                                                                    <p><span className="class-info-2"> {formatDate(app.createdAt)}</span></p>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <p><IonIcon icon={informationCircleOutline} size="small" color="dark"
                                                                    onClick={() => {
                                                                        history.push(`/app/form/${app._id}`);
                                                                    }}/>
                                                                    </p>
                                                                    
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-3">{t("project_start")}: {app.appointmentInfo && formatDated(app.appointmentInfo.dated)}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-4">{t("duration")}: {app.appointmentInfo && app.appointmentInfo.info}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                    )}
                 
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Dashboard;
