import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import FileOpener from "../../../components/FileOpener";
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    billID: string
}

const BillDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {billID} = useParams < ParamTypes > ();
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [tags, setTags] = useState < string[] > ([]);
    const [fileName, setFileName] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");
    const [reversed, setReversed] = useState < boolean > (false);

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadBill = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/bills/' + fileName;

        if (isPlatform('capacitor'))
        {
        try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            setShowDownloading(true);
                            try {
                                const savedFile = await Filesystem.downloadFile({
                                    path: fileName,
                                    url,
                                    directory: Directory.External,
                                });
                                
                                if(savedFile.path)
                                {
                                    await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                                
                            }
                            catch(error: any) {
                                setMessage(t("download_error"));  
                                setIserror(true);
                            } finally {
                                setShowDownloading(false);
                            }
                        }
                    });
                }
                else {
                    setShowDownloading(true);
                    try {
                        const savedFile = await Filesystem.downloadFile({
                            path: fileName,
                            url,
                            directory: Directory.External,
                        });
                        
                        if(savedFile.path)
                        {
                            await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                        
                    }
                    catch(error: any) {
                        setMessage(t("download_error"));  
                        setIserror(true);
                    } finally {
                        setShowDownloading(false);
                    }
                }
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            }
        }
       
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/transactions/' + billID);

                setRemarks(result.data.remarks);
                setMode(result.data.mode);
                setAmount(Math.abs(result.data.amount));
                setTags(result.data.tags);

                setFileName(result.data.filename);
                const myArray = result.data.filename.split(".");
                setFileExt(myArray[1]);

                setSelectedDate(result.data.dated);
                setReversed(result.data.reversed);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, billID]);

    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color={reversed ? "danger" : "secondary"}>{reversed ? t("reversed") : t("manage_bill")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_bill")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                       () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">

                {fileName && (
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>{t("bill_preview")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {(fileExt) && ((fileExt === 'jpeg') || (fileExt === 'png')) &&
                                        (
                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`, remarks)}
                                            src={`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`} 
                                            alt={t("bill_preview")}
                                            width="320" />
                                       )
                                    }
                                    {(fileExt) && (fileExt === 'pdf') &&
                                        (<div className="pdf-preview">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    <img onClick={() => downloadBill(fileName)} src="assets/images/pdf-icon.png" alt="PDF" />
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}  
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("bill_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_date")}
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_remarks")}
                                            </IonLabel>
                                            <IonInput value={remarks} 
                                                placeholder={t("tran_remarks")}
                                                type="text"
                                                className="input-field" 
                                                readonly={true}
                                            />
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_mode")}
                                            </IonLabel>
                                            <IonInput value={mode}
                                                placeholder={t("tran_mode")}
                                                type="text"
                                                className="input-field" 
                                                readonly={true}
                                            />
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_amount")}
                                            </IonLabel>
                                        <IonInput type="number"
                                            value={amount}
                                            placeholder={t("tran_amount")}
                                            className="input-field" 
                                            readonly={true}></IonInput>
                                    </IonItem>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_tags")}
                                            </IonLabel>
                                        <IonInput type="text"
                                            placeholder={t("tran_tags")}
                                            className="input-field" 
                                            value={tags.join(' ')}
                                            readonly={true}></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
            {fileName && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                                {isPlatform("capacitor") && (
                                    <IonButton color="dark" onClick={() => downloadBill(fileName)}>{t("download_bill")}</IonButton> 
                                )}
                                {!isPlatform("capacitor") && (
                                    <a href={`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`} target="_blank" rel="noreferrer" download className='font-none'> 
                                        <IonButton color="dark">{t("download_bill")}</IonButton> 
                                    </a>
                                )}

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}

        </IonPage>
    );
};

export default BillDetail;