import {
    IonAlert,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import Chart from "react-apexcharts";
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { chatbubbles, chevronForward, create, notifications, sparkles, time } from 'ionicons/icons';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { SendIntent } from "send-intent";
import {useAuth} from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import CircularsSwiper from '../../components/ui/CircularsSwiper';
import useApiService from '../../components/ApiService';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Chat {
    _id: string,
    title: string,
    type: string,
    status: string,
    employee?: ChatEmployee,
    employees?: string[],
    adminEmployee?: string,
    taskInfo?: TaskInfo,
    createdAt: Date,
    updatedAt: Date
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

const AccountantHome: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [payment, setPayment] = useState < boolean > (false);
    const [sharedFile, setSharedFile] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [circulars, setCirculars]= useState < Circular[] > ([]);
    const [chats, setChats] = useState < Chat[] > ([]);
    const [tasks, setTasks] = useState < Chat[] > ([]);
    const [categories, setCategories] = useState < string[] > (['First', 'Second', 'Third']);
    const [incomeSeries, setIncomeSeries] = useState < number[] > ([0, 0, 0]);
    const [expenseSeries, setExpenseSeries] = useState < number[] > ([0, 0, 0]);
    const [income, setIncome] = useState < number > (0);
    const [expense, setExpense] = useState < number > (0);
    const [series1, setSeries1] = useState < number[] > ([0, 0]);
    const [series2, setSeries2] = useState < number[] > ([0, 0]);
    const [isSuspended, setIsSuspended] = useState < boolean > (false);
    const [attendance, setAttendance]= useState < Attendance > ();
    const [unread, setUnread] = useState < number > (0);
    const [events, setEvents] = useState < Event[] > ([]);

    const series =  [{
        name: 'Income',
        data: incomeSeries
        }, {
        name: 'Expenses',
        data: expenseSeries
    }];

    const options =  {
        chart: {
            toolbar: {
              show: false
            }
        },
        colors : ['#2dd36f', '#eb445a'],
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: 'Rs. (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return "Rs. " + val + " thousands"
                }
            },
            theme: 'dark'
        }
    };

    const options1 = {
        colors : ['#FFC900', '#086E7D'],
        labels: ['Cash', 'Online'],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom' as 'bottom',
            labels: {
                colors: '#777'
            },
          }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };
   
    useEffect(() => {

        const upUser = async (push: Token) => {
            try {
                const response = await api.put(`/users/${authInfo.user._id}`, { push: push.value });
                return response.data;
            } catch (error) {
                throw error;
            }
        };

        const register = async () => {
            try {
                // Register with Apple / Google to receive push notifications
                await PushNotifications.register();
    
                // On success, we should be able to receive notifications
                await PushNotifications.addListener('registration', async (token: Token) => {
                    if (!authInfo.user.push || authInfo.user.push !== token.value) {
                        await upUser(token);
                    }
                });
    
                // Handle registration errors
                await PushNotifications.addListener('registrationError', () => {
                    setMessage(t("push_error"));
                    setIserror(true);
                });
            } catch (err) {
                console.error('Error in register function:', err);
            }
        };

        const fetchData = async () => {
            try {
                
                setPayment(authInfo.institute.payment);

                if (authInfo.institute.payment)
                {
                    const result = await api.get('/transactions/reports/' + authInfo.user.instituteID);

                    setCategories(result.data.categories);
                    setIncomeSeries(result.data.incomeSeries);
                    setExpenseSeries(result.data.expenseSeries);
                    setIncome(result.data.income);
                    setSeries1(result.data.series1);
                    setExpense(result.data.expense);
                    setSeries2(result.data.series2);

                     // Fetch necessary data
                     const [eve, circ, chat, task, entry, count] = await Promise.all([
                        api.get(`/feeds/getEventsByInstitute/${authInfo.user.instituteID}`),
                        api.get(`/circulars/getSomeByRole/${authInfo.user.instituteID}/${authInfo.user.role}`),
                        api.get(`/staffChats/getOneByEmployee/${authInfo.user._id}`),
                        api.get(`/staffChats/getHomeByEmployee/${authInfo.user._id}`),
                        api.get(`/staffAttends/getOneByEmployee/${authInfo.user._id}`),
                        api.get(`/notifications/getCountUser/${authInfo.user._id}`)
                    ]);

                    setEvents(eve.data);
                    setCirculars(circ.data);
                    setChats(chat.data);
                    setTasks(task.data);
                    setAttendance(entry.data);
                    setUnread(count.data);
                }
                else
                {
                    setIsSuspended(true);
                }

            } catch (error: any) {
                //
            }
            
        };

        const checkIntent = async () => {
            try {
                const result = await SendIntent.checkSendIntentReceived();
                if (result && result.url) {
                    setSharedFile(encodeURIComponent(result.url));
                }
            } catch (err) {
                console.error('Error checking intent:', err);
            }
        };

        const fetchVersion = async () => {
            setShowDownloading(true);
            try {
                const versionRes = await api.get(`/version-check`);
                const currentVersion = parseInt(process.env.REACT_APP_VERSION!);
    
                if (parseInt(versionRes.data) > currentVersion) {
                    setIsold(true);
                } else {

                    if (isPlatform('android') && isPlatform('capacitor')) {
                        await checkIntent();
                    }
                    await fetchData();
                
                    if (isPlatform('capacitor')) {
                        const permissionRes = await PushNotifications.checkPermissions();
                        if (permissionRes.receive !== 'granted') {
                            const requestRes = await PushNotifications.requestPermissions();
                            if (requestRes.receive === 'granted') {
                                await register();
                            }
                        } else {
                            await register();
                        }
                    }
                }

                
            } catch (error: any) {  
                //
            } finally {
                setShowDownloading(false);
            }
        };

        fetchVersion();

    }, [authInfo, refreshed]);


    if(sharedFile && sharedFile !== "")
    {
       return (<Redirect to={`/app/bills/add/${sharedFile}`} />);
    }
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                    </IonButtons>
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isSuspended}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/home");
                    }}
                    header={t("suspended")}
                    message={t("account_suspended")}
                    buttons={[`${t("logout")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>

                {(isPlatform("capacitor")) && payment && (
                    <IonRow className='mt-30'>
                        <IonCol size="4">
                            <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/dues`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_dues")} src="assets/images/receipts.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_dues")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/fees`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("fee_head_struct")} src="assets/images/feehead.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("fee_head_struct")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        <IonCol size="4">
                            <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/cash`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("petty_cash")} src="assets/images/payments.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("petty_cash")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                )}

                {(attendance) && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("attendance_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    
                            <IonCard className={
                                    (attendance.status === 'in') ? 'success-card' : 'danger-card'
                                }>
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    (attendance.status === 'in') ? 'success' : 'danger'
                                                    }
                                                />
                                                <IonLabel className="recent-label ion-text-wrap">
                                                        
                                                        {(attendance.status === 'out') && (
                                                            <>
                                                                <h3>{t("your_out_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'in') && (
                                                            <>
                                                                <h3>{t("your_in_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        
                                                </IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        
                        {(attendance.status === 'in') && (
                            <IonButton expand="block" color="dark" className='ion-margin-horizontal' onClick={() => history.push('/app/user/attendance')}>{t("mark_exit")}</IonButton>
                        )}
                        </IonCol>
                    </IonRow>
                </>
                )}

                {circulars && (circulars.length > 0) && 
                (<CircularsSwiper circulars={circulars} />)}

                    <IonRow className="ion-padding-vertical max-lg">

                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_income")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-in">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{t("rs")} {income}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='6'>
                                                        <Chart options={options1} series={series1} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard className='action-card-d'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_expense")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-out">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>{t("rs")} {expense}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='6'>
                                                        <Chart options={options1} series={series2} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("last_3_months")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <Chart options={options} series={series} type="bar" height={350} />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-vertical">
                        <IonCol className='ion-no-padding'>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{768: 1, 992: 2, 1200: 3}}
                            >
                                <Masonry>

                        {(events.length > 0) && (
                            <IonCard className="ion-margin">
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol>
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("upcoming_events")}</IonCardSubtitle>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>

                                {events.map((event) => {
                                return (
                                    <IonCard className="planner-card-2" key={event._id}>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>            
                                                    <IonItem lines="none"
                                                        button={false}
                                                        detail={false}
                                                        >
                                                            <IonIcon icon={sparkles}
                                                                slot="start"
                                                                color="primary" />
                                                            <IonLabel className="action-title-one">
                                                                <h3>{event.title}</h3>
                                                                <p>{formatDate(event.calendarDate)}</p>
                                                                </IonLabel>
                                                        
                                                        </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>            
                                                    <IonText>{event.description}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                        );
                                    })
                                }
                                        </IonCol>
                                    </IonRow>     
                               
                                </IonCardContent>
                            </IonCard>
                    )}

                            {(tasks.length > 0) && (
                            <IonCard className='ion-margin'>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_tasks")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="danger" size="small" className='pr-16' onClick={() => history.push(`/app/user/tasks`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>

                                            <IonList className='ion-no-padding'>
                                                {(tasks.map((chat, i) => {
                                                        return (
                                                            <IonItem detail={true}
                                                            detailIcon={chevronForward}
                                                            lines='full'
                                                            button={true}
                                                            key={chat._id}
                                                            onClick={
                                                                () => history.push(`/app/user/task/${chat._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={create}
                                                                color='danger'/>
                                                            <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                                <h3>{chat.title}
                                                                <IonBadge className='ml-05' color={(chat.taskInfo!.priority === "high") ? "danger" : (chat.taskInfo!.priority === "medium") ? "warning" : "medium"}>
                                                                    {t(chat.taskInfo!.priority)}
                                                                </IonBadge>
                                                                <br/> 
                                                                {t(chat.status)} <br/>
                                                                {formatDate(chat.taskInfo!.startDate)} {t("to")} {formatDate(chat.taskInfo!.endDate)}
                                                                </h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                        )
                                                    }))
                                                    } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>     
                                
                                    
                                </IonCardContent>
                            </IonCard>
                            )}

                            {(chats.length > 0) && (
                            <IonCard className='ion-margin'>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="8">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("my_chats")}</IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="4" className='ion-text-right'>
                                            <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/app/user/chats`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>

                                            <IonList className='ion-no-padding'>
                                                {(chats.map((chat, i) => {
                                                        return (
                                                            <IonItem detail={true}
                                                            detailIcon={chevronForward}
                                                            lines='full'
                                                            button={true}
                                                            key={chat._id}
                                                            onClick={
                                                                () => history.push(`/app/user/chat/${chat._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={chatbubbles}
                                                                color='primary'/>
                                                            <IonLabel className="list-label text-capitalize">
                                                                <h3>{chat.title}<br/> {chat.employee ? chat.employee.name : (chat.employees?.length === 1) ? chat.adminEmployee : `${t("employees")}: ${chat.employees!.length}`} ({chat.type})<br/>{formatDate(chat.createdAt)}</h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                        )
                                                    }))
                                                    } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>     
                                
                                    
                                </IonCardContent>
                            </IonCard>

                            )}
                                </Masonry>
                            </ResponsiveMasonry>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default AccountantHome;