import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonLabel,
  IonIcon
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import TeacherHome from "../pages/Home/TeacherHome";
import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import Planner from "../pages/Teacher/Academics/Planner";
import StudentSubmissions from "../pages/Teacher/Academics/StudentSubmissions";
import TopicSubmissions from "../pages/Teacher/Academics/TopicSubmissions";
import SubmissionDetail from "../pages/Admin/Academic/Submission/SubmissionDetail";
import AssessmentDetail from "../pages/Admin/Academic/Submission/AssessmentDetail";

import Subjects from "../pages/Teacher/Academics/Subjects";
import Chapters from "../pages/Teacher/Academics/Chapters";
import Topics from "../pages/Teacher/Academics/Topics";
import Leaderboard from "../pages/Teacher/Academics/Leaderboard";

import Attendance from "../pages/Employee/Dashboard/Attendance";
import AttendStatus from "../pages/Teacher/Attendance/AttendStatus";

import EntryExit from "../pages/Admin/Attendance/EntryExit";
import StudentLog from "../pages/Admin/Attendance/StudentLog";
import Attend from "../pages/Admin/Attendance/Attend";
import AttendClass from "../pages/Admin/Attendance/AttendClass";
import LeaveApplications from "../pages/Admin/Attendance/LeaveApplications";
import ScanEntry from "../pages/Admin/Attendance/ScanEntry";
import ScanExit from "../pages/Admin/Attendance/ScanExit";
import ScanPresent from "../pages/Admin/Attendance/ScanPresent";

import Students from "../pages/Teacher/Student/Students";
import AddStudent from "../pages/Teacher/Student/AddStudent";
import AbsentStudents from "../pages/Teacher/Student/AbsentStudents";

import StudentDetail from "../pages/Admin/Student/StudentDetail";
import StudentLocation from "../pages/Admin/Student/StudentLocation";
import StudentAttendance from "../pages/Admin/Student/StudentAttendance";
import StudentProfile from "../pages/Admin/Student/StudentProfile";
import ParentProfile from "../pages/Admin/Student/ParentProfile";
import StudentGuardians from "../pages/Admin/Student/StudentGuardians";
import StudentIncidents from "../pages/Admin/Student/StudentIncidents";
import IncidentDetail from "../pages/Admin/Medical/IncidentDetail";

import Circulars from "../pages/Admin/Circular/Circulars";
import AddCircular from "../pages/Admin/Circular/AddCircular";
import CircularDetail from "../pages/Admin/Circular/CircularDetail";
import AddInquiry from "../pages/Admin/Inquiry/AddInquiry";
import PendingInquiries from "../pages/Admin/Inquiry/PendingInquiries";
import ProcessedInquiries from "../pages/Admin/Inquiry/ProcessedInquiries";
import StudentInquiries from "../pages/Admin/Inquiry/StudentInquiries";
import InquiryDetail from "../pages/Admin/Inquiry/InquiryDetail";

import MyTasks from "../pages/Employee/Task/MyTasks";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import UserNotifications from "../pages/User/UserNotifications";
import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";

import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

import { calendarNumber, chatbubbleEllipses, clipboard, home, newspaper } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useAuth } from './AuthContext';

const TeacherTabs: React.FC = () => {
  const {t} = useTranslation();
  const {authInfo} = useAuth()!;
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={TeacherHome} />
          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

          <Route path="/app/planner" exact={true} component={Planner} />
          <Route path="/app/teacher/submissions/student/:studentID?" exact={true} component={StudentSubmissions} />
          <Route path="/app/teacher/submissions/topic/:topicID?/:classID?" exact={true} component={TopicSubmissions} />
          <Route path="/app/submission/:submissionID" exact={true} component={SubmissionDetail} />
          <Route path="/app/assessment/:evaluationID" exact={true} component={AssessmentDetail} />

          <Route path="/app/subjects" exact={true} component={Subjects} />
          <Route path="/app/chapters/:subjectID" exact={true} component={Chapters} />
          <Route path="/app/topics/:chapterID" exact={true} component={Topics} />
          <Route path="/app/leaderboard" exact={true} component={Leaderboard} />

          <Route path="/app/attendance" exact={true} component={Attendance} />
          <Route path="/app/attendance/status" exact={true} component={AttendStatus} />
          <Route path="/app/attend" exact={true} component={Attend} />
          <Route path="/app/attend/class/:classID?" exact={true} component={AttendClass} />
          <Route path="/app/attend/leave" exact={true} component={LeaveApplications} />
          <Route path="/app/entryexit" exact={true} component={EntryExit} />
          <Route path="/app/entry/log/:student?" exact={true} component={StudentLog} />

          <Route path="/app/scan/entry" exact={true} component={ScanEntry} />
          <Route path="/app/scan/exit" exact={true} component={ScanExit} />
          <Route path="/app/scan/present" exact={true} component={ScanPresent} />

          <Route path="/app/students" exact={true} component={Students} />
          <Route path="/app/students/teacher/absent" exact={true} component={AbsentStudents} />
          <Route path="/app/students/teacher/add" exact={true} component={AddStudent} />
          <Route path="/app/student/:studentID" exact={true} component={StudentDetail} />
          <Route path="/app/student/location/:studentID" exact={true} component={StudentLocation} />
          <Route path="/app/student/attendance/:studentID" exact={true} component={StudentAttendance} />
          <Route path="/app/student/profile/:studentID" exact={true} component={StudentProfile} />
          <Route path="/app/student/parent/:parentID" exact={true} component={ParentProfile} />
          <Route path="/app/student/guardians/:studentID" exact={true} component={StudentGuardians} />
          <Route path="/app/student/incidents/:studentID" exact={true} component={StudentIncidents} />
          <Route path="/app/incident/:incidentID" exact={true} component={IncidentDetail} />
          
          <Route path="/app/circulars" exact={true} component={Circulars} />
          <Route path="/app/circular/add" exact={true} component={AddCircular} />
          <Route path="/app/circular/:circularID" exact={true} component={CircularDetail} />
          <Route path="/app/inquiries/new/:studentID?" exact={true} component={AddInquiry} />
          <Route path="/app/inquiries/pending" exact={true} component={PendingInquiries} />
          <Route path="/app/inquiries/processed" exact={true} component={ProcessedInquiries} />
          <Route path="/app/inquiries/student/:studentID?" exact={true} component={StudentInquiries} />
          <Route path="/app/inquiry/:inquiryID" exact={true} component={InquiryDetail} />

          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:chatID" exact={true} component={ViewChat} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />

          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to="/app/home" />
          <Redirect from="*" to="/app"/>
        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bar">
        <IonTabButton tab="home" href="/app/home" className="tab-button" target="_blank">
          <IonIcon icon={home} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("home")}</IonLabel>
        </IonTabButton>

        {(authInfo.institute.task) ? 
        (<IonTabButton tab="reports" href="/app/user/reports" className="tab-button" target="_blank">
          <IonIcon icon={clipboard} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("daily_reports")}</IonLabel>
        </IonTabButton>) 
        : 
        ( <IonTabButton tab="news" href="/app/news" className="tab-button" target="_blank">
          <IonIcon icon={newspaper} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("circulars")}</IonLabel>
        </IonTabButton>)}

        <IonTabButton tab="chat" href="/app/inquiries/pending" className="tab-button" target="_blank">
          <IonIcon icon={chatbubbleEllipses} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("parent_inquiries")}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="attendance" href="/app/user/attendance" className="tab-button" target="_blank">
          <IonIcon icon={calendarNumber} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_attendance")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default TeacherTabs;
