import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

const Accounting: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [dues, setDues] = useState < number > (0);
    const [payments, setPayments] = useState < number > (0);
    const [expenses, setExpenses] = useState < number > (0);
            
    useEffect(() => {

        const fetchData = async () => {
            setShowDownloading(true);
            try {
                const stats = await api.get(`/institutes/accountStats/${authInfo.user.instituteID}`);
                const statsData = stats.data;

                setDues(statsData.dues);
                setPayments(statsData.payments);
                setExpenses(statsData.expenses);
            }
            catch (error: any) {
                //
            } finally {
                setShowDownloading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("accounting")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                     {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("accounting")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-lg">
                        <IonCol>
                            <IonRow className='dash-border-bottom'>
                                <IonCol sizeMd="6" size="12">

                                    <IonCard className="dashboard-card dash-bg-6">
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("account_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("account_overview")} src="assets/images/finance-stats.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("account_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("pending_dues")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {dues}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("collected_today")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {payments}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("expenses_today")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {expenses}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="6" size="12">
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-cash-info" className='border-bottom-sec'>
                                            {t("petty_cash")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-cash-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("cash_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/transactions`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("view_transactions")} src="assets/images/transaction.png" />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_transactions")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/bills/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("general_expense")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/bills/list`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_expenses")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                    <IonRow>

                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/reports`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("view_reports")} src="assets/images/reports.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_reports")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        {/*
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/daybook`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("day_book")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/banks/list`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_banks")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        */}
                                        
                                    {/*
                                        <IonCol sizeMd='2' sizeSm='3' size="6">
                                            <IonCard className="action-card" routerLink={`/app/expense/reports`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                        <IonRow className="ion-padding-top">
                                                            <IonCol className="ion-no-padding">
                                                                <img alt={t("expense_report")} src="assets/images/reports.png" />
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("expense_report")}</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    */}
                                    
                                    
                                    </IonRow>

                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-dues-info" className='border-bottom-sec'>
                                                {t("dues_receipts")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-dues-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                    {t("dues_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/collection`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("collect_fees")} src="assets/images/payroll-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("collect_fees")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/latefees/charge`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("charge_latefees")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/concession/give`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("give_concession")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                    <IonRow>

                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/dues/view`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("outstanding_dues")} src="assets/images/students-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("outstanding_dues")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/collections/receipts`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("collect_receipts")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/concessions`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("concession")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    {/*
                                    
                                        <IonCol sizeMd='2' sizeSm='3' size="6">
                                            <IonCard className="action-card" routerLink={`/app/dues/reports`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                        <IonRow className="ion-padding-top">
                                                            <IonCol className="ion-no-padding">
                                                                <img alt={t("dues_reports")} src="assets/images/reports.png"/>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("dues_reports")}</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                        <IonCol sizeMd='2' sizeSm='3' size="6">
                                            <IonCard className="action-card" routerLink={`/app/collections/reports`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                        <IonRow className="ion-padding-top">
                                                            <IonCol className="ion-no-padding">
                                                                <img alt={t("collect_reports")} src="assets/images/reports.png"/>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("collect_reports")}</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                    */}
                                    
                                    </IonRow>


                                </IonCol>
                                <IonCol sizeMd="6" size="12">
                                    
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-invoices-info" className='border-bottom-sec'>
                                            {t("student_invoices")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-invoices-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("invoices_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/dues/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("add_dues_student")} src="assets/images/students.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_dues_student")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/invoices/scheduled`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("scheduled_invoices")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/invoices/student`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_student_wise")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/dues/addMany`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={(authInfo.institute.type === "school") ? t("add_dues_class") : t("add_dues_batch")} src="assets/images/class.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("add_dues_class") : t("add_dues_batch")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        {/*
                                        <IonCol sizeMd='2' sizeSm='3' size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/dues/addHead`}>
                                            <IonCardContent className="ion-no-padding ion-text-center">
                                                        <IonRow className="ion-padding-top">
                                                            <IonCol className="ion-no-padding">
                                                                <img alt={t("add_dues_head")} src="assets/images/institute-1.png"/>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_dues_head")}</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        */}

                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/invoices/uncleared`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("uncleared_invoices")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/invoices/cleared`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("cleared_invoices")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-fees-info" className='border-bottom-sec'>
                                                {t("fee_head_struct")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-fees-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("fees_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/student`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("student_fee_struct")} src="assets/images/students-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_fee_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/fees`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_fee_structs")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_fee_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/fees/class`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={(authInfo.institute.type === "school") ? t("class_fee_struct") : t("batch_fee_struct")} src="assets/images/class-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("class_fee_struct") : t("batch_fee_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/feeheads`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_fee_heads")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/feeheads/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_fee_head")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            
                                        </IonCol>
                                    
                                        
                                    </IonRow>
                                
                                
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="6" size="12">
                                {authInfo.institute.payroll && (<>
                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-payroll-info" className='border-bottom-sec'>
                                                {t("payroll")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-payroll-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("payroll_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/payroll`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("generate_salary")} src="assets/images/payroll.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("generate_salary")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/payroll`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("pay_advance")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/payroll/`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("increment_salary")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/payroll`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("salary_reports")} src="assets/images/reports.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("salary_reports")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/payroll/`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton  className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("employee_salary_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/payroll`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton  className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("department_salary_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                    </IonRow>
                                    <IonRow>

                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/payroll/`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_salary_head")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/payroll/`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_salary_head")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/payroll/`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton  className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_salary_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/payroll/`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_salary_struct")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                    </IonRow>
                                    </>)}
                                </IonCol>
                                <IonCol sizeMd="6" size="12">
                                    
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Accounting;