import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { chatbubblesSharp, constructSharp } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Anchorme } from 'react-anchorme';
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface Task {
    _id: string,
    title: string,
    status: string,
    text: string,
    employee: Employee,
    messages: Message[],
    createdAt: Date,
    updatedAt: Date,
    taskInfo: TaskInfo
}

interface Employee {
    employeeID: string,
    name: string
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string,
    projectID: string,
    projectName: string
}

interface Message {
    timestamp: Date,
    userInfo: UserInterface,
    message: Update
}

interface UserInterface {
    userID: string,
    userName: string,
    userRole: string,
    userPhoto: string
}
interface Update {
    update: string,
    filename: string
}

interface ParamTypes {
    taskID: string
}

const TaskDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {taskID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [status, setStatus] = useState < string > ("");
    const [task, setTask] = useState < Task > ();
    const [showUpdate, setShowUpdate] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = () => {

        if (!status || status === "") {
            setMessage(t("status_mandatory"));
            setIserror(true);
            return;
        }
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/staffChats/'+taskID, { status, 'adminID': authInfo.user._id }).then(res => {
                    return resolve(res.data.chat.title);
              }).catch(err => reject(err));
    
            });
        }
      
        setShowLoading(true);
        upApli()
        .then(data => {

            setPass(data+t("has_been_updated"));
            setIspass(true);
        
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get('/staffChats/' + taskID);
                setTask(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, taskID, refreshed]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_task")}</IonTitle>
                    
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_task")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {task && (
                <IonGrid className="ion-no-padding">
                     <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                color="secondary">
                                                                    <IonRow>
                                                                        <IonCol size="8">
                                                                            <IonLabel position="stacked" className="input-label">{t("inquiry_status")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{t(task.status)}</IonInput>
                                                                        </IonCol>
                                                                        <IonCol size="4" >
                                                                            <IonLabel position="stacked" className="input-label">{t("updated_at")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{formatDate(task.updatedAt)}</IonInput>
                                                            
                                                                        </IonCol>
                                                                    </IonRow>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {!showUpdate && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='ion-padding-horizontal ion-text-center'>
                            <IonButton onClick={() => setShowUpdate(true)}
                                color="dark"
                                expand='block'>{t("update_status")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    )}
                    {showUpdate && (<>
                     <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='ion-padding-bottom border-bottom-sec'>
                                {t("update_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                   
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-margin ion-no-padding">
                                                    <IonRow>
                                                        <IonCol size="6">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">
                                                                {t("select_status")}
                                                                </IonLabel>
                                                                    <IonSelect value={status}
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_status")}
                                                                            onIonChange={
                                                                                (e) => setStatus(e.detail.value)
                                                                        }>
                                                                        <IonSelectOption value="upcoming">{t("upcoming")}</IonSelectOption>
                                                                        <IonSelectOption value="ongoing">{t("ongoing")}</IonSelectOption>
                                                                        <IonSelectOption value="review">{t("review")}</IonSelectOption>
                                                                        <IonSelectOption value="completed">{t("completed")}</IonSelectOption>
                                                                        <IonSelectOption value="deffered">{t("deffered")}</IonSelectOption>
                                                                        <IonSelectOption value="reopened">{t("reopened")}</IonSelectOption>

                                                                        
                                                                    </IonSelect>
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size="6" className='ion-text-center mt-15'>
                                                            <IonButton onClick={handleSubmit}
                                                                className="first-button"
                                                                fill="clear">{t("update_status")}</IonButton>
                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                    
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>)}
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='ion-padding-bottom border-bottom-sec'>
                                {t("task_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                        <span className="profile-text">{task.title}</span>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="4">
                                            <p>
                                                <span className='pt-05 text-capitalize'>{task.employee.name}</span>
                                                <span className='pt-05'> 
                                                <IonBadge color={(task.taskInfo.priority === "high") ? "danger" : (task.taskInfo.priority === "medium") ? "warning" : "medium"}>
                                                    {t(task.taskInfo.priority)}
                                                </IonBadge></span>
                                            </p>

                                        </IonCol>
                                        <IonCol size="7">
                                                <p>
                                                    <span className="pt-05">{task.taskInfo.projectName}</span>
                                                    <span className='pt-05'></span>{formatDate(task.taskInfo.startDate)} {t("to")} {formatDate(task.taskInfo.endDate)}
                                            
                                                </p>
                                        </IonCol>
                                        <IonCol size="1">
                                            
                                        <IonIcon 
                                                icon={constructSharp}
                                                color="secondary"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/task/${task._id}/edit`)
                                                }
                                            />
                                            <br/>
                                            <IonIcon 
                                                icon={chatbubblesSharp}
                                                color="tertiary"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/chat/${task._id}`)
                                                }
                                            />

                                            
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-margin-top'>
                                        <IonCol>
                                            <p className='anchorme-text'>
                                                <Anchorme>{task.text}</Anchorme>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    {(task.messages.length > 0) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("timeline")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="4" className='table-heading'>
                                            {t("status_update")}
                                        </IonCol>
                                        <IonCol sizeLg="4" size="0" className='table-heading ion-hide-lg-down'>
                                            {t("updated_by")}
                                        </IonCol>
                                        <IonCol sizeLg="4" size="8" className='table-heading ion-text-center'>
                                            {t("updated_at")}
                                        </IonCol>
                                        
                                    </IonRow>
                                    {(task.messages.slice(0).reverse().map((update, i) => 
                                    (update.message.update) && (
                                    <IonRow key={i} className='row-table-alt ion-padding-horizontal'>
                                        
                                            <IonCol size="4" className='table-field text-capitalize' >
                                                
                                                {t(update.message.update)}
                                                    
                                            </IonCol>
                                            
                                            <IonCol sizeLg="4" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                
                                                {t(update.userInfo.userName)}
                                                    
                                                
                                            </IonCol>
                                            <IonCol sizeLg="4" size="8" className='table-field'>
                                                
                                                {formatDate(update.timestamp)}
                                                <IonText className='ion-hide-lg-up'> {t("marked_by")} {t(update.userInfo.userName)}</IonText>
                                            </IonCol>
                                    </IonRow>
                                    )
                                    ))}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>)}
                    </IonGrid>
                )}
            </IonContent>
        </IonPage>
    );
};

export default TaskDetail;