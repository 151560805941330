import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { alertCircleOutline, informationCircleOutline, star, starOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Application {
    _id: string,
    amount: number,
    classInfo: ClassInfo,
    appointmentInfo: AppointmentInfo,
    rating?: number,
    createdAt: Date
}

interface ClassInfo {
    classID: string,
    className: string
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface ParamTypes {
    attendeeID: string
}

const WorkshopHistory: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {attendeeID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [paidApps, setPaidApps] = useState < Application[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [name, setName] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

      const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/applications/getAttendedSuper/${process.env.REACT_APP_APP_ID}/${attendeeID}?skip=${limit}`);
            if(result.data.applications && result.data.applications.length)
            {
                setPaidApps([...paidApps, ...result.data.applications]);
                setSkip(limit);
            }
              

        } catch (error: any) {
            //
        } finally {
            setShowLoading(false);
        }
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const user = await api.get(`/users/${attendeeID}`);
                setName(user.data.name);

                const paid = await api.get(`/applications/getAttendedSuper/${process.env.REACT_APP_APP_ID}/${attendeeID}`);
                setPaidApps(paid.data.applications);
                setTotal(paid.data.total);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed, attendeeID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("attended_workshops")} - {name}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("attended_workshops")} - {name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                        
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                      

                            {(paidApps.length > 0) && paidApps.map((app) => {
                                return (
                                        <IonCard key={app._id} className="ion-margin-vertical planner-card-3 ion-padding" >

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol size="10.5">
                                                                    <p><span className="class-name" >{app.classInfo.className}</span></p>
                                                                    <p><span className="class-info-2"> {formatDate(app.createdAt)}</span></p>
                                                                </IonCol>
                                                                <IonCol size="1.5">
                                                                    <p><IonIcon icon={informationCircleOutline} size="large" color="success"
                                                                    onClick={() => {
                                                                        history.push(`/app/application/${app._id}`);
                                                                    }}/>
                                                                    </p>
                                                                    
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-4">{t("project_start")} {formatDated(app.appointmentInfo.dated)}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-4">{t("duration")} {app.appointmentInfo.info}</span></p>
                                                                </IonCol>
                                                            </IonRow>

                                                            <IonRow>
                                                                <IonCol size="6">
                                                                <p><span className="class-info-3">{t("rating")}</span></p>
                                                                </IonCol>
                                                                <IonCol size="6">
                                                                    {!app.rating && (
                                                                            <>
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium"
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                            </>
                                                                        )}
                                                                        {app.rating && (app.rating === 1) && (
                                                                            <>
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium"
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                            </>
                                                                        )}
                                                                        {app.rating && (app.rating === 2) && (
                                                                            <>
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark"
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                            </>
                                                                        )}
                                                                        {app.rating && (app.rating === 3) && (
                                                                            <>
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark"
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                            </>
                                                                        )}
                                                                        {app.rating && (app.rating === 4) && (
                                                                            <>
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark"
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={starOutline}
                                                                                    color="medium" 
                                                                                />
                                                                            </>
                                                                        )}
                                                                        {app.rating && (app.rating === 5) && (
                                                                            <>
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark"
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                                <IonIcon
                                                                                    icon={star}
                                                                                    color="dark" 
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                {(paidApps.length === 0) && (
                                     <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger"
                                                    size="large" />
                                                <IonLabel className="list-title"><h3>{t("no_workshop_history")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )}
                        </IonCol>
                    </IonRow>

                    {(paidApps.length > 0) &&
                        <IonRow>
                            <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }
                  
                </IonGrid>
            </IonContent>
            <IonFooter className=' footer-shade'>
                <IonGrid>
                    <IonRow className='ion-text-center'>
                        <IonCol className="ion-padding footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${paidApps.length} ${t("of")} ${total}`} 
                        </IonCol>
                      
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default WorkshopHistory;