import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    ItemReorderEventDetail,
    isPlatform,
} from '@ionic/react';
import { addCircle, alertCircleOutline, arrowUndoCircle, book, documentText, toggle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface ParamTypes {
    topicID: string
}

interface Course {
    _id: string,
    name: string,
    desc: string,
    chapters: Chapter[],
    isActive: boolean
}
interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}
interface Topic {
    _id: string,
    name: string,
    video?: string,
    isActive: boolean,
    questions: Question[],
    order: number
}
interface Question {
    _id: string,
    title: string,
    isActive: boolean,
    order?: number
}

const TopicDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {topicID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showSaved, setShowSaved] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [course, setCourse] = useState < Course > ();
    const [chapterID, setChapterID] = useState < string > ("");
    const [topics, setTopics] = useState < Topic[] > ([]);
    const [topic, setTopic] = useState < string > ("");
    const [questions, setQuestions] = useState < Question[] > ([]);
    const [video, setVideo] = useState < string > ("");

    const handleChapter = (chapter : string) => {

        setVideo("");
        setQuestions([]);
        setTopic("");
        setTopics([]);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            } finally {
                setShowLoading(false);
            }
           
        };

        if (chapter !== "")
        {
            setChapterID(chapter);
            fetchUp();
        }
        
    }

    const handleTopic = (topic: string) => {

        setTopic(topic);
        setQuestions([]);
        setVideo("");

        if (topic !== "")
        {
            if (topics.find(val => val._id === topic)!.video !== undefined)
            {
                setVideo(topics.find(val => val._id === topic)!.video!);
            }
            if (topics.find(val => val._id === topic)!.questions.length)
            {
                //sort ques based on order
                let res = topics.find(val => val._id === topic)!.questions.sort((a: Question, b: Question) => a.order! - b.order!);
                setQuestions(res);
            }
        }
        
    }

    const handleUndo = () => {

        if (topic !== "")
        {
            if (topics.find(val => val._id === topic)!.questions.length)
            {
               //sort ques based on order
                let res = topics.find(val => val._id === topic)!.questions.sort((a: Question, b: Question) => a.order! - b.order!);
                setQuestions(res);
            }
        }
    }

    const handleTitle = (title: string, index: number) => {

        let comps = [...questions];
        comps[index].title = title;
        setQuestions(comps);
    
    }

    const handleDelete = (index: number) => 
    {
        let comps = [...questions];
        if(comps[index]._id.length > 10)
        {
            comps[index].isActive = !comps[index].isActive;
        }
        else
        {
            if (index > -1) {
                comps.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setQuestions(comps);
    }

    const handleAdd = () => {
        let r = (Math.random() + 1).toString(36).substring(7);
        let comps = [...questions];
        comps.push({_id: r, title: '', isActive: true});
        setQuestions(comps);
    }


    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let ques = [...questions];

        if (to < from)
        {
            ques[to] = {...questions[from]};
            to = to + 1;
            while (to <= from)
            {
                ques[to] = {...questions[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            ques[toCopy] = {...questions[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                ques[toCopy] = {...questions[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }

        setQuestions(ques);
        
        event.detail.complete();
      }

      const handleVideo = () => {

        if (!video || (video === "")) {
            setMessage(t("video_mandatory"));
            setIserror(true);
            return;
        }

        const upTopic = () => {
            return new Promise((resolve, reject) => {
                api.put('/topics/'+topicID, { video }).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        }

        if (topic !== "")
        {
            setShowLoading(true);
            upTopic()
            .then((data: any) => {

                const comps = [...topics];
                const index = topics.findIndex(val => val._id === topic);
                if (index > -1)
                {
                    comps[index] = data;
                }
                setTopics(comps);
      
                setPass(data.name+t("has_been_updated"));
                setIspass(true);
              
            })
            .catch((error) => {
                // Api Error handling is already done in the interceptor
            })
            .finally(() => setShowLoading(false));
        }

    }

    const handleQuestions = () => {

        if (!questions || (questions.length < 1)) {
            setMessage(t("questions_mandatory"));
            setIserror(true);
            return;
        }

        let sendQuestions: Question[] = [];
        for (let i = 0; i < questions.length; i++)
        {
            if(questions[i].title === "") {
                setMessage(t("title_mandatory"));
                setIserror(true);
                return;
            }
            sendQuestions.push({
                _id: questions[i]._id,
                title: questions[i].title,
                isActive: questions[i].isActive
            });
        }
  
        const upTopic = (topicID: string, questions: Question[]) => {
          return new Promise((resolve, reject) => {
            api.post('/topics/setQuestions', { topicID, questions }).then(res => {
                return resolve(res.data);
            }).catch(err => reject(err));
          });
        };

        if (topic !== "")
        {
            
            setShowLoading(true);
            upTopic(topic, sendQuestions)
            .then((data: any) => {

                if (Object.hasOwn(data, 'questions'))
                {
                    let res =  data.questions.sort((a: Question, b: Question) => a.order! - b.order!);
                    setQuestions(res);
                }
                const comps = [...topics];
                const index = comps.findIndex(val => val._id === topic);
                if (index > -1)
                {
                    comps[index] = data;
                }
                setTopics(comps);
    
                setPass(data.name+t('has_been_updated'));
                setIspass(true);
            
            })
            .catch((error) => {
                // Api Error handling is already done in the interceptor
            })
            .finally(() => setShowLoading(false));
        }
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/topics/${topicID}`);
                const res = await api.get(`/courses/${result.data.courseID}`);
                setCourse(res.data);

                setChapterID(result.data.chapterInfo.chapterID);

                const tops = await api.get(`/topics/getByChapter/${result.data.chapterInfo.chapterID}`);
                setTopics(tops.data);
                setTopic(topicID);

                if (result.data.video)
                {
                    setVideo(result.data.video);
                }
                
                if (result.data.questions.length)
                {
                    //sort ques based on order
                    let res = result.data.questions.sort((a: Question, b: Question) => a.order! - b.order!);

                    setQuestions(res);
                }


            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, topicID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("topic_assessment")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("topic_assessment")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={showSaved}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setShowSaved(false)
                    }
                    header={t("error")}
                    message={t("save_first")}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    {course && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="action-card-b">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="3">
                                                
                                                <IonIcon icon={book} color="secondary" className="action-icon" />
                                                
                                            </IonCol>
                                            <IonCol size="9">
                                                <IonText className='action-text-3'>{course.name}<br/>
                                                <p>{course.desc}</p>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec'>
                            {t("select_topic")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("select_chapter")}</IonLabel>
                                                    <IonSelect value={chapterID}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_chapter")}
                                                            onIonChange={
                                                                (e) => handleChapter(e.detail.value)
                                                        }>
                                                            
                                                            {(course) && course.chapters.map((chapter) => 
                                                                (chapter.isActive) && 
                                                                (<IonSelectOption key={chapter._id} value={`${chapter._id}`}>{chapter.chapterName}</IonSelectOption>)
                                                            )}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("select_topic")}</IonLabel>
                                                        <IonSelect value={topic}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_topic")}
                                                                onIonChange={
                                                                    (e) => handleTopic(e.detail.value)
                                                            }>
                                                                
                                                                {topics.map((topic) => 
                                                                    (topic.isActive) && 
                                                                    (<IonSelectOption key={topic._id} value={`${topic._id}`}>{topic.name}</IonSelectOption>)
                                                                )}
                                                        </IonSelect>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                        </IonCol>
                    </IonRow>
                {(topic !== "") && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec'>
                            {t("topic_content")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t('video_link')}</IonLabel>
                                        <IonInput type="text"
                                            className="input-field"
                                            placeholder={t('video_link')}
                                            value={video}
                                            onIonInput={
                                                (e) => setVideo(e.detail.value!)
                                        }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='ion-text-center'>
                            <IonButton onClick={handleVideo} color="dark">{t("update_video")}</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                            <IonRow>
                                <IonCol size="8" className='mt-05'>
                                    <IonText className='list-title ion-padding-start'>
                                        {t("topic_questions")}
                                    </IonText>
                                </IonCol>
                                <IonCol size="2" className='ion-text-right'>
                                    <IonIcon size="large"
                                        icon={arrowUndoCircle}
                                        color='secondary'
                                        onClick={handleUndo}
                                    />
                                </IonCol>
                                <IonCol size="2" className='ion-text-center'>
                                    <IonIcon size="large"
                                        icon={addCircle}
                                        color='secondary'
                                        onClick={handleAdd}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {(questions.length > 0) ? (
                    <IonRow className="ion-margin-top mb-60">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {questions.map((ques, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={ques._id}>
                                                            <IonReorder slot="start"/>
                                                            <IonInput type="text"
                                                                    placeholder={t("question_title")}
                                                                    value={ques.title}
                                                                    onIonInput={
                                                                        (e) => handleTitle(e.detail.value!, i)
                                                                }
                                                                readonly={!ques.isActive}
                                                                ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={ques.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                {ques.isActive && (
                                                                <IonButton onClick={() => (ques._id.length > 10) ? history.push(`/app/courses/topic/${topicID}/${ques._id}`) : setShowSaved(true) }> 
                                                                    <IonIcon
                                                                    icon={documentText}
                                                                    color="tertiary"
                                                                    />
                                                                </IonButton>
                                                                )}
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>

                    ) : (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_questions")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                </>
                )}

            </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonButton onClick={handleQuestions}
                            className="first-button"
                            fill="clear">{t("update_topic")}</IonButton>

                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};

export default TopicDetail;
