import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface DaySchedule {
    timeSlot: Slot,
    subject: Subject,
    teacher: Teacher
}

interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string,
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface ClassInfo {
    classID: string,
    className: string
}

interface ParamTypes {
    timetableID: string
}

interface TimeTable {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    mon: DaySchedule[],
    tue: DaySchedule[],
    wed: DaySchedule[],
    thu: DaySchedule[],
    fri: DaySchedule[],
    sat: DaySchedule[]
}

const ClassTimeTable: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {timetableID} = useParams < ParamTypes > ();
    const [timetable, setTimeTable] = useState < TimeTable > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [day, setDay] = useState < string > ("mon");
    const [slots, setSlots] = useState < Slot[] > ([]);

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/timeTables/${timetableID}`);
                setTimeTable(result.data);

                let slotids: Slot[] = [];  

                for(let i = 0; i < result.data.mon.length; i++)
                {
                    slotids.push({"timeSlotID": result.data.mon[i].timeSlot.timeSlotID, "startTime": result.data.mon[i].timeSlot.startTime, "endTime": result.data.mon[i].timeSlot.endTime});
                }

                setSlots(slotids);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, timetableID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{timetable && `${timetable.name} - ${timetable.classInfo.className}`}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{timetable && `${timetable.name} - ${timetable.classInfo.className}`}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding overflow-x">
                {(!isPlatform("mobile")) && (
                    <div className="ion-table min-xl">

                        <IonRow className="table-title ion-padding-vertical ion-text-center">

                            <IonCol size="1.5" className='table-heading heading-fixed'>
                            {t("timings")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("monday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("tuesday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("wednesday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("thursday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("friday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("saturday")}
                            </IonCol>
                        </IonRow>
                        {slots.length > 0 && slots.map((slot, i) => (
                        <IonRow key={slot.timeSlotID} className='row-table' >
                            <IonCol size="1.5" className='table-heading heading-fixed'>     
                                <div className="vertical-container">
                                    <div className="vertical-center">     
                                        {slot.startTime} - {slot.endTime}
                                    </div>
                                </div>
                            </IonCol>
                            
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonInput type="text"
                                                    value={timetable!.mon.find((val) => val.timeSlot.timeSlotID === slot.timeSlotID)!.subject.subjectName}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>  
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonInput type="text"
                                                    value={timetable!.tue.find((val) => val.timeSlot.timeSlotID === slot.timeSlotID)!.subject.subjectName}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonInput type="text"
                                                    value={timetable!.wed.find((val) => val.timeSlot.timeSlotID === slot.timeSlotID)!.subject.subjectName}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >

                                            <IonInput type="text"
                                                    
                                                    value={timetable!.thu.find((val) => val.timeSlot.timeSlotID === slot.timeSlotID)!.subject.subjectName}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            
                                            <IonInput type="text"
                                                    
                                                    value={timetable!.fri.find((val) => val.timeSlot.timeSlotID === slot.timeSlotID)!.subject.subjectName}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                          
                                            <IonInput type="text"
                                                    
                                                    value={timetable!.sat.find((val) => val.timeSlot.timeSlotID === slot.timeSlotID)!.subject.subjectName}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        ))}
                    </div>
                )}

                {(isPlatform("mobile")) && (
                <>
                  <IonRow>
                        <IonCol>
                            <IonSegment scrollable={true} value={day} onIonChange={e => setDay( e.detail.value!.toString() )}  >
                                <IonSegmentButton value="mon">
                                    <IonLabel>{t("mon")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="tue">
                                    <IonLabel>{t("tue")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="wed">
                                    <IonLabel>{t("wed")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="thu">
                                    <IonLabel>{t("thu")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="fri">
                                    <IonLabel>{t("fri")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="sat">
                                    <IonLabel>{t("sat")}</IonLabel>
                                </IonSegmentButton>
                               
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    {(day === 'mon') && (timetable && timetable.mon.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.mon.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol> 
                                                   <IonText>{entry.timeSlot.startTime} - {entry.timeSlot.endTime}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonInput type="text"
                                                            value={entry.subject.subjectName}
                                                            readonly={true}
                                                            />
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'mon') && (timetable && timetable.mon.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'tue') && (timetable && timetable.tue.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.tue.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                            <IonCol> 
                                                   <IonText>{entry.timeSlot.startTime} - {entry.timeSlot.endTime}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonInput type="text"
                                                                value={entry.subject.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'tue') && (timetable && timetable.tue.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'wed') && (timetable && timetable.wed.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.wed.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                            <IonCol> 
                                                   <IonText>{entry.timeSlot.startTime} - {entry.timeSlot.endTime}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonInput type="text"
                                                                value={entry.subject.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'wed') && (timetable && timetable.wed.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'thu') && (timetable && timetable.thu.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.thu.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                            <IonCol> 
                                                   <IonText>{entry.timeSlot.startTime} - {entry.timeSlot.endTime}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonInput type="text"
                                                                value={entry.subject.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'thu') && (timetable && timetable.thu.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                    {(day === 'fri') && (timetable && timetable.fri.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.fri.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                            <IonCol> 
                                                   <IonText>{entry.timeSlot.startTime} - {entry.timeSlot.endTime}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonInput type="text"
                                                                value={entry.subject.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'fri') && (timetable && timetable.fri.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                    {(day === 'sat') && (timetable && timetable.sat.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.sat.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                            <IonCol> 
                                                   <IonText>{entry.timeSlot.startTime} - {entry.timeSlot.endTime}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonInput type="text"
                                                                value={entry.subject.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'sat') && (timetable && timetable.sat.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                </>)}
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton routerLink={timetable ? `/app/timetable/update/${timetable._id}` : ''}
                                className="first-button"
                                fill="clear">{t("update_time_table")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ClassTimeTable;
