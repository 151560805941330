import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

declare global {
    interface Window {
        launchWhatsAppSignup: () =>  void;
        fbAsyncInit: () => void;
        FB: any;
    }
}

const Marketing: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [signed, setSigned] = useState < boolean > (false);
    
    const [newLeads, setNewLeads] = useState < number > (0);
    const [openLeads, setOpenLeads] = useState < number > (0);
    const [convertedLeads, setConvertedLeads] = useState < number > (0);
    
    const responseFacebook = (code: any) => {

        const getToken = () => {
            return new Promise((resolve, reject) => {
                api.post('/institutes/whatsapp/getToken', {'instituteID': authInfo.user.instituteID, code}).then(res => {
        
                return resolve(res.data.institute.name);
                
              }).catch(err => reject(err));
    
            });
          }

            setShowLoading(true);
            getToken()
            .then(data => {
            
                setPass(data+t("has_been_setup"));
                setIspass(true);
            })
            .catch((error) => {
                // Api Error handling is already done in the interceptor
            })
            .finally(() => setShowLoading(false));
            
      }

    const handleSignup = () => {
        window.launchWhatsAppSignup();
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const stats = await api.get(`/institutes/crmStats/${authInfo.user.instituteID}`);
                const statsData = stats.data;

                setNewLeads(statsData.newLeads);
                setOpenLeads(statsData.openLeads);
                setConvertedLeads(statsData.convertedLeads);

                const result = await api.get('/institutes/' + authInfo.user.instituteID );
                
                if (result.data.config && result.data.config.phoneID)
                {
                    setSigned(true);
                }

            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

        window.fbAsyncInit = () => {
            window.FB.init({
              appId            : '2115983695413326',
              autoLogAppEvents : true,
              xfbml            : true,
              version          : 'v18.0'
            });
          };
    
        window.launchWhatsAppSignup = () => {
            // Launch Facebook login
            window.FB.login(function (response: any) {
              if (response.authResponse) {
                //Use this token to call the debug_token API and get the shared WABA's ID
                responseFacebook(response.authResponse.code);
              } else {
                setMessage(t("fb_embedded_error"));
                setIserror(true);
              }
            }, {
              config_id: '327054326745443', // configuration ID for fb business login
              response_type: 'code',     // must be set to 'code' for System User access token
              override_default_response_type: true,
              extras: {
                setup: {
                  
                }
              }
            });
          }
    
        const script = document.createElement('script');
    
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";
    
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }

    }, [authInfo]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("crm")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                     {showLoading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("crm")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-lg">
                        <IonCol>
                            <IonRow className='dash-border-bottom'>
                                <IonCol sizeMd="6" size="12">

                                    <IonCard className="dashboard-card dash-bg-5" >
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("enquiries_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("enquiries_overview")} src="assets/images/admn-stats.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("enquiries_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("new_leads")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {newLeads}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("open_leads")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {openLeads}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("leads_converted")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {convertedLeads}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                           
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="6" size="12">
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-inquiries-info" className='border-bottom-sec'>
                                            {t("admn_inquiries")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-inquiries-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("admn_inquiries_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/inquiries/board`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("enquiries_board")} src="assets/images/board.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("enquiries_board")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/inquiries/new`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("new_admn_inq")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/admission/inquiries/list`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("enquiries_list")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/inquiries/reports/salesrep`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("sales_rep_performance")} src="assets/images/reports.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("sales_rep_performance")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/inquiries/reports`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("enquiries_reports")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/admission/class`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-assets-info" className='border-bottom-sec'>
                                                {t("manage_assets")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-assets-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("assets_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" onClick={handleSignup}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("embedded_signup")} src="assets/images/whatsapp-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("embedded_signup")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">

                                            <IonCard className="action-card action-card-2" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/hide/app/whatsapp/templates`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_templates")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/hide/app/whatsapp/medias`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_media")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol sizeMd="6" size="12">
                                    
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-messaging-info" className='border-bottom-sec'>
                                                {t("messaging")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-messaging-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("messaging_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/hide/app/whatsapp/messages`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("conversations")} src="assets/images/messages.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("conversations")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/hide/app/whatsapp/messages/new`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("send_message")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/hide/app/whatsapp/campaigns`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("manage_campaigns")} src="assets/images/campaign.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_campaigns")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/hide/app/whatsapp/campaigns/start`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("start_campaign")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" onClick={() => {
                                                if (!signed)
                                                {
                                                    setMessage(t("signup_first"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/whatsapp/campaigns/reports`);
                                                }
                                            }}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("campaigns_reports")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>

                                        </IonCol>
                                    </IonRow>
                                    

                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Marketing;