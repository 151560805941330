import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, informationCircle, newspaper, notifications } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchStudent from '../../../components/SwitchStudent';
import useApiService from '../../../components/ApiService';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string
}

const Circulars: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [circulars, setCirculars] = useState < Circular[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [skip, setSkip] = useState < number > (0);
    const [classID, setClassID] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/circulars/getByClass/${classID}?skip=${limit}`);

            if(result.data)
            {
                setCirculars([...circulars, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error : any) {
            //
        }
        
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: '2-digit' })
    };

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                setClassID(student.data.classID);
                const result = await api.get(`/circulars/getByClass/${student.data.classID}`);
                setCirculars(result.data);

            } catch {
                // Api Error handling is already done in the interceptor
           } finally {
               setShowLoading(false);
           }
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("circulars")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("circulars")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />
                
                <IonGrid className="ion-no-padding mb-60">
                 
                 <div className="ion-table">
                 {(circulars.length > 0) ? circulars.map((circular, i) => {
                         return (
                             <IonRow key={circular._id} className={((i%2) === 0) ? 'row-table-alt ion-padding-horizontal' : 'row-table ion-padding-horizontal'}
                             onClick={
                                 () => history.push(`/app/circular/${circular._id}`)
                                 }
                             >
                                 <IonCol size="2" className='table-field'>
                                     <IonIcon
                                         icon={circular.filename ? newspaper : notifications}
                                         className='table-icon mt-10'
                                         color={!circular.filename ? 'secondary' : 'tertiary' }
                                         />
                                 </IonCol>
                                 
                                     <IonCol size="9" className='table-field'>
                                         <IonRow>
                                             <IonCol>
                                                
                                             {circular.title}
                                                 
                                             </IonCol>
                                         </IonRow>
                                         <IonRow className='vid-responsive-field-1 text-capitalize'>
                                             <IonCol>
                                                 <IonLabel>
                                                     {circular.filename ? t("circular") : t("announcement")} &#8226; {formatDate(circular.createdAt)}
                                                 </IonLabel>
                                                     
                                             </IonCol>
                                         </IonRow>
                                         
                                     </IonCol>
                                     
                                     <IonCol size="1" className='table-field'>
                                             <IonIcon 
                                             icon={informationCircle}
                                             color="dark"
                                             className='table-icon mt-10'
                                             
                                             />

                                     </IonCol>
                                 
                             </IonRow>
                         )
                     }) : ( 
                     <IonRow>
                         <IonCol>
                             <IonCard className="red-card">
                                 <IonCardContent>
                                     <IonItem lines="none">
                                         <IonIcon icon={alertCircleOutline}
                                             slot="start"
                                             color="danger" />
                                         <IonLabel className="list-title"><h3>{t("no_circulars")}</h3></IonLabel>

                                     </IonItem>
                                 </IonCardContent>
                             </IonCard>
                         </IonCol>
                     </IonRow>
                     )
                    } 
                    </div>
                    {(circulars.length > 0) &&
                            <IonRow>
                               <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                                </IonCol>
                            </IonRow>
                            }
             
                </IonGrid>

              
            </IonContent>
        </IonPage>
    );
};

export default Circulars;
