import axios, { AxiosInstance } from 'axios';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { IonAlert } from '@ionic/react';
import { useTranslation } from "react-i18next";
import { useAuth } from './AuthContext';

const useApiService = () => {
    const { authInfo, logOut } = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [isExpired, setIsExpired] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const api: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    // Response interceptor to handle token expiration
    api.interceptors.response.use(
        response => response,
        async error => {
            if (error.response && error.response.data.message === 'expired') {
                setIsExpired(true); // Trigger the expired state
            } else {
                setErrorMessage(error.response?.data.message || t("unable_to_connect"));
                setIsError(true); // Trigger general error state
            }
            return Promise.reject(error);
        }
    );

    // Function to render the alert components
    const renderApiAlerts = () => (
        <>
            {/* Alert for session expiration */}
            <IonAlert
                cssClass="first-alert"
                isOpen={isExpired}
                onDidDismiss={async () => {
                    await logOut();
                    history.replace("/login");
                }}
                header={t("session_expired")}
                message={t("login_again")}
                buttons={[`${t("logout")}`]}
            />

            {/* Alert for general errors */}
            <IonAlert isOpen={isError}
                cssClass="first-alert"
                onDidDismiss={
                    () => setIsError(false)
                }
                header={t("error")}
                message={errorMessage}
                buttons={
                    [`${t("close")}`]
                }
            />
        </>
    );

    return {
        api,
        renderApiAlerts,
    };
};

export default useApiService;