import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import { helpCircleOutline } from 'ionicons/icons';

const Attendance: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_attendance")}</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton id="open-attendance-info" fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon color="success" icon={helpCircleOutline} className="badge-icon" />
                            <IonPopover trigger="open-attendance-info" className='info-popover'>
                                <IonCard className='note-card-3 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol className='note-text'>
                                                <p>
                                                {t("attendance_help")}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
    
                            <IonCol sizeMd='2' sizeSm='3' size="6">
                                <IonCard className="action-card action-card-2" routerLink={`/app/attend`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("mark_attendance")} src="assets/images/attendance-1.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("mark_attendance")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeMd='2' sizeSm='3' size="6">
                                <IonCard className="action-card action-card-2" routerLink={`/app/attend/class`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("class_attendance") : t("batch_attendance")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                                <IonCard className="action-card action-card-2" routerLink={`/app/attend/leave`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("leave_applications")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                                
                            <IonCol sizeMd='2' sizeSm='3' size="6">
                                <IonCard className="action-card action-card-2" routerLink={`/app/entryexit`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("mark_entry_exit")} src="assets/images/timetable-1.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("mark_entry_exit")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeMd='2' sizeSm='3' size="6">
                                <IonCard className="action-card action-card-2" routerLink={`/app/entry/log`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("entry_exit_logs")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                                <IonCard className="action-card action-card-2" routerLink={`/app/attendance/status`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("attendance_report")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            {(isPlatform("capacitor")) && (
                            <>
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/scan/entry`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_entry")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard className="action-card action-card-2" routerLink={`/app/scan/exit`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_exit")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                {/** 
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/scan/present`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("student_present")} src="assets/images/qrcode-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_present")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                */}
                            </>
                            )}
                          
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Attendance;