import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, calendar } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface Employee {
    _id: string,
    name: string,
    departmentName: string,
    status: string
}

interface Department {
    _id: string,
    name: string
}

interface ParamTypes {
    role?: string
}

const StaffAttend: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {role} = useParams < ParamTypes > ();
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        
    };

    const handleDepartment = (departmentID: string) => {

        const onDate = new Date(Date.now());
        setSelectedDate(onDate.toISOString());
        setDepartment(departmentID);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getByDepartment/${departmentID}/${onDate}`);
                setEmployees(studs.data);
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getAllInstitute/${authInfo.user.instituteID}/${onDate}`);
                setEmployees(studs.data);
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }
        
    }

    const handleSubmit = (dated: string | string[]) => {

        if (authInfo.user.role === 'Admin' || authInfo.user.role === 'Super')
        {
            if (!department || department === "") {
                setMessage(t("select_dep"));
                setIserror(true);
                return;
            }
        }

        if (!(typeof(dated) === "string")) {
            setMessage(t("select_date"));
            setIserror(true);
            return;
        }

        setSelectedDate(dated);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getByDepartment/${department}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getAllInstitute/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        const fetchTeacher = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getAllTeacher/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        const fetchDriver = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getAllDriver/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        const fetchSalesRep = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getAllSalesRep/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        if (authInfo.user.role === 'Super' || authInfo.user.role === 'Admin')
        {
            if (department === 'all')
            {
                fetchAll();
            }
            else
            {
                fetchUp();
            }
        }
        else
        {
            if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
            {
                fetchTeacher();
            }
            if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
            {
                fetchDriver();
            }
            if ((authInfo.user.role === 'AdmissionHead') || (authInfo.user.role === 'Employee' && role === "salesreps"))
            {
                fetchSalesRep();
            }
            
        }

    }

    const handleChange = (employeeID : string, status: string) => {

        if (!selectedDate || selectedDate === "") {
            setMessage(t("select_date"));
            setIserror(true);
            return;
        }

        const entry = () => {
            return new Promise((resolve, reject) => {
                api.get('/staffAttends/mark/' + employeeID + '/' + selectedDate+ '/' + status).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        entry()
        .then(data => {

            let studs = [...employees];
            let stud = studs.find(val => val._id === employeeID)!;
            stud.status = status;
            setEmployees(studs);
            
            /*setPass(data+' has been marked present.');
            setIspass(true);*/
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if (authInfo.user.role === 'Super' || authInfo.user.role === 'Admin') {
                    const result = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                    setDepartments(result.data);
                    setEmployees([]);
                }
                else {
                    const dated = new Date(Date.now());
                    setSelectedDate(dated.toISOString());
                    if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
                    {
                        const studs = await api.get(`/staffAttends/getAllTeacher/${authInfo.user.instituteID}/${dated}`);
                        setEmployees(studs.data);
                    }
                    if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
                    {
                        const studs = await api.get(`/staffAttends/getAllDriver/${authInfo.user.instituteID}/${dated}`);
                        setEmployees(studs.data);
                    }
                    if ((authInfo.user.role === 'AdmissionHead') || (authInfo.user.role === 'Employee' && role === "salesreps"))
                    {
                        const studs = await api.get(`/staffAttends/getAllSalesRep/${authInfo.user.instituteID}/${dated}`);
                        setEmployees(studs.data);
                    }
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, role]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("mark_attendance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end"> 
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("mark_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
                        {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin')) && (
                         <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding pointer">
                                
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">{t("date")}</IonLabel>
                                            <IonInput className="input-field" value={typeof selectedDate !== 'string' ? t("pick_date") : formatDate(selectedDate)} readonly={true} />
                                                <IonModal trigger="open-date-input" showBackdrop={true} 
                                                    initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                    <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                        <IonToolbar>
                                                            <IonTitle>{t("pick_date")}</IonTitle>
                                                            
                                                        </IonToolbar>
                                                        <IonDatetime
                                                            value={selectedDate}
                                                            presentation="date"
                                                            onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                            showDefaultButtons={true}
                                                            size="cover"
                                                        />
                                                    </IonContent>
                                                    
                                                </IonModal>
                                        </IonItem>
                                     
                                    </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding max-xl">
            
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("employees")} {typeof selectedDate !== "string" ? "" : `(${formatDate(selectedDate)})`}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
         
                    {(employees.length > 0) && (
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                        
                          <IonList className="ion-no-padding">

                             {(employees.map((employee) => {
                            return (
                                <IonItem key={employee._id}
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                            <IonIcon slot="start"
                                            icon={calendar}
                                            color={
                                                (employee.status === 'present') ? 'success' : (employee.status === 'absent' || employee.status === 'leave') ? 'danger' : ''
                                            }/>
                                        <IonLabel>
                                        <h2 className="text-capitalize">{employee.name} {((department === "all") || (department === "")) && ("("+employee.departmentName+")")} </h2>
                                            {(employee.status === 'absent') && (
                                                <p>{t("marked_absent")}</p>
                                            )}

                                            {(employee.status === 'present') && (
                                                <p>{t("marked_present")}</p>
                                            )}

                                            {(employee.status === 'half') && (
                                                <p>{t("marked_half")}</p>
                                            )}

                                            {(employee.status === 'late') && (
                                                <p>{t("marked_late")}</p>
                                            )}

                                                {(employee.status === 'leave') && (
                                                <p>{t("marked_leave")}</p>
                                            )}

                                            {(employee.status === 'holiday') && (
                                                <p>{t("marked_holiday")}</p>
                                            )}
                                        </IonLabel>
                                        <IonSelect value={employee.status}
                                            className="input-field"
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("mark_attendance")}
                                            onIonChange={
                                                (e) => handleChange(employee._id, e.detail.value)
                                            }>

                                            <IonSelectOption value="present">{t("present")}</IonSelectOption> 
                                            <IonSelectOption value="absent">{t("absent")}</IonSelectOption> 
                                            <IonSelectOption value="half">{t("half")}</IonSelectOption> 
                                            <IonSelectOption value="late">{t("late")}</IonSelectOption> 
                                            <IonSelectOption value="leave">{t("leave")}</IonSelectOption>
                                            <IonSelectOption value="holiday">{t("holiday")}</IonSelectOption>
                                        </IonSelect>
                                        
                                    </IonItem>

                                    )
                                }))}
                                
                                </IonList>
                            </IonCol>
                        </IonRow>
                        )}
                            
                    {(employees.length === 0) && ( 
                        <IonRow>
                            <IonCol>
                                <IonCard className='red-card'>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_employees")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    )} 

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow className="max-xl">
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("total")}: {employees.length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("present")}: {employees.filter(val => val.status === "present").length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("absent")}: {employees.filter(val => val.status === "absent").length}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StaffAttend;