import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface ItemTran {
    _id: string,
    qty: number,
    amount: number,
    vendor?: Vendor,
    student?: Student,
    createdAt: Date
}

interface Vendor  {
    vendorID: string,
    vendorName: string
}
interface Student  {
    studentID: string,
    studentName: string
}

interface ParamTypes {
    itemID: string
}

const ItemLedger: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {itemID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [transactions, setTransactions] = useState < ItemTran[] > ([]);
    const [dues, setDues] = useState < ItemTran[] > ([]);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("three");
    const [due, setDue] = useState < number > (0);
    const [balance, setBalance] = useState < number > (0);
    const [name, setName] = useState < string > ("");
    const [stock, setStock] = useState < boolean > (false);
    const [price, setPrice] = useState < number > (0);

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/itemTransactions/listByItem/${itemID}`, {duration, toDOB, fromDOB});
                setTransactions(result.data.debits);
                setDues(result.data.credits);
                setBalance(result.data.total);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/items/${itemID}`);
                setName(stud.data.name);
                setStock(stud.data.stock);
                setDue(stud.data.quantity);
                setPrice(stud.data.price);

                const result = await api.post(`/itemTransactions/listByItem/${itemID}`, {duration: "three", toDOB: "", fromDOB: ""});
                setTransactions(result.data.debits);
                setDues(result.data.credits);
                setBalance(result.data.total);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, itemID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className='text-capitalize'>{name}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className='text-capitalize'>{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("due_duration")}
                                            </IonLabel>
                                            <IonSelect value={duration}
                                                placeholder={t("due_duration")}
                                                onIonChange={
                                                    (e) => setDuration(e.detail.value)
                                                }>
                                                <IonSelectOption value="one">{t("last_month")}</IonSelectOption>
                                                <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                                <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                                <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                                <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                                <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                     
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_transactions")}
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className='ion-text-center mt-10'>
                           <span className="text-capitalize text-bold">{t("sell_price")} {price}</span>
                        </IonCol>
                        
                    </IonRow>
                    <IonRow className=" mt-10">
                        
                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("credit")} ({t("buy")})
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                        {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                        {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                        {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                        {t("amount")}
                                    </IonCol>
                                   
                                </IonRow>
                               

                                {(dues.length > 0) ? (dues.map((due, i) => {
                                    return (
                                        <IonRow key={due._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            
                                            <IonCol size="1" className='table-field' >
                                                {i+1}.
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDated(due.createdAt)}
                                            </IonCol>
                                            <IonCol size="5" className='table-field'>
                                                {t("purchased")} {due.qty} {t("units")}
                                                {due.vendor && due.vendor.vendorID !== "none" && (<IonText className='text-capitalize'> {t("from")} <Link to={`/app/vendor/ledger/${due.vendor.vendorID}`} className='link-dark'>{due.vendor.vendorName}</Link></IonText>)} 
                                            </IonCol>
                                            <IonCol size="3" className='table-field ion-text-right'>
                                                {due.amount}
                                            </IonCol>
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_trans")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 
                            </div>
                        </IonCol>

                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("debit")} ({t("sell")})
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">

                                    <IonCol size="1" className='table-heading'>
                                        {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                        {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                        {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                        {t("amount")}
                                    </IonCol>
                                   
                                </IonRow>

                                {(transactions.length > 0) ? (transactions.map((tran, i) => {
                                    return (
                                        <IonRow key={tran._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                             
                                            <IonCol size="1" className='table-field' >
                                                {i+1}.
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDated(tran.createdAt)}
                                            </IonCol>
                                            <IonCol size="5" className='table-field'>
                                                {t("sold")} {tran.qty} {t("units")}
                                                {tran.student && (<IonText className='text-capitalize'> {t("to")} <Link to={`/app/student/ledger/${tran.student.studentID}`} className='link-dark'>{tran.student.studentName}</Link></IonText>)} 
                                            </IonCol>
                                            <IonCol size="3" className='table-field ion-text-right'>
                                                {tran.amount}
                                            </IonCol>
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_trans")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 
                            </div>
                        </IonCol>

                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    <span>{t("page_balance")} {balance}</span><span className="ion-margin-start">{t("quantity_left")} {stock ? due : t("na")}</span>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-md'>
                                    {stock && (
                                    <IonButton 
                                        onClick={
                                            () =>history.push(`/app/inventory/transact/buy/${itemID}`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("buy_item")}
                                    </IonButton>
                                    )}
                                    
                                    <IonButton 
                                        onClick={
                                            () =>history.push(`/app/inventory/transact/sell/${itemID}`)
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("sell_item")}
                                    </IonButton>

                                    {/*<IonButton 
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("export_excel")}</IonButton>*/}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ItemLedger;