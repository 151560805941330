import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import useApiService from '../../../components/ApiService';

const Admission: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [pendingApps, setPendingApps] = useState < number > (0);
    const [totalApps, setTotalApps] = useState < number > (0);
    const [fees, setFees] = useState < number > (0);

    useEffect(() => {

        const fetchData = async () => {
            setShowDownloading(true);
            try {
                const stats = await api.get(`/institutes/admissionStats/${authInfo.user.instituteID}`);
                const statsData = stats.data;

                setTotalApps(statsData.applications);
                setPendingApps(statsData.pending);
                setFees(statsData.fees);
            }
            catch (error: any) {
                //
            } finally {
                setShowDownloading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("admissions")}</IonTitle>
                    {!isPlatform('mobile') && (
                        <IonButtons slot="end">
                            <ThemeToggle />
                            <LanguageToggle />
                        </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("admissions")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-lg">
                        <IonCol>
                            <IonRow className='dash-border-bottom'>
                                <IonCol sizeMd="6" size="12">

                                    <IonCard className="dashboard-card dash-bg-3">
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("admn_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("admn_overview")} src="assets/images/form-1.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("admn_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("pending_apps")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {pendingApps}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("total_apps")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {totalApps}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("total_fees")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {fees}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                            
                                            
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="6" size="12">
                                    <IonRow className="ion-padding-top"> 
                                        <IonCol>
                                            <IonTitle id="open-setup-info" className='border-bottom-sec'>
                                                {t("admn_setup")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-setup-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("setup_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                       
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/class`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")} src="assets/images/class.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        {(authInfo.institute.type === "school") && (
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/school/stats`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("key_stats")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/admission/school/facilities`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("facilities")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        )}
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/admission/form`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("admn_form")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/admission/school/gallery`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("picture_gallery")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        {(authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/config/institute`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("basic_info")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        )}

                                    </IonRow>
                                </IonCol>
                                <IonCol sizeMd="6" size="12">
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-applications-info" className='border-bottom-sec'>
                                                {t("applications")}
                                            <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-applications-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("applications_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">

                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/applications/board`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("applications_board")} src="assets/images/board-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("applications_board")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/appointments`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_appointments")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/applications`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("pending_apps")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>

                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/list`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("admn_list")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/reports`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("admn_reports")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/appointments/history`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("appointments_history")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/admission/applications/history`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("apps_history")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            
                                        </IonCol>
                                        

                                    </IonRow>
                                
                                
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Admission;