import {
    ActionSheetButton, IonActionSheet, IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonLoading, IonRow, IonSkeletonText, IonTitle, IonToolbar, isPlatform,
} from '@ionic/react';
import { accessibilityOutline, accessibilitySharp, chevronDownOutline, closeOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {useAuth} from './AuthContext';
import useApiService from './ApiService';

interface Student {
    _id: string,
    classID: string,
    name: string,
    due: number
}

interface RefreshPage {
    refreshed: number,
    setRefreshed: (value: number | ((prevState: number) => number)) => void;
}

const SwitchStudent: React.FC<RefreshPage> = (props) => {
    const { api, renderApiAlerts } = useApiService(); 
    const { t } = useTranslation();
    const {authInfo} = useAuth()!;
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [students, setStudents] = useState < ActionSheetButton[] > ([]);
    const [student, setStudent] = useState < Student > ();

    const swapStudent = (selected: string) => {
  
        const upStudent = (selected: string) => {
            return new Promise((resolve, reject) => {
                api.post('/students/setDefault/', { 'parentID': authInfo.user._id, 'studentID': selected }).then(res => {
                    return resolve(res.data.student);
                  }).catch(err => reject(err)); 
            });
          }
      
        setShowLoading(true);
        upStudent(selected)
        .then((data) => {
            setShowActionSheet(false);
            props.setRefreshed(Math.random());
        })
        .catch(error => {
            // Api Error handling is already done in the interceptor
        })
        .finally(() => setShowLoading(false));
    }

    useEffect(() => {

        const fetchUp = async () => {
            try {
                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                const students = await api.get(`/students/getAllByParent/${authInfo.user._id}`);

                if(students.data.length > 1)
                {
                    let cObjects: ActionSheetButton[] = [];

                    for (let i=0; i < students.data.length; i++)
                    {
                        let cObject: ActionSheetButton;
                        if (students.data[i]._id !== student.data._id)
                        {
                            if (students.data[i].isActive === true)
                            {
                                cObject = {
                                    text: students.data[i].name,
                                    icon: !isPlatform('ios') ? accessibilityOutline : undefined,
                                    handler: () => swapStudent(students.data[i]._id)
                                }
                                cObjects.push(cObject);

                            }
                        }
                    }
                    cObjects.push({
                        text: t('cancel'),
                        icon: !isPlatform('ios') ? closeOutline : undefined,
                        role: 'cancel'
                    });

                    setStudents(cObjects);
                }

            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            }
        };

        fetchUp();
        
    }, [authInfo, props.refreshed]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <IonLoading isOpen={showLoading}
                cssClass="first-loading"
                spinner="circular"
                message={t("please_wait")}
            />
            
            {renderApiAlerts()}

            <IonActionSheet isOpen={showActionSheet}
                onDidDismiss={
                    () => setShowActionSheet(false)
                }
                cssClass='swap-sheet'
                subHeader={`${t("currently_selected")} - ${student?.name}`}
                header={t("switch_student")}
                buttons={students} 
            />

            <IonGrid className="ion-no-padding">
                <IonRow className="ion-no-padding">
                    <IonCol>
                        <IonToolbar className="pl-16" color="secondary" mode="ios" onClick={() => students && (students.length > 1) && setShowActionSheet(true)}>
                            {students && (students.length > 1) && (
                            <IonIcon slot='start' icon={accessibilitySharp} color="warning" />
                            )}
                            <IonTitle color="secondary" className='parent-home-title'>
                                {
                                student ? (student.name) : 
                                ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                                }
                            </IonTitle>
                            {students && (students.length > 1) && (
                            <IonButtons slot="end">
                                <IonButton><IonIcon icon={chevronDownOutline} color="warning" /></IonButton>
                            </IonButtons>
                            )}
                        </IonToolbar> 
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>         
    );
}

export default SwitchStudent;
