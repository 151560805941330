import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { attach, camera, images, trash, videocam } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

const AddChat: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [imageURL, setImageURL] = useState < string > ("");
    
    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUpload([rawFile]);
                                    setImageURL(webPath);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUpload([rawFile]);
                            setImageURL(webPath);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads: File[] =  [];
            let imgUrl = "";

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            imgUrl = webPath;
                        }
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            imgUrl = webPath;
                        }
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            const base64 = await fileToDataUri(rawFile);
                            imgUrl = base64;
                        }
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(imgUrl);
        } 
        catch (error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }

    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads: File[] =  [];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
           }
           setUpload(theUploads);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };
      
   const pickFiles = async () => {
    isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads: File[] = [];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
            }
            setUpload(theUploads);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const handleSubmit = () => {

        if (!type || type === "") {
            setMessage(t("select_inq_type"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("inq_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("inq_text_mandatory"));
            setIserror(true);
            return;
        }
  
        const addInq = () => {
            return new Promise((resolve, reject) => {
                api.post('/staffChats/add', { 'employeeID': authInfo.user._id, title, type, text }).then(res => {
                    return resolve(res.data.staffChat.title);
                }).catch(err => reject(err));
            });
        }

        const addAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/staffChats/attach/add', formData).then(res => {
                    return resolve(res.data.staffChat.title);
                }).catch(err => reject(err));
            });
        }

        if (upload.length > 0)
        {
            let formData = new FormData();

            formData.append('employeeID', authInfo.user._id);
            formData.append('title', title);
            formData.append('type', type);
            formData.append('text', text);
            for (let i=0; i < upload.length; i++)
            {
                formData.append('attachment[]', upload[i], `${authInfo.user.instituteID}`);
            }

            setShowLoading(true);
            addAttach(formData)
            .then(data => {
                setPass(data+t("has_been_submitted"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            addInq()
            .then(data => {
            
                setPass(data + t("has_been_submitted"));
                setIspass(true);
            })
            .catch((error) => {
               //
            })
            .finally(() => setShowLoading(false));
        }
    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("new_chat")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("new_chat")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/home`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonGrid className="mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                           
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-no-margin">
                                        <IonCardHeader>
                                                <IonCardSubtitle className='info-subtitle'>{t("contact_info")}</IonCardSubtitle>
                                            </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonList className="ion-no-padding">
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("select_type")}</IonLabel>
                                                                <IonSelect value={type}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                    placeholder={t("select_type")}
                                                                    onIonChange={
                                                                        (e) => setType(e.detail.value)
                                                                }>
                                                                     
                                                                    <IonSelectOption value="general">{t("general")}</IonSelectOption>  
                                                                    <IonSelectOption value="query">{t("query")}</IonSelectOption>  
                                                                    <IonSelectOption value="feedback">{t("feedback")}</IonSelectOption>  
                                                                </IonSelect>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                            <IonInput type="text"
                                                                    value={title}
                                                                    onIonInput={
                                                                        (e) => setTitle(e.detail.value!)
                                                                }></IonInput>
                                                        </IonItem>
                                                        
                                                        <IonItem detail={false}
                                                            lines='full'
                                                            button={false}>
                                                            <IonLabel position="floating" color="secondary">{t("write_to_us")}</IonLabel>
                                                            <IonTextarea rows={4} value={text} onIonInput={
                                                                    (e) => setText(e.detail.value!)
                                                            }></IonTextarea>
                                                        
                                                        </IonItem>

                                                        {isPlatform("capacitor") && (
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={takePhoto}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={camera}
                                                            />
                                                            <IonLabel>
                                                                {t("take_photo")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        )}

                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={pickImages}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={images}
                                                            />
                                                            <IonLabel>
                                                                {t("upload_photos")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={pickVideo}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={videocam}
                                                            />
                                                            <IonLabel>
                                                                {t("upload_video")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={pickFiles}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={attach}
                                                            />
                                                            <IonLabel>
                                                                {t("attach_doc")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>

                                                        {(upload.length > 0) && (
                                                        <IonItem detail={true}
                                                                detailIcon={trash}
                                                                color="danger"
                                                                lines='none'
                                                                button={true}
                                                                onClick={() => {
                                                                    setUpload([]);
                                                                    setImageURL("");
                                                                }}
                                                            >
                                                            <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                            <IonLabel>
                                                                {t("remove_files")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        )}

                                                    </IonList>
                                                </IonCol>
                                            </IonRow>

                                            {imageURL && (
                                            <IonRow>
                                                <IonCol>
                                                    <IonCard className="ion-text-center">
                                                        <IonCardHeader>
                                                            <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                        </IonCardHeader>
                                                        <IonCardContent>
                                                        
                                                            <img src={imageURL} 
                                                                alt={t("image_preview")}
                                                                width="320" />
                        
                                                        </IonCardContent>        
                                                    </IonCard>
                                                </IonCol>
                                            </IonRow>
                                            )}
                                          
                                            <IonRow className='ion-margin-vertical'>
                                                <IonCol className='ion-text-center'>
                                                    <IonButton onClick={handleSubmit}
                                                        className="first-button"
                                                        fill="clear"
                                                        size="small"
                                                        >{t("submit")}</IonButton>

                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
           
        </IonPage>
    );
};

export default AddChat;
