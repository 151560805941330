import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { addCircleOutline, alertCircleOutline, chevronForwardOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Student {
    _id: string,
    name: string,
    dob: Date,
    gender: string
}

const Students: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
   
    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
      };
   
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const students = await api.get(`/admissionUsers/getStudents/${authInfo.user._id}`);
                setStudents(students.data);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_children")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_children")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    onClick={() => history.push(`/app/students/add`)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("add_child")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {
                            (students.length > 0) ? (students.map((child) => {
                                return (
                                        <IonCard className="child-card" key={child._id}
                                            routerLink={`/app/student/${child._id}`} >
                                            <IonCardContent className='child-card-content'>
                                                <IonRow>
                                                    <IonCol size="11">
                                                        <IonRow>
                                                            <IonCol size="2" className='ion-text-center'>
                                                                <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                    {(child.gender === "boy") ? (<img src={`assets/images/boy.png`} alt={child.name} width="60" onClick={() => history.push(`/student/${child._id}`)}/>) 
                                                                        : (<img src={`assets/images/girl.png`} alt={child.name} width="60" onClick={() => history.push(`/student/${child._id}`)}/>)
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </IonCol>
                                                            <IonCol size="9">
                                                                <IonText className='child-name'>{child.name}</IonText>
                                                                
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className='mt-10'>
                                                            <IonCol>
                                                                <IonLabel className='child-label'>
                                                                    <p>{formatDateTime(child.dob)}</p>
                                                                </IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                    <IonCol size="1">
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <IonIcon
                                                                    size='large'
                                                                    icon={chevronForwardOutline}
                                                                    color='secondary'
                                                                />
                                                            </div>
                                                        </div>
                                                
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                        })) : (
                                            <IonCard className='empty-card'>
                                                <IonCardContent className='ion-no-margin ion-no-padding ion-padding-vertical'>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_child")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>
                  
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Students;
