import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { informationCircleOutline, locateSharp } from 'ionicons/icons';
import { Geolocation } from '@capacitor/geolocation';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    studentID: string
}
interface LatLon {
    lat: number,
    lon: number
}

const libraries = ["places" as "places"];

const StudentLocation: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [message, setMessage] = useState < string > ("");
    const [locate, setLocation] = useState < LatLon > ({lat: parseInt(process.env.REACT_APP_DEFAULT_LAT!), lon: parseInt(process.env.REACT_APP_DEFAULT_LNG!)});
    const [current, setCurrent] = useState < LatLon > ();
    const [place, setPlace] = useState < any > ();
    const placeRef = useRef < any > ();
    const mapRef = useRef < any > ();
    const markerRef = useRef < any > ();

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!,
        libraries
    });

    if (loadError)
    {
        setMessage(t("maps_error"));
        setIserror(true);
    }
    
    const mapStyles = {        
        height: "52vh",
        width: "100%"
    };

    const handleMapLoad = (ref: any) => mapRef.current = ref;

    const handleMarkerLoad = (ref: any) => markerRef.current = ref;

    const handleSearchLoad = (ref: any) => placeRef.current = ref;

    const handlePlacesChanged = () => {
        if (placeRef.current && placeRef.current.getPlaces()[0])
        {
            setPlace(placeRef.current.getPlaces()[0]);
            setCurrent(undefined);
            
        }
    };

    const handleCurrent = async () => {
        if (isPlatform('capacitor'))
        {
            Geolocation.checkPermissions().then(async (res) => {
            if (res.location !== 'granted') {
                Geolocation.requestPermissions().then(async (ress) => {
                    if (ress.location === 'denied') {
                        setPass(t("location_denied"));
                        setIspass(true);
                    }
                    else if (ress.location === 'granted') {
                        try {
                            const coordinates = await Geolocation.getCurrentPosition();
                           
                            setCurrent({lat: coordinates.coords.latitude, lon: coordinates.coords.longitude});
                            setPlace(undefined);

                        }
                        catch(error: any) {

                            setPass(error.message);
                            setIspass(true);

                        }
                    }
                });
                }
                else {
                    try {
                        const coordinates = await Geolocation.getCurrentPosition();
                       
                        setCurrent({lat: coordinates.coords.latitude, lon: coordinates.coords.longitude});
                        setPlace(undefined);

                    }
                    catch(error: any) {

                        setPass(error.message);
                        setIspass(true);

                    }
                }
            }).catch(err =>  {
                setMessage(t("location_error"));
                setIserror(true);
            });
        }
       
    }

    const fitCenter = () => {
        if(place && (place.geometry) && (place.geometry.location))
        {
            if(mapRef.current) {
                mapRef.current.setCenter(place.geometry.location);
            }
        }
        else if(current) 
        {
            if(mapRef.current) {
                mapRef.current.setCenter({lat: current.lat, lng: current.lon });
            }
        }
        else if (locate)
        {
            if(mapRef.current) {
                mapRef.current.setCenter({lat: locate.lat, lng: locate.lon });
            }
        }
        else
        {
            if(mapRef.current) {
                mapRef.current.setCenter({lat: parseInt(process.env.REACT_APP_DEFAULT_LAT!), lng: parseInt(process.env.REACT_APP_DEFAULT_LNG!)});
            }
        }
    }

    const getCenter = () => {
        if(place && (place.geometry) && (place.geometry.location))
        {
            return place.geometry.location;
           
        }
        else if(current) 
        {
            return {lat: current.lat, lng: current.lon};
        }
        else if(locate) 
        {
            return {lat: locate.lat, lng: locate.lon};
        }
        else
        {
            return {lat: parseInt(process.env.REACT_APP_DEFAULT_LAT!), lng: parseInt(process.env.REACT_APP_DEFAULT_LNG!)};
        }
    }


    const handleLocation = async () => {
  
        const upStud = (studentID: string, lat: number, lon: number) => {
          return new Promise((resolve, reject) => {
            api.put('/students/'+studentID, { 'lat': lat, 'lon': lon }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
        
        if (markerRef.current)
        {
            try {
                const latLng = await markerRef.current.getPosition();
                setShowLoading(true);
                upStud(studentID, latLng.lat(), latLng.lng())
                .then(data => {
            
                    setPass(data+t("has_been_updated"));
                    setIspass(true);
                    
                })
                .catch((error) => {
                   //
                })
                .finally(() => setShowLoading(false));

            } catch (error: any) {
                //
            }
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/students/${studentID}`);

                if (result.data.lat && result.data.lon)
                {
                    setLocation({lat: result.data.lat, lon: result.data.lon});
                }
                else
                {
                    const insti = await api.get(`/institutes/${result.data.instituteID}`);
                    if (insti.data.lat && insti.data.lon)
                    {
                        setLocation({lat: insti.data.lat, lon: insti.data.lon});
                    }
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };
      
        fetchData();
     
    }, [authInfo, studentID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student/${studentID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_location")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_location")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert
                    isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={ () => history.push(`/app/student/${studentID}`)}
                    header={t("error")}
                    message={message}
                    buttons={[`${t("close")}`]}
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => window.location.reload()
                    }
                    header={t("result")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                   
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("location_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton color="primary" onClick={handleCurrent}>{t("move_pin")}</IonButton>    
                        </IonCol>
                    </IonRow>
                
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="white-shade">
                                <IonCardContent className="ion-no-padding map-container-small">
                                    {isLoaded ? (
                                        <GoogleMap
                                        onLoad={handleMapLoad}
                                        mapContainerStyle={mapStyles}
                                        zoom={14}
                                        center={getCenter()}
                                        clickableIcons={false}
                                        options={{disableDefaultUI: true}}
                                        >                               
                                         <StandaloneSearchBox
                                            onLoad={handleSearchLoad}
                                            onPlacesChanged={handlePlacesChanged}
                                            >
                                            <input
                                                type="text"
                                                placeholder={t("search_location")}
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `2px solid transparent`,
                                                    width: `100%`,
                                                    height: `48px`,
                                                    padding: `10px`,
                                                    borderRadius: `5px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                    position: `absolute`,
                                                    left:  `0%`
                                                    }}
                                            />
                                            </StandaloneSearchBox>     

                                            {current && 
                                            <Marker onLoad={handleMarkerLoad} zIndex={50} position={{lat: current.lat, lng: current.lon}} draggable={true} />
                                           }

                                            {place && (place.geometry) && (place.geometry.location) && 
                                            <Marker onLoad={handleMarkerLoad} zIndex={50} title={place.name} position={place.geometry.location} draggable={true} />
                                           }
                                              {!current && !place && locate &&
                                            <Marker onLoad={handleMarkerLoad} zIndex={50} position={{lat: locate.lat, lng: locate.lon}} draggable={true} />
                                           }

                                            <IonIcon onClick={fitCenter} size="large" icon={locateSharp} className="bottom-right" />

                                        </GoogleMap>
                                    ) : ( 
                                        <div className="load-body">
                                            <div className="load-wrapper">
                                                <span className="circle circle-1"></span>
                                                <span className="circle circle-2"></span>
                                                <span className="circle circle-3"></span>
                                                <span className="circle circle-4"></span>
                                                <span className="circle circle-5"></span>

                                            </div>
                                            <div className="load-title">
                                            {t("loading_maps")}
                                            </div>
                                        </div>

                                        )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton className="first-button" 
                                fill="clear" 
                                onClick={handleLocation}>
                                    {t("update_location")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default StudentLocation;
