import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';
import useApiService from '../../../components/ApiService';

interface Student {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    dob: Date,
    admnNo?: string,
    gender?: string,
    isActive: boolean
}
interface ClassInfo {
    className: string,
    session: string
}

const AbsentStudents: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const { authInfo } = useAuth()!;
    const history = useHistory();
    const { t } = useTranslation();
    const [students, setStudents] = useState<Student[]>([]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [refreshed, setRefreshed] = useState<number>(0);
    const [current, setCurrent] = useState<string>("");
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const scrollContent = useRef<HTMLIonContentElement | null>(null);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    useEffect(() => {
        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { students, scrollPosition } = JSON.parse(storedState);
            setStudents(students);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } else {
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const result = await api.get(`/students/absentTeacher/${authInfo.user._id}`);
                    setStudents(result.data.students);
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
            };

            fetchUp();
        }
        setInitialRender(false);
    }, [authInfo, refreshed, history.action, history.location.pathname]);

    useEffect(() => {
        if (!initialRender) {
            const currentState = {
                students,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [students, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/students`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("absent_students")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("absent_students")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">

                           <IonCol sizeLg="3" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("name")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {(authInfo.institute.type === "school") ? t("class") : t("batch")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                            {t("dob")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("gender")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("student_admn")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                (e) => setCurrent(e.detail.value!)
                        }>

                        {(students.length > 0) ? (students.map((student, i) => {
                            return (
                                <IonRow key={student._id} className={`${(i % 2 === 0) ? 'row-table' : 'row-table-alt'} ${!student.isActive && 'font-color-3'} ion-padding-horizontal`}
                                onClick={
                                    () => setCurrent(student._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/student/${student._id}`)
                                }>
                                    
                                    <IonCol sizeLg="3" size="8" className='table-field' >
                                            <IonRow>
                                                <IonCol>
                                                    <IonRadio value={student._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start text-capitalize'>{student.name}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className={`${student.isActive ? 'vid-responsive-field' : 'vid-responsive-field-red'} ion-hide-lg-up text-capitalize`}>
                                                <IonCol>
                                                    <IonLabel>
                                                        {student.classInfo.className} &#8226; {student.gender && t(student.gender)} &#8226; {student.admnNo}
                                                    </IonLabel>
                                                        
                                                </IonCol>
                                            </IonRow>

                                        </IonCol>
                                        <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {student.classInfo.className}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="3" className='table-field'>
                                            {formatDated(student.dob)}
                                        </IonCol>
                                        <IonCol size="2" className='table-field text-capitalize ion-hide-lg-down'>
                                        {student.gender && t(student.gender)}
                                        </IonCol>
                                        <IonCol size="2" className='table-field ion-hide-lg-down'>
                                            {student.admnNo}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color={student.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${student._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                {t("absent_students")} : {students.length}
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("manage_student")}
                                    </IonButton>

                                

                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AbsentStudents;