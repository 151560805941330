import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, card } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Payment {
    _id: string,
    netPayable: number,
    applicationID: string,
    desc: string,
    status: string,
    updatedAt: Date
}

const Payments: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [trans, setTrans] = useState < Payment[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    function getDate(utcdate: Date)
    {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
               
                const result = await api.get(`/payments/getByAdmnUser/${authInfo.user._id}`);
                setTrans(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("payments_history")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("payments_history")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">
                  
                    <IonRow>
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(trans.length > 0) ? (trans.map((tran, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={tran._id}
                                            className='transact-item'
                                           >
                                            <IonIcon slot="start"
                                                icon={card}
                                                color={tran.status === "Success" ? 'success' : 'danger' }
                                                />
                                            <IonLabel className="transact-label">
                                                <h3>{tran.desc}</h3>
                                                <p>{tran.updatedAt && getDate(tran.updatedAt)}</p>
                                            </IonLabel>
                                            <IonLabel className="ion-text-right transact-amt">
                                                <h3>&#8377; {Math.abs(tran.netPayable)}</h3>
                                                <p>{tran.applicationID !== "" ? (<Link className='transact-link' to={`/app/form/${tran.applicationID}`}>{t("view_form")}</Link>) : ""}</p>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_payments")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Payments;
