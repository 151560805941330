import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonAlert,
    IonCardContent,
    IonCard,
    IonLoading,
    IonText,
    IonIcon
} from '@ionic/react';
import axios from 'axios';
import { mailOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgotPassword: React.FC = () => {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [isSuccess, setIsSuccess] = useState < boolean > (false);
    const [success, setSuccess] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
    });

    function validateEmail(email : string) { // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = () => {

        if (!email) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }
        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        const upUser = (email: string) => {
          return new Promise((resolve, reject) => {
            api.post('/auth/forgot', {'email': email}).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
          });
        }

        setShowLoading(true);
        upUser(email)
        .then(data => {

            setSuccess(t("link_sent"));
            setIsSuccess(true);
          
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("unable_to_connect"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

    return (
        <IonPage>

            <IonContent fullscreen={true} className="ion-padding ion-text-center grey-shade">

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={isSuccess}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIsSuccess(false)
                    }
                    header={t("success")}
                    message={success}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                   <IonRow>
                        <IonCol offsetXl="4" offsetLg="3" offsetSm="2" offset="0" sizeXl="4" sizeLg="6" sizeSm="8" size="12">
                           
                            <IonRow className="mt-30">
                                <IonCol className="ion-text-center">
                                    <img alt="Logo" src="assets/images/logo-trans.png" width="160" height="160" className='auth-logo' 
                                        onClick={
                                            () => history.push(`/home`)
                                        }
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol className="ion-text-center">
                                    <IonText className="auth-title">{i18n.language === "hi" ? process.env.REACT_APP_NAME_HI : process.env.REACT_APP_NAME}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center mt-30">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                            <IonIcon slot="start"
                                                icon={mailOutline}
                                                color="primary" 
                                                className='mt-20'
                                                />
                                                <IonLabel position="stacked" className="auth-label">
                                                {t("registered_email")}</IonLabel>
                                                <IonInput type="email"
                                                    value={email}
                                                    placeholder={t("email")}
                                                    className="auth-input"
                                                    onIonInput={
                                                        (e) => setEmail(e.detail.value !)
                                                }
                                                onKeyUp={(e) => (e.key === 'Enter') && handleSubmit()}></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        
                            <IonRow className="ion-justify-content-center mt-30">
                                <IonCol className='ion-padding'>
                                    <IonButton expand="block"
                                        onClick={handleSubmit}>{t("send_link")}</IonButton>
                                        <p>
                                            <Link to="/login" className="auth-link"> {t("login_here")}</Link>
                                        </p>
                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ForgotPassword;
