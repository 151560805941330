import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface AllField {
    _id: string,
    name: string,
    category: string,
    options?: string[],
    isMandatory: boolean,
    isActive: boolean,
    order: number
}

interface AdmnField {
    masterID: string,
    name: string,
    category: string,
    options?: string[],
    isMandatory: boolean,
    isActive: boolean,
    order: number
}

const FormSetup: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [admnFields, setAdmnFields] = useState < AdmnField[] > ([]);
    const [allFields, setAllFields] = useState < AllField[] > ([]);
    const [categories, setCategories] = useState < string[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [type, setType] = useState < string > ("");

    const handleChange = (value: boolean, fieldID: string) => {

        let admn = [...admnFields];

        if (value === false)
        {
            const indexOfObject = admn.findIndex(obj => obj.masterID === fieldID);
            if (indexOfObject > -1) {
                admn.splice(indexOfObject, 1); // 2nd parameter means remove one item only
              }
        }
        if (value === true)
        {
            const indexOfObject = admn.findIndex(obj => obj.masterID === fieldID);
            if (!(indexOfObject > -1)) {
                
                const newField = allFields.find(obj => obj._id === fieldID);
                if (newField && newField.options && newField.options.length > 0)
                {
                    admn.push({masterID: newField._id, name: newField.name, category: newField.category, options: newField.options, isMandatory: newField.isMandatory, isActive: newField.isActive, order: newField.order});
                }
                else if (newField)
                {
                    admn.push({masterID: newField._id, name: newField.name, category: newField.category, isMandatory: newField.isMandatory, isActive: newField.isActive, order: newField.order});
                }
            }
            
        }
        setAdmnFields(admn);
    }

    const handleSubmit = () => {
  
        const upClass = (admnFields: AdmnField[]) => {
          return new Promise((resolve, reject) => {
            api.post('/institutes/updateFields', { 'instituteID': authInfo.user.instituteID, admnFields }).then(res => {
      
              return resolve(res.data.institute.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        setShowLoading(true);
        upClass(admnFields)
        .then(data => {
  
            setPass(data+t("form_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const insti = await api.get(`/institutes/${authInfo.user.instituteID}`);
                setType(insti.data.type);

                if(insti.data.admnFields && insti.data.admnFields.length > 0) {
                    setAdmnFields(insti.data.admnFields);
                } else {
                    setAdmnFields([]);
                }

                const result = await api.get(`/masters/getFields`);
                const formFields: AllField[] = result.data.admissionFields;
                setAllFields(formFields);

                const unique = [...new Set(formFields.map(item => item.category))];
                setCategories(unique);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admission`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("admn_form")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("admn_form")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {(type === "school") ? t("admn_form_info") : t("admn_form_insti")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
 
              
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            
                        {categories && categories.length > 0 && categories.map((category, i) => (
                            <IonCard key={i} className="mt-30">
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{category}{t("fields")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                              
                                    <IonRow>
                                            <IonCol>
                                              
                                                {allFields.length > 1 && allFields.map((field) => (
                                                    (field.isActive) && (field.category === category) &&
                                                    (<IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={field._id}
                                                        >
                                                        <IonLabel color="secondary">{field.name}</IonLabel>
                                                        <IonToggle checked={admnFields.some(el => el.masterID === field._id)} onIonChange={e => handleChange(e.detail.checked, field._id)} />
                                                    </IonItem>)
                                                ))}

                                            </IonCol>
                                        
                                    </IonRow>
                                          
                                </IonCardContent>
                            </IonCard>
                            ))}

                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("save_changes")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default FormSetup;
