import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { alertCircleOutline, informationCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Student {
    _id: string,
    name: string,
    dob: Date,
    gender: string
}

interface ParamTypes {
    workshopID: string
}

const BookWorkshop: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {workshopID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedID, setSelectedID] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [workshopName, setWorkshopName] = useState < string > ("");
    const [amount, setAmount] = useState < number > (0);
    const [info, setInfo] = useState < string > ("");
    const [dated, setDated] = useState < Date > ();

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleSubmit = () => {

        if (!selectedID || selectedID === "") {
            setMessage(t("select_a_child"));
            setIserror(true);
            return;
        }

        const childName = students.find((val) => val._id === selectedID)!.name;

        const addApplication = () => {
            return new Promise((resolve, reject) => {
              api.post('/applications/addWorkshop', {'userID': authInfo.user._id, 'appID': process.env.REACT_APP_APP_ID, 'appName': process.env.REACT_APP_NAME, workshopID, workshopName, 'childID': selectedID, childName, amount, dated, info}).then(res => {
                return resolve(res.data.applicationID);
              }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        addApplication()
            .then(data => {
                setShowLoading(false);
                history.push(`/app/application/${data}`);
            })
            .catch(error => {
                //
                setShowLoading(false);
            });
    }
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const students = await api.get(`/admissionUsers/getStudents/${authInfo.user._id}`);
                setStudents(students.data);

                const result = await api.get(`/admissionCourses/${workshopID}`);
                setWorkshopName(result.data.name);
                setAmount(result.data.applicationFees);
                setDated(result.data.startDate);
                setInfo(result.data.duration);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed, workshopID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("select_child")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("select_child")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("apply_note_3")} {workshopName} {t("apply_note_4")} {process.env.REACT_APP_NAME} {t("apply_note_5")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {
                            (students.length > 0) ? (students.map((child) => {
                                return (
                                        <IonCard className={selectedID === child._id ? "child-card-selected" : "child-card"} key={child._id}
                                            onClick={() => setSelectedID(child._id)} >
                                            <IonCardContent className='child-card-content'>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="2" className='ion-text-center'>
                                                                <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                    {(child.gender === "boy") ? (<img src={`assets/images/boy.png`} alt={child.name} width="60" />) 
                                                                        : (<img src={`assets/images/girl.png`} alt={child.name} width="60" />)
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </IonCol>
                                                            <IonCol size="9">
                                                                <IonText className='child-name'>{child.name}</IonText>
                                                                
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className='mt-10'>
                                                            <IonCol>
                                                                <IonLabel className='child-label'>
                                                                    <p>{formatDateTime(child.dob)}</p>
                                                                </IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                        })) : (
                                            <IonCard className='empty-card'>
                                                <IonCardContent className='ion-no-margin ion-no-padding ion-padding-vertical'>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_child")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>
                  
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={() => history.push(`/app/students/add`)}
                                className="first-button"
                                fill="clear"
                                >+ {t("add_child")}</IonButton>

                        </IonCol>
                        <IonCol className="ion-text-right">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("next")} &gt;</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default BookWorkshop;
