import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { alertCircleOutline, locationSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

interface Institute {
    _id: string,
    name: string,
    address: string,
    phone: string,
    email: string,
    logo: string,
    admnPhoto?: string
}

const Shortlisted: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const removeShort = (schoolID: string, schoolName: string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                await api.get(`/admissionUsers/remove/${authInfo.user._id}/${schoolID}`);
                setPass(schoolName+ t("has_been_removed"));
                setIspass(true);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/admissionUsers/getShortlisted/${authInfo.user._id}`);
                setInstitutes(result.data);
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };
     
        fetchData();

    }, [refreshed, authInfo]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("shortlisted_schools")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("shortlisted_schools")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-bottom">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {
                            (institutes.length > 0) ? (institutes.map((insti) => {
                                return (
                                        <IonCard key={insti._id} className="ion-margin-vertical">
                                            {insti.admnPhoto &&
                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.admnPhoto}`} alt={insti.name} onClick={() => history.push(`/app/school/${insti._id}`)}
                                                style={{'width': '100%', 'height': 'auto'}} />)
                                            }
                                            <IonCardContent className="ion-no-padding">
                                                <IonGrid className="white-shade">
                                                    <IonRow>
                                                        <IonCol size="9" className="insti-info">
                                                            <IonRow className="ion-margin-horizontal pb-08">
                                                                <IonCol>
                                                                    <p><span className="insti-name" onClick={() => history.push(`/app/school/${insti._id}`)}>{insti.name}</span></p>
                                                                    
                                                                    <p><IonIcon icon={locationSharp} color="secondary" /><span className="insti-address">{insti.address}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow className="ion-margin-horizontal pb-08">
                                                                <IonCol>
                                                                    <IonButton className="second-button" fill="clear" onClick={() => history.push(`/app/school/${insti._id}`)}>{t("apply_now")}</IonButton>
                                                                    <IonButton className="fifth-button ml-08" fill="clear" onClick={() => removeShort(insti._id, insti.name)} >{t("remove")}</IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-text-center">
                                                            <div className="vertical-container">
                                                                <div className="vertical-center"> 
                                                                {insti.logo && (insti.logo !== "") ?
                                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.logo}`} alt={insti.name} width="60" onClick={() => history.push(`/app/school/${insti._id}`)} />)
                                                                    : (<img src={`assets/images/institute.png`} alt="Logo" width="60" onClick={() => history.push(`/app/school/${insti._id}`)} />)
                                                                }
                                                                </div>
                                                            </div>
                                                              
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                        })) : (
                                        <IonCard className="ion-margin-vertical red-card">
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        color="danger"
                                                        slot="start"
                                                        size="large" />
                                                    <IonLabel className="list-title"><h3>{t("no_schools_schortlisted")}</h3></IonLabel>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>

                   
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Shortlisted;
