import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, star, starOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/PageUnloadHandler';
import useApiService from '../../../../components/ApiService';

interface Evaluation {
    _id: string,
    student: Student,
    topic: TopicObj,
    response?: string,
    rating?: number,
    score?: number,
    createdAt: Date
}

interface Student {
    studentID: string,
    studentName: string
}
interface TopicObj {
    topicID: string,
    topicName: string
}

interface Course {
    _id: string,
    name: string,
    chapters: Chapter[],
    courseType: string,
    isActive: boolean
}
interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}
interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number
}

interface ParamTypes {
    topicID?: string
}

const TopicSubmissions: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {topicID} = useParams < ParamTypes > ();
    const [evaluations, setEvaluations] = useState < Evaluation[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [selectedTopic, setSelectedTopic] = useState <string> ("all");
    const [courses, setCourses] = useState < Course[] > ([]);
    const [course, setCourse] = useState < string > ("");
    const [chapter, setChapter] = useState < string > ("all");
    const [topics, setTopics] = useState < Topic[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleCourse = (courseID : string) => {
        if(!initialRender)
        {
            setCourse(courseID);
            setChapter("all");
            setTopics([]);
            setSelectedTopic("all");
            setEvaluations([]);
            setTotal(0);
        }
    }

    const handleChapter = (chapter : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        if (chapter !== "")
        {
            if (chapter !== 'all')
            {
                fetchUp();
            }
            else
            {
                setTopics([]);
            }
            setChapter(chapter);
            setSelectedTopic("all");
            setEvaluations([]);
            setTotal(0);
        }
    }

    const handleSubmit = () => {

        if(!course || course === "")
        {
            setMessage(t("course_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/evaluations/listByTopic/${course}/${chapter}/${selectedTopic}`);
                setEvaluations(result.data.evaluations);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
    }


    const loadItems = async () => {

        if(!course || course === "")
        {
            setMessage(t("course_mandatory"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {
            const result = await api.get(`/evaluations/listByTopic/${course}/${chapter}/${selectedTopic}?skip=${limit}`);
            if(result.data.evaluations && result.data.evaluations.length)
            {
                setEvaluations([...evaluations, ...result.data.evaluations]);
                setSkip(limit);
            }

        } catch (error: any) {
            //
        } 
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            // Data is present in local storage, use it to initialize state
            const { evaluations, total, courses, topics, course, chapter, selectedTopic, skip, scrollPosition } = JSON.parse(storedState);
            setEvaluations(evaluations);
            setTotal(total);
            setCourses(courses);
            setTopics(topics);
            setCourse(course);
            setChapter(chapter);
            setSelectedTopic(selectedTopic);
            setSkip(skip);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else 
        {
            let instituteID: string = "";
            if (process.env.REACT_APP_APP_ID === 'com.bricked.stms')
            {
                instituteID = authInfo.user.instituteID;
            }
            else
            {
                instituteID = process.env.REACT_APP_APP_ID!;
            }

            const fetchUp = async () => {
                setShowLoading(true);
                try {

                    const result = await api.get(`/courses/getAllInstitute/${instituteID}`);
                    setCourses(result.data);
    
                    if(topicID)
                    {
                        const top = await api.get(`/topics/${topicID}`);
                        setCourse(top.data.courseID);
                        
                        setChapter(top.data.chapterInfo.chapterID);
                        
                        const tops = await api.get(`/topics/getByChapter/${top.data.chapterInfo.chapterID}`);
                        setTopics(tops.data);
                        setSelectedTopic(topicID);

                        const res = await api.get(`/evaluations/getByTopic/${topicID}`);
                        setEvaluations(res.data.evaluations);
                        setTotal(res.data.total);
                        
                    }
                    else
                    {
                        setEvaluations([]);
                        setTotal(0);
                        setTopics([]);
                        setCourse("");
                        setChapter("all");
                        setSelectedTopic("all");
                    }
                    setSkip(0);

                } catch (error: any) {
                   //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, topicID, refreshed, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            evaluations,
            total,
            courses,
            topics,
            course,
            chapter,
            selectedTopic,
            skip,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [evaluations, total, courses, topics, skip, selectedTopic, course, chapter, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_topic_wise")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_topic_wise")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />
                
                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className='min-md'>
      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("select_course")}</IonLabel>
                                            <IonSelect value={course}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_course")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleCourse(e.detail.value)
                                                }>

                                                    {courses.map((course) => 
                                                        (<IonSelectOption key={course._id} value={course._id}>{course.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_chapter")}</IonLabel>
                                            <IonSelect value={chapter}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_chapter")}
                                                    onIonChange={
                                                        (e) => handleChapter(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                     {(course !== "") && courses.find(val => val._id === course)!.chapters.map((chapter) => 
                                                        (chapter.isActive) && 
                                                        (<IonSelectOption key={chapter._id} value={`${chapter._id}`}>{chapter.chapterName}</IonSelectOption>)
                                                    )}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_topic")}</IonLabel>
                                        <IonSelect value={selectedTopic}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field"
                                                    placeholder={t("select_topic")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setSelectedTopic(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                {topics.map((topic) => 
                                                    (topic.isActive) && 
                                                    (<IonSelectOption key={topic._id} value={`${topic._id}`} >{topic.name}</IonSelectOption>)
                                                )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                
                <IonGrid className="ion-no-padding mb-60 mt-10">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_submissions")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                           <IonCol sizeLg="3" size="6" className='table-heading'>
                                
                                {t("name")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("topic_name")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                            {t("date")}
                            </IonCol>

                            <IonCol size="3" className='table-heading'>
                            {t("rating")}
                            </IonCol>
                        </IonRow>

                            {(evaluations.length > 0) ? (evaluations.map((evaluation, i) => {
                                return (
                                    <IonRow key={evaluation._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                   
                                    onClick={
                                        () => (evaluation.score !== undefined) ? history.push(`/app/assessment/${evaluation._id}`) : history.push(`/app/submission/${evaluation._id}`)
                                    }>
                                        
                                        <IonCol sizeLg="3" size="6" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        
                                                        <IonText className='text-capitalize'>{evaluation.student.studentName}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {evaluation.topic.topicName}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>

                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down'>
                                                {evaluation.topic.topicName}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(evaluation.createdAt)}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                            {(evaluation.score !== undefined) && `${evaluation.score} %`}
                                                {(evaluation.score === undefined) && !evaluation.rating && (
                                                    <>
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 1) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 2) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 3) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 4) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 5) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                    </>
                                                )}
                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_evaluations")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(evaluations.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${evaluations.length} ${t("of")} ${total}`} 
                                    </IonCol>
                                </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                <IonButton 
                                    onClick={
                                        () => history.push(`/app/submissions/student`)
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_student_wise")}</IonButton>

                           
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default TopicSubmissions;