import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { NativeAudio } from '@capacitor-community/native-audio';
import { Toast } from '@capacitor/toast';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import useApiService from '../../../components/ApiService';

const ScanExit: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const handleExit = async (data : string) => {

        const exit = ( studentID: string) => {
            return new Promise((resolve, reject) => {
              api.get('/attendances/exit/' + studentID).then(res => {
                return resolve(res.data.student);
              }).catch(err => reject(err));
            });
        }

        const studentID = data.split("/").pop();

        if (studentID && studentID.match(/^[0-9a-fA-F]{24}$/))
        {
            exit(studentID)
            .then(async (res: any) => {
                NativeAudio.play({
                    assetId: 'attend'
                }).catch((error) => {
                    console.log(error);
                });
                await Toast.show({
                    text: `${res.name} ${t("marked_exit")} ${formatDateTime(new Date(Date.now()))}`,
                });
            })
            .catch(async (error) => {
                await Toast.show({
                    text: t("qr_error"),
                });
            });
        }
        else 
        {
            await Toast.show({
                text: t("qr_error"),
            });
        }

    }

    const stopScan = async () => {
        document.body.classList.remove('scanner-active');
        await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
    };

    const openScanner = async () => {
        try {
            const status = await BarcodeScanner.checkPermissions();

            if (status.camera !== 'granted')
            {
                const res = await BarcodeScanner.requestPermissions();
                if (res.camera === 'denied')
                {
                    setMessage1(t("camera_denied"));
                    setIserror1(true);
                }
                else if (res.camera === 'granted') {

                    document.body.classList.add('scanner-active');

                    let previous = "";
                    // Add the `barcodeScanned` listener
                    const listener = await BarcodeScanner.addListener(
                        'barcodeScanned',
                        async (result) => {
                            if (result.barcode.displayValue && result.barcode.displayValue !== previous)
                            {
                                previous = result.barcode.displayValue;
                                handleExit(result.barcode.displayValue);
                            }
                        },
                    );

                    await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
                    
                }
            }
            else
            {
                document.body.classList.add('scanner-active');

                let previous = "";
                // Add the `barcodeScanned` listener
                const listener = await BarcodeScanner.addListener(
                    'barcodeScanned',
                    async (result) => {
                        if (result.barcode.displayValue && result.barcode.displayValue !== previous)
                        {
                            previous = result.barcode.displayValue;
                            handleExit(result.barcode.displayValue);
                        }
                    },
                );

                await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
            }

        } catch (error: any) {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    };

    useEffect(() => {

        NativeAudio.preload({
            assetId: "attend",
            assetPath: "attend.mp3",
            audioChannelNum: 1,
            isUrl: false
        }).catch((error) => {
            console.log(error);
        });

        openScanner();

        return () => {
            stopScan();
            NativeAudio.unload({
                assetId: 'attend'
            }).catch((error) => {
                console.log(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_exit")}</IonTitle>
                    <IonButtons slot="end">

                            <IonButton onClick={() => {
                                stopScan();
                                history.push(`/app`);
                            }}>
                                <IonIcon icon={close} color="secondary" size="large" />
                            </IonButton>
                      
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_exit")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIserror(false);
                            history.push(`/app`);
                        }
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIserror1(false);
                            BarcodeScanner.openSettings();
                        }
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("open_settings")}`]
                    }
                />

            </IonContent>
        </IonPage>
    );
};

export default ScanExit;
