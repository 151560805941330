import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { business, busOutline, person } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { useAuth } from "../../components/AuthContext";
import useApiService from '../../components/ApiService';

import 'react-vertical-timeline-component/style.min.css';

interface ParamTypes {
    routeID: string
}

interface TripStudent {
    studentID: string,
    name: string
}

const RouteDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {routeID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);
    const [institute, setInstitute] = useState < string > ("");
   
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/routes/' + routeID);
                setStudents(result.data.students);
                setName(result.data.name);
                setType(result.data.type);

                const insti = await api.get(`/institutes/${result.data.instituteID}`);
                setInstitute(insti.data.name);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };
         
        fetchData();

    }, [authInfo, routeID]);

    return (
        <IonPage>
           <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("route_preview")}</IonTitle>
                    
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("route_preview")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                
                    <IonRow className="ion-no-padding ion-margin-top">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className={(type === 'pickup') ? "action-card-c" : "action-card-b"}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="1">
                                                <IonIcon className='third-icon'
                                                    icon={busOutline}
                                                    color={type === 'pickup' ? 'primary' : 'secondary'} />
                                                </IonCol>
                                                <IonCol size="10" className="ion-text-center mt-05">
                                                    <IonText className='action-text-3'>{name} ({type})
                                                    </IonText>
                                                </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <VerticalTimeline layout='1-column' animate={false} lineColor="#086E7D">
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element"
                                                    icon={ <IonIcon icon={business} className='timeline-icon' />}
                                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                    <IonText className='timeline-para'>
                                                    {t("start_from")}{institute}
  
                                                    </IonText>
                                                   
                                                   
                                                </VerticalTimelineElement> 
                                            {
                                                students.map(student => {
                                                return (
                                               
                                                <VerticalTimelineElement
                                                    key={student.studentID}
                                                    className="vertical-timeline-element"
                                                    icon={ <IonIcon icon={person} className='timeline-icon' />}
                                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                    <IonText className='timeline-para'>
                                                        {student.name} 
                                                        {(type === 'pickup') ? t("to_be_picked") : ""} 
                                                        {(type === 'drop') ? t("to_be_dropped") : ""} 
                                                       
                                                    </IonText>
                                                   
                                                   
                                                </VerticalTimelineElement> 
                                                    )
                                                })
                                            }

                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element"
                                                    icon={ <IonIcon icon={business} className='timeline-icon' />}
                                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                    <IonText className='timeline-para'>
                                                    {t("reach_at")}{institute}
                                                      
                                                    </IonText>
                                                   
                                                </VerticalTimelineElement> 
                            
                            </VerticalTimeline>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default RouteDetail;
